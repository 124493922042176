import React from "react";
import CheckSvg from "../../../assets/svgs/CheckSvg";
import { status as appointmentStatus } from "../data/index";
import Lock from "../../../assets/svgs/Lock";
import Unlock from "../../../assets/svgs/Unlock";
import classNames from "classnames";
import { useLSStore } from "../../../context/ls-store";

const AppointmentCard = ({
  indx,
  appointmentId,
  status,
  patient,
  distance,
  location,
  appointmentType,
  addOpen,
  publishOpen,
  appointment,
  containerRef,
  isLastVisit,
  handleDrag,
}) => {
  const filterOpen = useLSStore((state) => state.filterOpen);
  const editable = useLSStore((state) => state.editable);
  const selectedAppointment = useLSStore((state) => state.selectedAppointment);

  const setDraggingAppointment = useLSStore(
    (state) => state.setDraggingAppointment
  );

  const setSelectedAppointment = useLSStore(
    (state) => state.setSelectedAppointment
  );

  const setAppointmentToDrop = useLSStore(
    (state) => state.setAppointmentToDrop
  );

  return (
    <div
      key={appointmentId}
      draggable={
        editable === true &&
        appointment.status === "UNLOCKED" &&
        selectedAppointment === null &&
        filterOpen === false &&
        addOpen === false &&
        publishOpen === false
          ? true
          : false
      }
      onDrag={handleDrag}
      onDragStart={(ev) => {
        setDraggingAppointment(appointmentId);
        setAppointmentToDrop(appointment);
        ev.dataTransfer.setData("text/html", ev.currentTarget.outerHTML);
      }}
      onDragEnd={() => {
        setDraggingAppointment(null);
        setAppointmentToDrop(null);
      }}
      style={{ viewTransitionName: `card-${appointmentId}` }}
      className={classNames(
        "relative flex flex-col w-full gap-2 p-2 border rounded-md border-primarygrayborder transition-all",
        editable === true &&
          selectedAppointment === null &&
          appointment.status === "UNLOCKED" &&
          filterOpen === false &&
          addOpen === false &&
          publishOpen === false &&
          "cursor-grab active:cursor-grabbing",
        selectedAppointment === appointmentId
          ? "bg-primaryblue opacity-100"
          : selectedAppointment !== null ||
            filterOpen === true ||
            addOpen === true ||
            publishOpen === true
          ? "opacity-40"
          : "bg-white opacity-100"
      )}
    >
      <div
        className={classNames(
          "absolute -top-2 -left-1 rounded-full w-4 h-4 flex items-center justify-center m-0 text-xs text-white",
          appointment.visitStatus === "PUBLISHED" ||
            appointment.visitStatus === "COMPLETED"
            ? "bg-primarygreen"
            : appointment.visitStatus === "NOSHOW"
            ? "bg-primaryorange"
            : status === appointmentStatus.published
            ? "bg-primarygreen"
            : status === appointmentStatus.locked
            ? "bg-primaryblue"
            : "bg-primaryyellow"
        )}
      >
        {indx + 1}
      </div>
      <div className="flex flex-row items-center justify-between w-full">
        <p
          className={classNames(
            "m-0 text-sm font-bold text-primaryblack",
            selectedAppointment === appointmentId
              ? "text-white"
              : "text-primaryblack"
          )}
        >
          {appointment.patient.name}
        </p>
        {status === appointmentStatus.published ? (
          <CheckSvg color={"#6FCF97"} />
        ) : status === appointmentStatus.locked ? (
          <Lock
            color={
              appointment.visitStatus === "COMPLETED"
                ? "#6FCF97"
                : appointment.visitStatus === "NOSHOW"
                ? "#F59638"
                : "#3062D4"
            }
          />
        ) : (
          <Unlock
            color={
              appointment.visitStatus === "COMPLETED"
                ? "#6FCF97"
                : appointment.visitStatus === "NOSHOW"
                ? "#F59638"
                : "#F2C94C"
            }
          />
        )}
      </div>
      <div
        className={classNames(
          "px-2  w-fit bg-white text-xs flex items-center justify-center rounded-md",
          appointment.visitStatus === "COMPLETED" ||
            appointment.visitStatus === "PUBLISHED"
            ? "border-[1px] border-primarygreen text-primarygreen"
            : appointment.visitStatus === "NOSHOW"
            ? "border-[1px] border-primaryorange text-primaryorange"
            : "border-[1px] border-primaryblue text-primaryblue"
        )}
      >
        {appointment.timeslot}
      </div>
      <p
        className={classNames(
          "m-0 text-xs text-primaryblack",
          selectedAppointment === appointmentId
            ? "text-white"
            : "text-primaryblack"
        )}
      >
        {appointment.location.name}
      </p>
      <p
        className={classNames(
          "m-0 text-xs text-primarytextgrey",
          selectedAppointment === appointmentId
            ? "text-white"
            : "text-primaryblack"
        )}
      >
        {indx === 0 || isLastVisit
          ? appointment.distanceFromHome === null
            ? 0
            : appointment.distanceFromHome
          : appointment.distance === null
          ? 0
          : appointment.distance}{" "}
        miles from
        {indx === 0 || isLastVisit ? " home address" : " previous appointment"}
      </p>
      <div className="flex flex-row items-center justify-between w-full gap-1">
        <div className="flex items-center justify-center px-2 text-xs border rounded-md border-primarylightgrey bg-primarylightgrey">
          {appointmentType.name}
        </div>
        <button
          disabled={
            (selectedAppointment !== null &&
              selectedAppointment !== appointmentId) ||
            filterOpen === true ||
            addOpen === true ||
            publishOpen === true
          }
          onClick={() => {
            setSelectedAppointment(appointment);
            if (containerRef?.current) {
              containerRef?.current.scrollTo(0, 0);
            }
          }}
          className={classNames(
            "px-2 bg-white text-xs flex items-center justify-center rounded-md",
            appointment.visitStatus === "COMPLETED" ||
              appointment.visitStatus === "PUBLISHED"
              ? "border-[1px] border-primarygreen text-primarygreen"
              : appointment.visitStatus === "NOSHOW"
              ? "border-[1px] border-primaryorange text-primaryorange"
              : "border-[1px] border-primaryblue text-primaryblue"
          )}
        >
          View Details
        </button>
      </div>
    </div>
  );
};

export default AppointmentCard;
