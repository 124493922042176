import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import { Icons } from "../../../../assets";
import classNames from "classnames";
import CheckboxSelector from "../../../Schedules/components/CheckboxSelector";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@mui/icons-material/Search";
//Graph
import ReactEcharts from "echarts-for-react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import * as echarts from "echarts";
import { Tooltip } from "@mui/material";

function PatientVisitReportTable({
  patients,
  patientReports,
  cities,
  stateNames,
  visitType,
  providers,
  handlePatientNameFilter,
  handleCityNameFilter,
  handleStateNameFilter,
  handleVisitTypeFilter,
  handleProviderFilter,
  handleLocationFilter,
  selectedPatientsName,
  selectedCity,
  selectedStateName,
  selectedVisitType,
  selectedProviders,
  isSelectedValue,
  setPatientReports,
  filteredPt,
  setFilteredPt,
  filteredPv,
  setFilteredPv,
  selectedLocation,
  setFilteredLt,
  filteredLt,
  location,
  isClearCalled,
  isSelectAllPatients,
  setIsSelectAllPatients,
  isSelectAllProviders,
  setIsSelectAllProviders,
  isSelectAllLocations,
  setIsSelectAllLocations,
  isSelectAllVisitType,
  setIsSelectAllVisitType,
  isSelectAllCity,
  setIsSelectAllCity,
  isSelectAllState,
  setIsSelectAllState,
  isTableView,
  status,
  selectedStatus,
  handleStatusFilter,
  setIsSelectAllStatus,
  isSelectAllStatus
}) {
  const [patientNameListOpen, setPatientNameListOpen] = React.useState(false);
  const [locationListOpen, setLocationListOpen] = React.useState(false);
  const [cityListOpen, setCityListOpen] = React.useState(false);
  const [stateListOpen, setStateListOpen] = React.useState(false);
  const [visitTypeListOpen, setVisitTypeListOpen] = React.useState(false);
  const [providerListOpen, setProviderListOpen] = React.useState(false);
  const [statusListOpen, setStatusListOpen] = React.useState(false);

  const [patientNameSearch, setPatientNameSearch] = React.useState("");
  const [locationSearch, setLocationSearch] = React.useState("");
  const [providerNameSearch, setProviderNameSearch] = React.useState("");
  const [isApptDtAse, setIsAppDtAse] = React.useState(true);
  const [isPatientIdAse, setIsPatientIdAse] = React.useState(true);
  const [isZipAse, setIsZipAse] = React.useState(true);

  //graph
  const [months, setMonths] = React.useState([]);
  const [groupedDates, setGroupedDates] = React.useState([]);
  const [selectedMonth, setSelectedMonth] = React.useState("");
  const [isDaily, setIsDaily] = React.useState(true);
  const [isMonthly, setIsMonthly] = React.useState(false);
  const [groupedWeek, setGroupedWeek] = React.useState([]);
  const [viewType, setViewType] = React.useState("Daily");
  const [optionListOpen, setOptionsListOpen] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState({
    value: 1,
    name: "Filter By Providers",
  });
  const [dateToShow, setDateToShow] = React.useState([]);
  const [colors, setColors] = React.useState([]);
  const [chartOptions, setChartOptions] = useState({});
  const [weekDateToShow, setWeekDateToShow] = useState([]);
  const echartsRef = useRef(null);
  const graphRef = useRef(null);
  const [monthToShow, setMonthToShow] = React.useState([]);
  const [groupedMonth, setGroupedMonth] = React.useState([]);

  const dropdownRef = React.useRef(null);
  const dropdownRefCity = React.useRef(null);
  const dropdownRefState = React.useRef(null);
  const dropdownRefVisit = React.useRef(null);
  const dropdownRefProvider = React.useRef(null);
  const dropdownLocationRef = React.useRef(null);
  const dropdownStatusRef = React.useRef(null);

  const DAY_MAP = {
    MONDAY: "M",
    TUESDAY: "T",
    WEDNESDAY: "W",
    THURSDAY: "Th",
    FRIDAY: "F",
    SATURDAY: "Sa",
    SUNDAY: "Su",
  };
  const TABLE_HEADERS = [
    "Visit Date",
    "Patient ID",
    "Patient Name",
    "Provider Name",
    "Visit Type",
    "Location",
    "Patient City",
    "Patient State",
    "Zip",
    "Date of Birth",
    "Status"
  ];

  const parseNull = (value, replacement = "NA", type = "string") => {
    return value === null || (Array.isArray(value) && value.length === 0)
      ? replacement
      : Array.isArray(value)
        ? value.map((day) => DAY_MAP[day]).join(", ")
        : type === "date"
          ? moment(value).format("MM/DD/YYYY")
          : value;
  };

  //Graph
  const getOptions = () => {
    const findMaxCount = (data) => {
      const counts = data.flatMap((item) => item.data);
      const maxCount = Math.max(...counts);
      return maxCount;
    };
    const n1 = findMaxCount(groupedMonth);

    const xAxisData = isMonthly
      ? monthToShow
      : isDaily
        ? Object.keys(dateToShow)?.map((item) =>
          moment(item, "MM/DD/YYYY").format("MM/DD/YYYY")
        )
        : Object.keys(weekDateToShow);
    const yAxisMax = isMonthly
      ? Math.ceil(n1)
      : isDaily
        ? Math.max(...Object.values(dateToShow).map((day) => day.count)) % 2 === 0
          ? Math.max(...Object.values(dateToShow).map((day) => day.count)) + 2
          : Math.max(...Object.values(dateToShow).map((day) => day.count)) + 1
        : Math.max(...Object.values(weekDateToShow).map((day) => day.count)) %
          2 ===
          0
          ? Math.max(...Object.values(weekDateToShow).map((day) => day.count)) + 2
          : Math.max(...Object.values(weekDateToShow).map((day) => day.count)) + 1;

    const yAxisInterval = isMonthly
      ? Math.ceil(n1) > 300
        ? 40
        : 20
      : isDaily
        ? 2
        : Math.ceil(
          Math.max(...Object.values(weekDateToShow).map((day) => day.count)) /
          10
        ) *
          10 >
          10
          ? 10
          : 5;

    const seriesData = isMonthly
      ? groupedMonth?.map((item) => ({
        name: item.name,
        type: "bar",
        data: item.data,
      })) || []
      : isDaily
        ? groupedDates?.map((item) => ({
          name: item.name,
          type: "bar",
          data: item.data,
        })) || []
        : groupedWeek?.map((item) => ({
          name: item.name,
          type: "bar",
          data: item.data,
        })) || [];
    return {
      baseOption: {
        tooltip: {
          trigger: "item",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          left: "center",
          data: seriesData?.map((series) => series.name),
          //selected: namesObject
        },
        xAxis: [
          {
            axisLabel: {
              interval: 0,
              rotate: isDaily ? 55 : 0,
              textStyle: {
                baseline: "top",
                color: "#333",
                fontSize: 10,
                fontWeight: "bold",
              },
            },
            type: "category",
            data: xAxisData,
          },
        ],
        yAxis: {
          type: "value",
          min: 0,
          max: yAxisMax,
          interval: yAxisInterval,
          name: "Appointments",
        },
        series: seriesData,
      },
      options: [
        {
          title: { text: "june" },
          series: seriesData?.map((item) => item.data),
        },
      ],
    };
  };
  const groupByDates = (month, option) => {
    const assReports = [...patientReports].sort(
      (a, b) => new Date(a.visit_date) - new Date(b.visit_date)
    );
    const dates = assReports.map((item) => {
      return {
        date: moment(item.visit_date).format("MM/DD/YYYY"),
        provider: item.provider_name,
        providerId: item.provider_id,
        visitType: item.visit_type,
        visitTypeId: item.visit_type_id,
      };
    });
    if (!dates || dates.length === 0) {
      console.log("No data available");
      return;
    }
    const result = {};
    dates.forEach((entry) => {
      const { date, provider, visitType } = entry;
      const month = date.split("/")[0];

      if (!result[month]) {
        result[month] = {};
      }
      if (!result[month][date]) {
        result[month][date] = {
          count: 0,
          provider: {},
          visitType: {},
        };
      }
      result[month][date].count += 1;
      if (!result[month][date].provider[provider]) {
        result[month][date].provider[provider] = 0;
      }
      result[month][date].provider[provider] += 1;

      if (!result[month][date].visitType[visitType]) {
        result[month][date].visitType[visitType] = 0;
      }
      result[month][date].visitType[visitType] += 1;
    });
    setDateToShow(result[month ? month : Object.keys(result)[0]]);
    if (option?.value == 1) {
      const providers = {};
      const months = Object.keys(result);
      if (months.length === 0) {
        console.log("No months found in data");
        return;
      }
      const firstMonth = month ? month : Object.keys(result)[0];
      const datesInMonth = Object.keys(result[firstMonth]);
      datesInMonth.forEach((date) => {
        const entry = result[firstMonth][date];
        Object.keys(entry.provider).forEach((provider) => {
          if (!providers[provider]) {
            providers[provider] = Array(datesInMonth.length).fill(0);
          }
          const dayIndex = datesInMonth.indexOf(date);
          providers[provider][dayIndex] += entry.provider[provider];
        });
      });
      const colors = generateRandomColors(Object.keys(providers).length);
      const result1 = Object.keys(providers).map((provider, index) => ({
        name: provider,
        type: "bar",
        barGap: 0,
        emphasis: {
          focus: "series",
        },
        data: providers[provider],
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: colors[index] },
            { offset: 0.5, color: colors[index] },
            { offset: 1, color: colors[index] },
          ]),
        },
      }));
      setGroupedDates(result1);
    } else {
      const visitType = {};
      const months = Object.keys(result);
      if (months.length === 0) {
        console.log("No months found in data");
        return;
      }
      const firstMonth = month ? month : months[0];
      const datesInMonth = Object.keys(result[firstMonth]);
      datesInMonth.forEach((date) => {
        const entry = result[firstMonth][date];
        Object.keys(entry.visitType).forEach((type) => {
          if (!visitType[type]) {
            visitType[type] = Array(datesInMonth.length).fill(0);
          }
          const dayIndex = datesInMonth.indexOf(date);
          visitType[type][dayIndex] += entry.visitType[type];
        });
      });
      const colors = generateRandomColors(Object.keys(visitType).length);

      const result1 = Object.keys(visitType).map((type, index) => ({
        name: type,
        type: "bar",
        barGap: 0,
        emphasis: {
          focus: "series",
        },
        data: visitType[type],
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: colors[index] },
            { offset: 0.5, color: colors[index] },
            { offset: 1, color: colors[index] },
          ]),
        },
      }));
      setGroupedDates(result1);
    }
    setMonths(Object.keys(result));
    setSelectedMonth(month ? month : Object.keys(result)[0]);
  };
  const groupByWeek = (month, option) => {
    const assReports = patientReports.sort(
      (a, b) => new Date(a.visit_date) - new Date(b.visit_date)
    );
    const dates = assReports.map((item) => {
      return {
        date: moment(item.visit_date).format("MM/DD/YYYY"),
        provider: item.provider_name,
        providerId: item.provider_id,
        visitType: item.visit_type,
        visitTypeId: item.visit_type_id,
      };
    });
    if (!dates || dates.length === 0) {
      console.log("No data available");
      return;
    }
    const result = {};
    dates.forEach((entry) => {
      const { date, provider, visitType } = entry;
      const month = date.split("/")[0];

      if (!result[month]) {
        result[month] = {};
      }
      if (!result[month][date]) {
        result[month][date] = {
          count: 0,
          provider: {},
          visitType: {},
        };
      }
      result[month][date].count += 1;
      if (!result[month][date].provider[provider]) {
        result[month][date].provider[provider] = 0;
      }
      result[month][date].provider[provider] += 1;

      if (!result[month][date].visitType[visitType]) {
        result[month][date].visitType[visitType] = 0;
      }
      result[month][date].visitType[visitType] += 1;
    });
    const result1 = {};
    const monthData = result[month];

    if (monthData) {
      const weeklyData = {};
      // Iterate over each date in the selected month
      for (const dateKey in monthData) {
        const currentDate = moment(dateKey, "MM/DD/YYYY");
        const weekStart = currentDate.clone().startOf("week");
        const weekEnd = currentDate.clone().endOf("week");
        const weekKey = `${weekStart.format("MM/DD/YYYY")} - ${weekEnd.format(
          "MM/DD/YYYY"
        )}`;
        if (!weeklyData[weekKey]) {
          weeklyData[weekKey] = {};
          weeklyData[weekKey].count = 0;
          weeklyData[weekKey].provider = {};
          weeklyData[weekKey].visitType = {};
        }
        // Accumulate counts for each provider and visit type
        const entry = monthData[dateKey];
        weeklyData[weekKey].count += entry.count;
        for (const provider in entry.provider) {
          if (!weeklyData[weekKey].provider[provider]) {
            weeklyData[weekKey].provider[provider] = 0;
          }
          weeklyData[weekKey].provider[provider] += entry.provider[provider];
        }
        for (const visitType in entry.visitType) {
          if (!weeklyData[weekKey].visitType[visitType]) {
            weeklyData[weekKey].visitType[visitType] = 0;
          }
          weeklyData[weekKey].visitType[visitType] +=
            entry.visitType[visitType];
        }
      }
      // Assign weekly data to the result object
      result1[month] = weeklyData;
      setWeekDateToShow(result1[month]);
      if (option.value == 1) {
        const providers = {};
        const months = Object.keys(result1);
        if (months.length === 0) {
          console.log("No months found in data");
          return;
        }
        const firstMonth = month ? month : months[0];
        const datesInMonth = Object.keys(result1[firstMonth]);
        datesInMonth.forEach((date) => {
          const entry = result1[firstMonth][date];
          Object.keys(entry.provider).forEach((provider) => {
            if (!providers[provider]) {
              providers[provider] = Array(datesInMonth.length).fill(0);
            }
            const dayIndex = datesInMonth.indexOf(date);
            providers[provider][dayIndex] += entry.provider[provider];
          });
        });
        const colors = generateRandomColors(Object.keys(providers).length);
        const result2 = Object.keys(providers).map((provider, index) => ({
          name: provider,
          type: "bar",
          barGap: 0,
          emphasis: {
            focus: "series",
          },
          data: providers[provider],
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: colors[index] },
              { offset: 0.5, color: colors[index] },
              { offset: 1, color: colors[index] },
            ]),
          },
        }));
        setGroupedWeek(result2);
      } else {
        const visitType = {};
        const months = Object.keys(result1);
        if (months.length === 0) {
          console.log("No months found in data");
          return;
        }
        const firstMonth = month ? month : months[0];
        const datesInMonth = Object.keys(result1[firstMonth]);
        datesInMonth.forEach((date) => {
          const entry = result1[firstMonth][date];
          Object.keys(entry.visitType).forEach((type) => {
            if (!visitType[type]) {
              visitType[type] = Array(datesInMonth.length).fill(0);
            }
            const dayIndex = datesInMonth.indexOf(date);
            visitType[type][dayIndex] += entry.visitType[type];
          });
        });
        const colors = generateRandomColors(Object.keys(visitType).length);
        const result2 = Object.keys(visitType).map((type, index) => ({
          name: type,
          type: "bar",
          barGap: 0,
          emphasis: {
            focus: "series",
          },
          data: visitType[type],
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: colors[index] },
              { offset: 0.5, color: colors[index] },
              { offset: 1, color: colors[index] },
            ]),
          },
        }));
        setGroupedWeek(result2);
      }
    } else {
      console.log("No data found for the selected month.");
    }
  };
  const groupByMonth = (option) => {
    const assReports = patientReports.sort(
      (a, b) => new Date(a.visit_date) - new Date(b.visit_date)
    );
    const dates = assReports.map((item) => {
      return {
        date: moment(item.visit_date).format("MM/DD/YYYY"),
        provider: item.provider_name,
        providerId: item.provider_id,
        visitType: item.visit_type,
        visitTypeId: item.visit_type_id,
      };
    });
    const result = {};
    dates.forEach((entry) => {
      const { date, provider, visitType } = entry;
      const month = date.split("/")[0];

      if (!result[month]) {
        result[month] = {};
      }
      if (!result[month][date]) {
        result[month][date] = {
          count: 0,
          provider: {},
          visitType: {},
        };
      }
      result[month][date].count += 1;
      if (!result[month][date].provider[provider]) {
        result[month][date].provider[provider] = 0;
      }
      result[month][date].provider[provider] += 1;

      if (!result[month][date].visitType[visitType]) {
        result[month][date].visitType[visitType] = 0;
      }
      result[month][date].visitType[visitType] += 1;
    });
    const monthNames = Object.keys(result).map((monthNumber) => {
      const date = new Date(`2024-${monthNumber}-01`);
      const monthName = date.toLocaleString("default", { month: "long" });
      return monthName;
    });
    setMonthToShow(monthNames);
    if (option.value == 1) {
      const result1 = {};
      const months = Object.keys(result);
      for (const month of months) {
        const monthlyCounts = {};
        if (result[month]) {
          for (const day in result[month]) {
            const dayData = result[month][day];
            for (const provider in dayData.provider) {
              if (!monthlyCounts[provider]) {
                monthlyCounts[provider] = 0;
              }
              monthlyCounts[provider] += dayData.provider[provider];
            }
          }
        }
        for (const provider in monthlyCounts) {
          if (!result1[provider]) {
            result1[provider] = new Array(months.length).fill(0);
          }
          result1[provider][months.indexOf(month)] = monthlyCounts[provider];
        }
      }
      for (const provider in result1) {
        for (let i = 0; i < months.length; i++) {
          if (result1[provider][i] === undefined) {
            result1[provider][i] = 0;
          }
        }
      }
      const result2 = Object.keys(result1).map((item, index) => ({
        name: item,
        type: "bar",
        barGap: 0,
        emphasis: {
          focus: "series",
        },
        data: result1[item],
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: colors[index] },
            { offset: 0.5, color: colors[index] },
            { offset: 1, color: colors[index] },
          ]),
        },
      }));
      setGroupedMonth(result2);
    } else {
      const result1 = {};
      const months = Object.keys(result);
      for (const month of months) {
        const monthlyCounts = {};
        if (result[month]) {
          for (const day in result[month]) {
            const dayData = result[month][day];
            for (const visitType in dayData.visitType) {
              if (!monthlyCounts[visitType]) {
                monthlyCounts[visitType] = 0;
              }
              monthlyCounts[visitType] += dayData.visitType[visitType];
            }
          }
        }
        for (const visitType in monthlyCounts) {
          if (!result1[visitType]) {
            result1[visitType] = new Array(months.length).fill(0);
          }
          result1[visitType][months.indexOf(month)] = monthlyCounts[visitType];
        }
      }
      for (const visitType in result1) {
        for (let i = 0; i < months.length; i++) {
          if (result1[visitType][i] === undefined) {
            result1[visitType][i] = 0;
          }
        }
      }
      const result2 = Object.keys(result1).map((item, index) => ({
        name: item,
        type: "bar",
        barGap: 0,
        emphasis: {
          focus: "series",
        },
        data: result1[item],
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: colors[index] },
            { offset: 0.5, color: colors[index] },
            { offset: 1, color: colors[index] },
          ]),
        },
      }));
      setGroupedMonth(result2);
    }
  };
  function generateRandomDarkColor() {
    const minDarkValue = 0;
    const maxDarkValue = 200;
    const randomRed =
      minDarkValue +
      Math.floor(Math.random() * (maxDarkValue - minDarkValue + 1));
    const randomGreen =
      minDarkValue +
      Math.floor(Math.random() * (maxDarkValue - minDarkValue + 1));
    const randomBlue =
      minDarkValue +
      Math.floor(Math.random() * (maxDarkValue - minDarkValue + 1));
    const darkHex = (
      (1 << 24) +
      (randomRed << 16) +
      (randomGreen << 8) +
      randomBlue
    )
      .toString(16)
      .slice(1);
    return `#${darkHex}`;
  }
  function generateRandomColors(count) {
    const colors = [];
    for (let i = 0; i < count; i++) {
      colors.push(generateRandomDarkColor());
    }
    return colors;
  }
  // Handlers
  const handlePatientListOpen = () => {
    setPatientNameListOpen(!patientNameListOpen);
    setCityListOpen(false);
    setStateListOpen(false);
    setVisitTypeListOpen(false);
    setProviderListOpen(false);
    if (patientNameListOpen) {
      setPatientNameSearch("");
      setFilteredPt(patients);
    }
    setProviderNameSearch("");
    setFilteredPv(providers);
    setStatusListOpen(false);
  };
  const handleLocationListOpen = () => {
    setLocationListOpen(!locationListOpen);
    setCityListOpen(false);
    setStateListOpen(false);
    setVisitTypeListOpen(false);
    setProviderListOpen(false);
    if (locationListOpen) {
      setLocationSearch("");
      setFilteredPt(location);
    }
    setProviderNameSearch("");
    setLocationSearch("");
    setFilteredPv(providers);
    setPatientNameListOpen(false);
    setStatusListOpen(false);
  };
  const handleStatusListOpen = () => {
    setStatusListOpen(!statusListOpen);
    setLocationListOpen(false);
    setCityListOpen(false);
    setStateListOpen(false);
    setVisitTypeListOpen(false);
    setProviderListOpen(false);
    if (locationListOpen) {
      setLocationSearch("");
      setFilteredPt(location);
    }
    setProviderNameSearch("");
    setLocationSearch("");
    setFilteredPv(providers);
    setPatientNameListOpen(false);
  };
  const handleCityListOpen = () => {
    setCityListOpen(!cityListOpen);
    setStateListOpen(false);
    setVisitTypeListOpen(false);
    setProviderListOpen(false);
    setPatientNameListOpen(false);
    setPatientNameSearch("");
    setLocationSearch("");
    setFilteredPt(patients);
    setProviderNameSearch("");
    setFilteredPv(providers);
    setFilteredLt(location);
    setStatusListOpen(false);
  };
  const handleStateListOpen = () => {
    setStateListOpen(!stateListOpen);
    setVisitTypeListOpen(false);
    setProviderListOpen(false);
    setPatientNameListOpen(false);
    setPatientNameSearch("");
    setLocationSearch("");
    setFilteredPt(patients);
    setCityListOpen(false);
    setProviderNameSearch("");
    setFilteredPv(providers);
    setFilteredLt(location);
    setStatusListOpen(false);
  };
  const handleVisitTypeListOpen = () => {
    setStateListOpen(false);
    setVisitTypeListOpen(!visitTypeListOpen);
    setProviderListOpen(false);
    setPatientNameListOpen(false);
    setPatientNameSearch("");
    setLocationSearch("");
    setFilteredPt(patients);
    setCityListOpen(false);
    setProviderNameSearch("");
    setFilteredPv(providers);
    setFilteredLt(location);
    setStatusListOpen(false);
  };
  const handleProviderListOpen = () => {
    setProviderListOpen(!providerListOpen);
    setStateListOpen(false);
    setVisitTypeListOpen(false);
    setPatientNameListOpen(false);
    setPatientNameSearch("");
    setLocationSearch("");
    setFilteredPt(patients);
    setCityListOpen(false);
    if (providerListOpen) {
      setProviderNameSearch("");
      setFilteredPv(providers);
    }
    setStatusListOpen(false);
  };
  const handleSelectAllPatients = () => {
    handlePatientNameFilter(-1, {
      selectAll: true,
      isSelectAll: !isSelectAllPatients,
    });
    setIsSelectAllPatients(!isSelectAllPatients);
  };
  const handleSelectAllProviders = () => {
    handleProviderFilter(-1, {
      selectAll: true,
      isSelectAll: !isSelectAllProviders,
    });
    setIsSelectAllProviders(!isSelectAllProviders);
  };
  const handleSelectAllVisitType = () => {
    handleVisitTypeFilter(-1, {
      selectAll: true,
      isSelectAll: !isSelectAllVisitType,
    });
    setIsSelectAllVisitType(!isSelectAllVisitType);
  };
  const handleSelectAllLocations = () => {
    handleLocationFilter(-1, {
      selectAll: true,
      isSelectAll: !isSelectAllLocations,
    });
    setIsSelectAllLocations(!isSelectAllLocations);
  };
  const handleSelectAllStatus = () => {
    handleStatusFilter(-1, {
      selectAll: true,
      isSelectAll: !isSelectAllStatus,
    });
    setIsSelectAllStatus(!isSelectAllStatus)
  };

  const handleSelectAllCities = () => {
    handleCityNameFilter(-1, {
      selectAll: true,
      isSelectAll: !isSelectAllCity,
    });
    setIsSelectAllCity(!isSelectAllCity);
  };
  const handleSelectAllStates = () => {
    handleStateNameFilter(-1, {
      selectAll: true,
      isSelectAll: !isSelectAllState,
    });
    setIsSelectAllState(!isSelectAllState);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setPatientNameListOpen(false);
      setPatientNameSearch("");
      setFilteredPt(patients);
    }
    if (
      dropdownRefCity.current &&
      !dropdownRefCity.current.contains(event.target)
    ) {
      setCityListOpen(false);
    }
    if (
      dropdownRefState.current &&
      !dropdownRefState.current.contains(event.target)
    ) {
      setStateListOpen(false);
    }
    if (
      dropdownRefVisit.current &&
      !dropdownRefVisit.current.contains(event.target)
    ) {
      setVisitTypeListOpen(false);
    }
    if (
      dropdownRefProvider.current &&
      !dropdownRefProvider.current.contains(event.target)
    ) {
      setProviderListOpen(false);
      setProviderNameSearch("");
      setFilteredPv(providers);
    }
    if (
      dropdownLocationRef.current &&
      !dropdownLocationRef.current.contains(event.target)
    ) {
      setLocationListOpen(false);
      setLocationSearch("");
      setFilteredLt(location);
    }
    if (dropdownStatusRef.current && !dropdownStatusRef.current.contains(event.target)) {
      setStatusListOpen(false);
    }
    if (graphRef.current && !graphRef.current.contains(event.target)) {
      setOptionsListOpen(false);
    }
  };

  // useEffects
  React.useEffect(() => {
    if (echartsRef.current) {
      const options = getOptions();
      echartsRef.current.getEchartsInstance().setOption(options, true);
    }
  }, [
    selectedMonth,
    isDaily,
    groupedDates,
    groupedWeek,
    dateToShow,
    selectedOption,
  ]);

  React.useEffect(() => {
    groupByDates(undefined, selectedOption);
    if (!isDaily) {
      setIsDaily(true);
      setIsMonthly(false);
      groupByDates(undefined, selectedOption);
    }
    viewType !== "Daily" ? setViewType("Daily") : "";
  }, [patientReports]);
  React.useEffect(() => {
    if (
      patientNameListOpen ||
      cityListOpen ||
      stateListOpen ||
      visitTypeListOpen ||
      providerListOpen ||
      locationListOpen ||
      optionListOpen ||
      statusListOpen
    ) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    patientNameListOpen,
    cityListOpen,
    stateListOpen,
    visitTypeListOpen,
    providerListOpen,
    locationListOpen,
    optionListOpen,
    statusListOpen
  ]);
  React.useEffect(() => {
    setIsAppDtAse(true);
    setIsPatientIdAse(true);
    setIsZipAse(true);
    selectedPatientsName.length === patients.length
      ? setIsSelectAllPatients(true)
      : setIsSelectAllPatients(false);
    selectedVisitType.length === visitType.length
      ? setIsSelectAllVisitType(true)
      : setIsSelectAllVisitType(false);
    selectedProviders.length === providers.length
      ? setIsSelectAllProviders(true)
      : setIsSelectAllProviders(false);
    selectedLocation.length === location.length
      ? setIsSelectAllLocations(true)
      : setIsSelectAllLocations(false);
    selectedCity.length === cities.length
      ? setIsSelectAllCity(true)
      : setIsSelectAllCity(false);
    selectedStateName.length === stateNames.length
      ? setIsSelectAllState(true)
      : setIsSelectAllState(false);
    selectedStatus.length === status.length ?
      setIsSelectAllStatus(true)
      : setIsSelectAllStatus(false);
  }, [
    selectedPatientsName,
    selectedCity,
    selectedProviders,
    selectedStateName,
    selectedVisitType,
    selectedLocation,
    selectedStatus
  ]);
  return (
    <>
      <div className="h-screen overflow-auto mt-2 w-full">
        {isTableView ? (
          <table
            className="w-full "
            id="patientVisitReport"
            style={{ whiteSpace: "nowrap" }}
          >
            <thead>
              <tr className="bg-primarylightgrey">
                {TABLE_HEADERS.map((header, index) => {
                  if (header === "Patient Name") {
                    return (
                      <th
                        key={index}
                        className={
                          "px-2 py-3 text-base relative min-w-56 " +
                          (patientNameListOpen ||
                            selectedPatientsName.length > 0
                            ? "bg-highlight"
                            : "")
                        }
                        ref={dropdownRef}
                      >
                        <div className="flex flex-row items-center">
                          <p className="m-0">
                            {header}
                            <span className="dropdown-icon ml-3"></span>
                          </p>
                          <Tooltip title="Sort">
                            <button
                              className="pr-2"
                              onClick={handlePatientListOpen}
                            >
                              <img
                                src={Icons.Sort_Icon}
                                alt="search"
                                className="w-5 h-5 cursor-pointer search-icon"
                              />
                            </button>
                          </Tooltip>{" "}
                          {patientNameListOpen && (
                            <div className="absolute z-50 flex flex-col w-full overflow-auto no-scrollbar bg-white border  max-h-52 top-16 left-0">
                              <div className="d-flex align-center sticky top-0 bg-white">
                                <input
                                  type="search"
                                  value={patientNameSearch}
                                  onChange={(e) => {
                                    setPatientNameSearch(e.target.value);
                                    setFilteredPt(
                                      patients.filter((patient) => {
                                        return patient.name
                                          .toLowerCase()
                                          .includes(
                                            e.target.value.toLowerCase()
                                          );
                                      })
                                    );
                                  }}
                                  className="search-input"
                                  autoFocus
                                  placeholder="Search"
                                />
                                {patientNameSearch === "" ? (
                                  <SearchIcon className="search-icon-input " />
                                ) : (
                                  ""
                                )}
                              </div>
                              {filteredPt.length > 0 ? (
                                <>
                                  <th
                                    className={classNames(
                                      "px-4 py-3 cursor-pointer text-sm"
                                    )}
                                  >
                                    <label className="flex flex-row items-center gap-2 cursor-pointer">
                                      <input
                                        type="checkbox"
                                        className="w-4 h-4 checked:lightblue"
                                        checked={
                                          isSelectAllPatients ||
                                          selectedPatientsName.length ===
                                          patients.length
                                        }
                                        onChange={(e) => {
                                          handleSelectAllPatients();
                                        }}
                                      />
                                      <p
                                        className={classNames(
                                          "m-0 cursor-pointer text-sm font-bold"
                                        )}
                                      >
                                        Select All
                                      </p>
                                    </label>
                                  </th>
                                  {filteredPt.map((patient, index) => (
                                    <div
                                      className={classNames(
                                        "hover:bg-primarylightgrey",
                                        patients.length - 1 && "border-b"
                                      )}
                                    >
                                      <CheckboxSelector
                                        header={patient.name}
                                        isChecked={
                                          selectedPatientsName.includes(
                                            patient.id
                                          )
                                            ? true
                                            : false
                                        }
                                        handleChange={(_e) =>
                                          handlePatientNameFilter(patient.id, {
                                            selectAll: false,
                                            isSelectAllPatients,
                                          })
                                        }
                                        isBold={false}
                                      />
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <div className="no-record-found">
                                  No Patients Found
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </th>
                    );
                  } else if (header === "Location") {
                    return (
                      <th
                        key={index}
                        className={
                          "px-2 py-3 text-base relative min-w-56 " +
                          (locationListOpen || selectedLocation.length > 0
                            ? "bg-highlight"
                            : "")
                        }
                        ref={dropdownLocationRef}
                      >
                        <div className="flex flex-row items-center justify-center">
                          <p className="m-0">
                            {header}
                            <span className="dropdown-icon ml-3"></span>
                          </p>
                          <Tooltip title="Sort">
                            <button
                              className="pr-2"
                              onClick={handleLocationListOpen}
                            >
                              <img
                                src={Icons.Sort_Icon}
                                alt="search"
                                className="w-5 h-5 cursor-pointer search-icon"
                              />
                            </button>
                          </Tooltip>{" "}
                          {locationListOpen && (
                            <div className="absolute z-50 flex flex-col w-full overflow-auto no-scrollbar bg-white border  max-h-52 top-16 left-0">
                              <div className="d-flex align-center sticky top-0 bg-white">
                                <input
                                  type="search"
                                  value={locationSearch}
                                  onChange={(e) => {
                                    setLocationSearch(e.target.value);
                                    setFilteredLt(
                                      location.filter((location) => {
                                        return (
                                          location?.community !== null
                                            ? location.community.name
                                            : location?.name || ""
                                        )
                                          .toLowerCase()
                                          .includes(
                                            e.target.value.toLowerCase()
                                          );
                                      })
                                    );
                                  }}
                                  className="search-input"
                                  autoFocus
                                  placeholder="Search"
                                />
                                {locationSearch === "" ? (
                                  <SearchIcon className="search-icon-input " />
                                ) : (
                                  ""
                                )}
                              </div>
                              {filteredLt.length > 0 ? (
                                <>
                                  <th
                                    className={classNames(
                                      "px-4 py-3 cursor-pointer text-sm"
                                    )}
                                  >
                                    <label className="flex flex-row items-center gap-2 cursor-pointer">
                                      <input
                                        type="checkbox"
                                        className="w-4 h-4 checked:lightblue"
                                        checked={
                                          isSelectAllLocations ||
                                          selectedLocation.length ===
                                          location.length
                                        }
                                        onChange={(e) => {
                                          handleSelectAllLocations();
                                        }}
                                      />
                                      <p
                                        className={classNames(
                                          "m-0 cursor-pointer text-sm font-bold"
                                        )}
                                      >
                                        Select All
                                      </p>
                                    </label>
                                  </th>
                                  {filteredLt.map((location, index) => (
                                    <div
                                      className={classNames(
                                        "hover:bg-primarylightgrey",
                                        location.length - 1 && "border-b"
                                      )}
                                    >
                                      {console.log(location?.name, ">>>")}
                                      <CheckboxSelector
                                        header={
                                          location?.community === null || location?.community === undefined
                                            ? location?.name : location?.community?.name
                                        }
                                        isChecked={
                                          selectedLocation.includes(location.id)
                                            ? true
                                            : false
                                        }
                                        handleChange={(_e) =>
                                          handleLocationFilter(location.id, {
                                            selectAll: false,
                                            isSelectAll: !isSelectAllLocations,
                                          })
                                        }
                                        isBold={false}
                                      />
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <div className="no-record-found">
                                  No Location Found
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </th>
                    );
                  } else if (header === "Patient City") {
                    return (
                      <th
                        key={index}
                        className={
                          "px-2 py-3 text-base relative city min-w-48 " +
                          (selectedCity.length > 0 || cityListOpen
                            ? "bg-highlight"
                            : "")
                        }
                        ref={dropdownRefCity}
                      >
                        <div className="flex flex-row items-center justify-center">
                          <p className="m-0">
                            {header}{" "}
                            <span className="dropdown-icon ml-3"></span>
                          </p>
                          <Tooltip title="Sort">
                            <button
                              className="pr-2"
                              onClick={handleCityListOpen}
                            >
                              <img
                                src={Icons.Sort_Icon}
                                alt="search"
                                className="w-5 h-5 cursor-pointer search-icon"
                              />
                            </button>
                          </Tooltip>
                          {cityListOpen && (
                            <div className="absolute z-50 flex flex-col w-full overflow-auto no-scrollbar bg-white border  max-h-52 top-16">
                              {cities.length > 0 ? (
                                <>
                                  <th
                                    className={classNames(
                                      "px-4 py-3 cursor-pointer text-sm"
                                    )}
                                  >
                                    <label className="flex flex-row items-center gap-2 cursor-pointer">
                                      <input
                                        type="checkbox"
                                        className="w-4 h-4 checked:lightblue"
                                        checked={
                                          isSelectAllCity ||
                                          selectedCity.length === cities.length
                                        }
                                        onChange={(e) => {
                                          handleSelectAllCities();
                                        }}
                                      />
                                      <p
                                        className={classNames(
                                          "m-0 cursor-pointer text-sm font-bold"
                                        )}
                                      >
                                        Select All
                                      </p>
                                    </label>
                                  </th>
                                  {cities.map((city, index) => (
                                    <div
                                      className={classNames(
                                        "hover:bg-primarylightgrey",
                                        cities.length - 1 && "border-b"
                                      )}
                                    >
                                      <CheckboxSelector
                                        header={city}
                                        isChecked={
                                          selectedCity.includes(city)
                                            ? true
                                            : false
                                        }
                                        handleChange={(_e) =>
                                          handleCityNameFilter(city, {
                                            selectAll: false,
                                            isSelectAll: !isSelectAllCity,
                                          })
                                        }
                                        isBold={false}
                                      />
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <div className="no-record-found ">
                                  No Cities Found
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </th>
                    );
                  } else if (header === "Patient State") {
                    return (
                      <th
                        key={index}
                        className={
                          "px-2 py-3 text-base min-w-40 relative " +
                          (selectedStateName.length > 0 || stateListOpen
                            ? "bg-highlight"
                            : "")
                        }
                        ref={dropdownRefState}
                      >
                        <div className="flex flex-row items-center justify-center">
                          <p className="m-0">
                            {header}
                            <span className="dropdown-icon ml-3"></span>
                          </p>
                          <Tooltip title="Sort">
                            <button
                              className="pr-2"
                              onClick={handleStateListOpen}
                            >
                              <img
                                src={Icons.Sort_Icon}
                                alt="search"
                                className="w-5 h-5 cursor-pointer search-icon"
                              />
                            </button>
                          </Tooltip>
                          {stateListOpen && (
                            <div className="absolute z-50 flex flex-col w-full no-scrollbar overflow-auto bg-white border  max-h-52 top-16">
                              {stateNames.length > 0 ? (
                                <>
                                  <th
                                    className={classNames(
                                      "px-4 py-3 cursor-pointer text-sm"
                                    )}
                                  >
                                    <label className="flex flex-row items-center gap-2 cursor-pointer">
                                      <input
                                        type="checkbox"
                                        className="w-4 h-4 checked:lightblue"
                                        checked={
                                          isSelectAllState ||
                                          selectedStateName.length ===
                                          stateNames.length
                                        }
                                        onChange={(e) => {
                                          handleSelectAllStates();
                                        }}
                                      />
                                      <p
                                        className={classNames(
                                          "m-0 cursor-pointer text-sm font-bold"
                                        )}
                                      >
                                        Select All
                                      </p>
                                    </label>
                                  </th>
                                  {stateNames.map((stateName, index) => (
                                    <div
                                      className={classNames(
                                        "hover:bg-primarylightgrey",
                                        stateName.length - 1 && "border-b"
                                      )}
                                    >
                                      <CheckboxSelector
                                        header={stateName}
                                        isChecked={
                                          selectedStateName.includes(stateName)
                                            ? true
                                            : false
                                        }
                                        handleChange={(_e) =>
                                          handleStateNameFilter(stateName, {
                                            selectAll: false,
                                            isSelectAll: !isSelectAllState,
                                          })
                                        }
                                        isBold={false}
                                      />
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <div className="no-record-found ">
                                  No States Found
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </th>
                    );
                  } else if (header === "Visit Type") {
                    return (
                      <th
                        key={index}
                        className={
                          "px-2 py-3 text-base relative min-w-56 " +
                          (selectedVisitType.length > 0 || visitTypeListOpen
                            ? "bg-highlight"
                            : "")
                        }
                        ref={dropdownRefVisit}
                      >
                        <div className="flex flex-row items-center justify-center">
                          <p className="m-0">
                            {header}{" "}
                            <span className="dropdown-icon ml-3"></span>
                          </p>
                          <Tooltip title="Sort">
                            <button
                              className="pr-2"
                              onClick={handleVisitTypeListOpen}
                            >
                              <img
                                src={Icons.Sort_Icon}
                                alt="search"
                                className="w-5 h-5 cursor-pointer search-icon"
                              />
                            </button>
                          </Tooltip>
                          {visitTypeListOpen && (
                            <div className="absolute z-50 flex flex-col w-full overflow-auto no-scrollbar bg-white border  max-h-52 top-16 ">
                              {visitType.length > 0 ? (
                                <>
                                  <th
                                    className={classNames(
                                      "px-4 py-3 cursor-pointer text-sm"
                                    )}
                                  >
                                    <label className="flex flex-row items-center gap-2 cursor-pointer">
                                      <input
                                        type="checkbox"
                                        className="w-4 h-4 checked:lightblue"
                                        checked={
                                          isSelectAllVisitType ||
                                          selectedVisitType.length ===
                                          visitType.length
                                        }
                                        onChange={(e) => {
                                          handleSelectAllVisitType();
                                        }}
                                      />
                                      <p
                                        className={classNames(
                                          "m-0 cursor-pointer text-sm font-bold"
                                        )}
                                      >
                                        {isSelectAllVisitType ||
                                          selectedVisitType.length ===
                                          visitType.length
                                          ? "Select All"
                                          : "Deselect All"}
                                      </p>
                                    </label>
                                  </th>
                                  {visitType.map((type, index) => (
                                    <div
                                      className={classNames(
                                        "hover:bg-primarylightgrey",
                                        visitType.length - 1 && "border-b"
                                      )}
                                    >
                                      <CheckboxSelector
                                        header={type.name}
                                        isChecked={
                                          selectedVisitType.includes(type.id)
                                            ? true
                                            : false
                                        }
                                        handleChange={(_e) =>
                                          handleVisitTypeFilter(type.id, {
                                            selectAll: false,
                                            isSelectAll: !isSelectAllVisitType,
                                          })
                                        }
                                        isBold={false}
                                      />
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <div className="no-record-found ">
                                  No Visit Type Found
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </th>
                    );
                  } else if (header === "Provider Name") {
                    return (
                      <th
                        key={index}
                        className={
                          "px-2 py-3 text-base relative min-w-56 " +
                          (selectedProviders.length > 0 || providerListOpen
                            ? "bg-highlight"
                            : "")
                        }
                        ref={dropdownRefProvider}
                      >
                        <div className="flex flex-row items-center">
                          <p className="m-0">
                            {header}{" "}
                            <span className="dropdown-icon ml-3"></span>
                          </p>
                          <Tooltip title="Sort">
                            <button
                              className="pr-2"
                              onClick={handleProviderListOpen}
                            >
                              <img
                                src={Icons.Sort_Icon}
                                alt="search"
                                className="w-5 h-5 cursor-pointer search-icon"
                              />
                            </button>
                          </Tooltip>
                          {providerListOpen && (
                            <div className="absolute z-50 flex flex-col w-full overflow-auto no-scrollbar left-0 bg-white border  max-h-52 top-16">
                              <div className="d-flex align-center sticky top-0 bg-white">
                                <input
                                  type="search"
                                  value={providerNameSearch}
                                  onChange={(e) => {
                                    setProviderNameSearch(e.target.value);
                                    setFilteredPv(
                                      providers.filter((provider) => {
                                        return provider.name
                                          .toLowerCase()
                                          .includes(
                                            e.target.value.toLowerCase()
                                          );
                                      })
                                    );
                                  }}
                                  className="search-input"
                                  autoFocus
                                  placeholder="Search"
                                />
                                {providerNameSearch === "" ? (
                                  <SearchIcon className="search-icon-input" />
                                ) : (
                                  ""
                                )}
                              </div>
                              {filteredPv.length > 0 ? (
                                <>
                                  <th
                                    className={classNames(
                                      "px-4 py-3 cursor-pointer text-sm"
                                    )}
                                  >
                                    <label className="flex flex-row items-center gap-2 cursor-pointer">
                                      <input
                                        type="checkbox"
                                        className="w-4 h-4 checked:lightblue"
                                        checked={
                                          isSelectAllProviders ||
                                          selectedProviders.length ===
                                          providers.length
                                        }
                                        onChange={(e) => {
                                          handleSelectAllProviders();
                                        }}
                                      />
                                      <p
                                        className={classNames(
                                          "m-0 cursor-pointer text-sm font-bold"
                                        )}
                                      >
                                        Select All
                                      </p>
                                    </label>
                                  </th>
                                  {filteredPv.map((provider, index) => (
                                    <div
                                      className={classNames(
                                        "hover:bg-primarylightgrey",
                                        providers.length - 1 && "border-b"
                                      )}
                                    >
                                      <CheckboxSelector
                                        header={provider.name}
                                        isChecked={
                                          selectedProviders.includes(
                                            provider.id
                                          )
                                            ? true
                                            : false
                                        }
                                        handleChange={(_e) =>
                                          handleProviderFilter(provider.id, {
                                            selectAll: false,
                                            isSelectAll: !isSelectAllProviders,
                                          })
                                        }
                                        isBold={false}
                                      />
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <div className="no-record-found">
                                  No Providers Found
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </th>
                    );
                  } else if (header === "Visit Date") {
                    return (
                      <th
                        key={index}
                        className="px-2 py-3 jusify-center text-base min-w-40"
                      >
                        <div className="d-flex flex-row items-center  jusify-center relative">
                          <div className="d-flex align-items-center w-full justify-center">
                            <p className="m-0">{header}</p>
                            {isApptDtAse ? (
                              <Tooltip title="Sort Descending">
                                <ArrowDropUpIcon
                                  style={{
                                    fontSize: 30,
                                    color: "#212529",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setIsAppDtAse(false);
                                    const result = [...patientReports].sort(
                                      (a, b) =>
                                        new Date(a.visit_date) -
                                        new Date(b.visit_date)
                                    );
                                    setPatientReports(result);
                                  }}
                                  value="asc"
                                  className="arrow-up"
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip title="Sort Ascending">
                                <ArrowDropDownIcon
                                  style={{
                                    fontSize: 30,
                                    color: "#212529",
                                    cursor: "pointer",
                                  }}
                                  value="des"
                                  className="arrow-down"
                                  onClick={() => {
                                    setIsAppDtAse(true);
                                    const result = [...patientReports].sort(
                                      (a, b) =>
                                        new Date(b.visit_date) -
                                        new Date(a.visit_date)
                                    );
                                    setPatientReports(result);
                                  }}
                                />
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      </th>
                    );
                  } else if (header === "Patient Id") {
                    return (
                      <th key={index} className="px-2 py-3 text-base">
                        <div className="d-flex flex-row items-center jusify-center relative ">
                          <div className="d-flex align-items-center w-full justify-center">
                            <p className="m-0">{header}</p>
                            {isPatientIdAse ? (
                              <Tooltip title="Sort Descending">
                                <ArrowDropUpIcon
                                  style={{
                                    fontSize: 30,
                                    color: "#212529",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setIsPatientIdAse(false);
                                    const result = [...patientReports].sort(
                                      (a, b) =>
                                        a.patient_ehr_id - b.patient_ehr_id
                                    );
                                    setPatientReports(result);
                                  }}
                                  value="asc"
                                  className="arrow-up"
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip title="Sort Ascending">
                                <ArrowDropDownIcon
                                  style={{
                                    fontSize: 30,
                                    color: "#212529",
                                    cursor: "pointer",
                                  }}
                                  value="des"
                                  className="arrow-down"
                                  onClick={() => {
                                    setIsPatientIdAse(true);
                                    const result = [...patientReports].sort(
                                      (a, b) =>
                                        b.patient_ehr_id - a.patient_ehr_id
                                    );
                                    setPatientReports(result);
                                  }}
                                />
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      </th>
                    );
                  } else if (header === "Zip") {
                    return (
                      <th
                        key={index}
                        className="px-2 py-3 text-base min-w-28 jusify-center"
                      >
                        <div className="d-flex flex-row  jusify-center relative items-center">
                          <div className="d-flex align-items-center w-full justify-center">
                            <p className="m-0">{header}</p>
                            {isZipAse ? (
                              <Tooltip title="Sort Descending">
                                <ArrowDropUpIcon
                                  style={{
                                    fontSize: 30,
                                    color: "#212529",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setIsZipAse(false);
                                    const result = [...patientReports].sort(
                                      (a, b) =>
                                        parseInt(a.zip.split("-")[0]) -
                                        parseInt(b.zip.split("-")[0])
                                    );
                                    setPatientReports(result);
                                  }}
                                  value="asc"
                                  className="arrow-up"
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip title="Sort Ascending">
                                <ArrowDropDownIcon
                                  style={{
                                    fontSize: 30,
                                    color: "#212529",
                                    cursor: "pointer",
                                  }}
                                  value="des"
                                  className="arrow-down"
                                  onClick={() => {
                                    setIsZipAse(true);
                                    const result = [...patientReports].sort(
                                      (a, b) =>
                                        parseInt(b.zip.split("-")[0]) -
                                        parseInt(a.zip.split("-")[0])
                                    );
                                    setPatientReports(result);
                                  }}
                                />
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      </th>
                    );
                  } else if (header === "Status") {
                    return (
                      <th
                        key={index}
                        className={
                          "px-2 py-3 text-base relative min-w-56 " +
                          (statusListOpen || selectedStatus.length > 0
                            ? "bg-highlight"
                            : "")
                        }
                        ref={dropdownStatusRef}
                      >
                        <div className="flex flex-row items-center justify-center">
                          <p className="m-0">
                            {header}
                            <span className="dropdown-icon ml-3"></span>
                          </p>
                          <Tooltip title="Sort">
                            <button
                              className="pr-2"
                              onClick={handleStatusListOpen}
                            >
                              <img
                                src={Icons.Sort_Icon}
                                alt="search"
                                className="w-5 h-5 cursor-pointer search-icon"
                              />
                            </button>
                          </Tooltip>{" "}
                          {statusListOpen && (
                            <div className="absolute z-50 flex flex-col w-full overflow-auto no-scrollbar bg-white border  max-h-52 top-16 left-0">
                              {status.length > 0 ? (
                                <>
                                  <th
                                    className={classNames(
                                      "px-4 py-3 cursor-pointer text-sm"
                                    )}
                                  >
                                    <label className="flex flex-row items-center gap-2 cursor-pointer">
                                      <input
                                        type="checkbox"
                                        className="w-4 h-4 checked:lightblue"
                                        checked={
                                          isSelectAllStatus ||
                                          selectedStatus.length ===
                                          status.length
                                        }
                                        onChange={(e) => {
                                          handleSelectAllStatus();
                                        }}
                                      />
                                      <p
                                        className={classNames(
                                          "m-0 cursor-pointer text-sm font-bold"
                                        )}
                                      >
                                        Select All
                                      </p>
                                    </label>
                                  </th>
                                  {status.map((st, index) => (
                                    <div
                                      className={classNames(
                                        "hover:bg-primarylightgrey",
                                        st.length - 1 && "border-b"
                                      )}
                                    >
                                      <CheckboxSelector
                                        header={st?.name}
                                        isChecked={
                                          selectedStatus.includes(st.id)
                                            ? true
                                            : false
                                        }
                                        handleChange={(_e) =>
                                          handleStatusFilter(st.id, {
                                            selectAll: false,
                                            isSelectAll: !isSelectAllStatus,
                                          })
                                        }
                                        isBold={false}
                                      />
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <div className="no-record-found">
                                  No Status Found
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </th>
                    );
                  } else {
                    return (
                      <th key={index} className="px-2 py-3 text-base">
                        <div className="flex items-center justify-center">
                          <p className="m-0">{header}</p>
                        </div>
                      </th>
                    );
                  }
                })}
              </tr>
            </thead>
            {patientReports.length > 0 ? (
              <tbody>
                {patientReports.map((patient, index) => {
                  const isEven = (index + 1) % 2 === 0;
                  return (
                    <tr
                      className={`text-base border-bottom text-left text-gray-900 border-bottom ${isEven ? "bg-primarylightgrey" : ""
                        }`}
                    >
                      <td className="px-2 py-3">
                        {parseNull(patient.visit_date, "NA", "date")}
                      </td>
                      <td className="px-2 py-3">{patient.patient_ehr_id}</td>
                      <td className="px-2 py-3 reportnamecolumn">
                        {patient.patient_name}
                      </td>
                      <td className="px-2  py-3 reportnamecolumn">
                        {parseNull(patient.provider_name)}
                      </td>
                      <td className="px-2 py-3">
                        {parseNull(patient.visit_type)}
                      </td>
                      <td className="px-2 py-3">
                        {parseNull(patient.location)}
                      </td>
                      <td className="px-2 py-3 city">
                        {parseNull(patient.city)}
                      </td>
                      <td className="px-2 py-3">{parseNull(patient.state)}</td>
                      <td className="px-2 py-3">{parseNull(patient.zip)}</td>
                      <td className="px-2 py-3">
                        {parseNull(patient.dob, "NA", "date")}
                      </td>
                      <td className="px-2 py-3">{parseNull(patient.status === 'SCHEDULED' ? 'Pending' : patient?.status == 'NOSHOW' ? 'No Show' : patient.status.charAt(0).toUpperCase() + patient.status.slice(1).toLowerCase())}</td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <tbody>
                <div
                  className="d-flex align-Items-center justify-content-center absolute w-full my-5 py-3"
                  style={{ zIndex: 0 }}
                >
                  <p className="text-xl font-bold text-primarygrayborder">
                    {" "}
                    No Records Found
                  </p>
                </div>
              </tbody>
            )}
          </table>
        ) : (
          <>
            {patientReports.length > 0 ? (
              <div className="mt-2">
                <div className="d-flex sm:flex-row flex-col">
                  <div className="relative flex mx-2 mt-1 flex-row items-center gap-2 divide-x-2 rounded-md cursor-grab outline outline-gray-200 w-56">
                    <Tooltip title="Sort">
                      <button className="pl-2">
                        <img
                          src={Icons.Sort_Icon}
                          alt="search"
                          className="w-5 h-5 cursor-pointer search-icon"
                          onClick={() => {
                            setOptionsListOpen(!optionListOpen);
                          }}
                        />
                      </button>
                    </Tooltip>
                    <div className="flex flex-row items-center w-full h-full py-2 gap-2 px-2 overflow-scroll flex-nowrap no-scrollbar">
                      {selectedOption === "" ? (
                        <p className="m-0 text-base text-primarygrayborder whitespace-nowrap">
                          Select Option...
                        </p>
                      ) : (
                        <p className="m-0 text-base  whitespace-nowrap">
                          {selectedOption?.name}
                        </p>
                      )}
                    </div>
                    {optionListOpen && (
                      <div
                        className="absolute flex flex-col w-full overflow-auto bg-white border rounded-md max-h-52 top-12"
                        ref={graphRef}
                      >
                        {[
                          { value: 1, name: "Filter By Providers" },
                          { value: 2, name: "Filter By Visit Type" },
                        ].map((item, index) => (
                          <div
                            className={classNames(
                              "hover:bg-primarylightgrey z-10 bg-white",
                              index !== 7 && "border-b"
                            )}
                          >
                            <div
                              className="px-2 py-2"
                              onClick={() => {
                                setSelectedOption(item);
                                groupByDates(selectedMonth, item);
                                groupByWeek(selectedMonth, item);
                                groupByMonth(item);
                              }}
                            >
                              {item?.name}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="d-flex flex-row gap-2 justify-end mt-2  mx-5">
                    <Tabs
                      value={viewType}
                      onChange={(e, newValue) => {
                        setViewType(newValue);
                        newValue === "Daily"
                          ? setIsDaily(true)
                          : setIsDaily(false);
                        if (newValue === "Daily") {
                          setIsDaily(true);
                          setIsMonthly(false);
                          //setProperties()
                        } else if (newValue === "Weekly") {
                          setIsDaily(false);
                          setIsMonthly(false);
                          groupByWeek(selectedMonth, selectedOption);
                        } else {
                          setIsDaily(false);
                          setIsMonthly(true);
                          groupByMonth(selectedOption);
                        }
                      }}
                      textColor="primary"
                      indicatorColor="white"
                      aria-label="secondary tabs example"
                    >
                      <Tab label="Daily" value="Daily" />
                      <Tab label="Weekly" value="Weekly" />
                      <Tab label="Monthly" value="Monthly" />
                    </Tabs>
                  </div>
                </div>
                <div className="d-flex flex-col lg:flex-row sm:min-h-fit min-h-96">
                  <div className="lg:w-11/12 w-full mt-3">
                    <ReactEcharts
                      ref={echartsRef}
                      option={getOptions()}
                      id="myGraph"
                    />
                  </div>
                  {!isMonthly ? (
                    <div className="d-flex chart-month lg:gap-0 gap-2">
                      {months.map((month, index) => {
                        const monthName = new Date(
                          Date.UTC(2000, month - 1, 1)
                        ).toLocaleString("default", { month: "long" });
                        return (
                          <div key={index} className="d-flex flex-column mt-2">
                            <div>
                              <input
                                checked={selectedMonth == month}
                                type="radio"
                                name="month"
                                value={month}
                                onChange={(e) => {
                                  setSelectedMonth(e.target.value);
                                  groupByDates(e.target.value, selectedOption);
                                  groupByWeek(e.target.value, selectedOption);
                                }}
                              />
                              &nbsp;{monthName}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              <div
                className="d-flex align-Items-center justify-content-center absolute w-full my-5 py-3"
                style={{ zIndex: 0 }}
              >
                <p className="text-xl font-bold text-primarygrayborder">
                  {" "}
                  No Records Found
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default PatientVisitReportTable;
