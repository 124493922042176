import React from "react";
import ReusableModal from "../../../components/Modals/ReusableModal";
import classNames from "classnames";
import { Icon } from "@virtuslab/tetrisly-icons";

const AppointmentConflictModal = ({
  open,
  handleSlotModal,
  handleChangeSlot,
  selected,
}) => {
  const [timeslot, setTimeslot] = React.useState(
    selected?.availableSlots?.[0] || ""
  );
  return (
    <ReusableModal open={open} hideHeader>
      <div className="flex flex-col gap-3 p-3">
        <div className="flex flex-row gap-2">
          <div
            className={classNames(
              "flex items-center justify-center w-10 h-10 rounded-full",
              "bg-primaryred"
            )}
          >
            <Icon name="20-alert" color={"#fff"} />
          </div>
          <div className="flex flex-col">
            <p className="m-0 font-semibold">Appointment Conflict</p>
            <p className="m-0 font-light">
              This appointment slot is conflicting with Athena appointments
              <br />
              please change the timeslot to publish.
            </p>
          </div>
        </div>
        <div className="h-[1px] w-full bg-primarygrayborder" />
        <div className="flex flex-row items-center justify-end gap-3">
          <label>Select timeslot: </label>
          <select
            value={timeslot}
            onChange={(e) => setTimeslot(e.target.value)}
          >
            {selected?.availableSlots.map((slot) => (
              <option key={slot} value={slot}>
                {slot}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-row items-center justify-end gap-3">
          <button
            onClick={handleSlotModal}
            className="px-4 py-2 font-medium border rounded-md bg-primarylightgrey border-primarygray"
          >
            Cancel
          </button>
          <button
            className={classNames(
              "px-4 py-2 font-medium text-white rounded-md",
              "bg-primaryred border-[1px] border-primaryred"
            )}
            onClick={async () => {
              await handleChangeSlot(timeslot);
              handleSlotModal();
            }}
          >
            Proceed
          </button>
        </div>
      </div>
    </ReusableModal>
  );
};

export default AppointmentConflictModal;
