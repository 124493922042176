import { Alert } from "@mui/material";
import DailyMileageReportFilter from "./components/DailyMileageReportFilter";
import DailyMileageReportTable from "./components/DailyMileageReportTable"
import React from "react";
import ReportHeader from "../reportHeader";
import Snackbar from "@mui/material/Snackbar";
import dayjs from "dayjs";
import { jsPDF } from "jspdf";
import moment from "moment";
import { useGetDailyMileageReportMutation } from "../../../features/report/reportApiSlice";

function index() {
    const today = dayjs();
    const [message, setMessage] = React.useState("Something went wrong");
    const [open, setOpen] = React.useState(false);
    const [totals, setTotals] = React.useState([
        { name: "Total Records Found", value: 0 },
        { name: "Total Mileage", value: "0" },
    ]);
    const [providers, setProviders] = React.useState([]);
    const [dailyMileageReports, setDailyMileageReports] = React.useState([]);
    const [filteredPv, setFilteredPv] = React.useState([]);
    const [selectedProviders, setSelectedProviders] = React.useState([]);
    const [stateNames, setStateNames] = React.useState([]);
    const [selectedStateName, setSelectedStateName] = React.useState([]);
    const [dateRangeValue, setDateRangeValue] = React.useState("THIS_MONTH");
    const [dateRange, setDateRange] = React.useState([
        today.startOf("month"),
        today,
    ]);
    const [isSelecetdValue, setIsSelectedValue] = React.useState(false);
    const [speciality, setSpeciality] = React.useState([]);
    const [selectedSpeciality, setSelectedSpeciality] = React.useState([]);
    const [filteredSpe, setFilteredSpe] = React.useState([]);
    const [isSelectAllProviders, setIsSelectAllProviders] = React.useState(true);
    const [isSelectAllSpe, setIsSelectAllSpe] = React.useState(true);
    const [isSelectAllState, setIsSelectAllState] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const DAY_MAP = {
        MONDAY: "M",
        TUESDAY: "T",
        WEDNESDAY: "W",
        THURSDAY: "Th",
        FRIDAY: "F",
        SATURDAY: "Sa",
        SUNDAY: "Su",
    };
    const [clear, setClear] = React.useState(true);
    const handleClose = () => {
        setOpen(false);
    };
    const [getDailyMileageReport] = useGetDailyMileageReportMutation();

    const getDailyMileageReports = async (filters) => {
        console.log("Filters received:", filters);
        try {
            setLoading(true);
            const from_date = filters.dateRange
                ? dayjs(filters.dateRange[0]).isValid()
                    ? dayjs(filters.dateRange[0])?.format("DD-MM-YYYY")
                    : ""
                : "";
            const to_date = filters.dateRange
                ? dayjs(filters.dateRange[1]).isValid()
                    ? dayjs(filters.dateRange[1])?.format("DD-MM-YYYY")
                    : ""
                : "";
            const dailyMileageReport = await getDailyMileageReport({
                date_range: filters.dateRangeValue
                    ? filters.dateRangeValue
                    : dateRangeValue,
                from_date: dateRangeValue ? "" : from_date,
                to_date: dateRangeValue ? "" : to_date,
                provider_id: filters.setHeaders ? "" : selectedProviders,
                state: filters.setHeaders ? "" : selectedStateName,
                provider_type: filters.setHeaders ? "" : selectedSpeciality,
            }).unwrap();

            setDailyMileageReports(
                [...dailyMileageReport.data.report_data].sort(
                    (a, b) => new Date(b.date) - new Date(a.date)
                )
            );
            setTotals([
                {
                    name: "Total Records Found",
                    value: dailyMileageReport?.data?.report_data?.length,
                },
                {
                    name: "Total Mileage",
                    // value: `${dailyMileageReport.data.total_mileage}`,
                    value: dailyMileageReport.data.total_mileage % 1 === 0
                        ? `${dailyMileageReport.data.total_mileage}`
                        : `${parseFloat(dailyMileageReport.data.total_mileage).toFixed(2)}`,
                },

            ]);
            if (filters.setHeaders === true) {
                const uniqueProviders = dailyMileageReport?.data?.report_data.reduce(
                    (acc, item) => {
                        const existingProvider = acc.find(
                            (provider) => provider.id === item.provider
                        );
                        if (!existingProvider) {
                            acc.push({ name: item.provider_name, id: item.provider });
                        }
                        return acc;
                    },
                    []
                );
                setProviders(uniqueProviders);
                setFilteredPv(uniqueProviders);
                const spe = dailyMileageReport.data.report_data.map(
                    (item) => item.provider_speciality
                );
                setSpeciality([...new Set(spe)]);
                setFilteredSpe([...new Set(spe)]);
                const states = dailyMileageReport.data.report_data.map(
                    (item) => item.provider_state
                );
                setStateNames([...new Set(states)]);
                setSelectedProviders(uniqueProviders.map(i => i.id));
                setSelectedSpeciality([...new Set(spe)]);
                setSelectedStateName([...new Set(states)]);
            }
        } catch (err) {
            setOpen(true);
            setMessage(err?.response?.data?.message);
            console.log(err, "error")
        } finally {
            setLoading(false); // Stop the spinner
        }
    };
    const handleProviderFilter = (provider, obj) => {
        setClear(false);
        if (obj.selectAll === true) {
            if (obj.isSelectAll) {
                setSelectedProviders(providers.map(i => i.id))
            } else {
                setSelectedProviders([])
            }
        } else {
            if (selectedProviders.includes(provider)) {
                setSelectedProviders(
                    selectedProviders.filter((filter) => filter !== provider)
                );
            } else {
                setSelectedProviders([...selectedProviders, provider]);
            }
        }
    };
    const handleStateNameFilter = (state, obj) => {
        setClear(false);
        if (obj.selectAll === true) {
            if (obj.isSelectAll) {
                setSelectedStateName(stateNames)
            } else {
                setSelectedStateName([])
            }
        } else {
            if (selectedStateName.includes(state)) {
                setSelectedStateName(
                    selectedStateName.filter((filter) => filter !== state)
                );
            } else {
                setSelectedStateName([...selectedStateName, state]);
            }
        }
    };
    const handleSpecialityFilter = (spe, obj) => {
        setClear(false);
        if (obj.selectAll === true) {
            if (obj.isSelectAll) {
                setSelectedSpeciality(speciality)
            } else {
                setSelectedSpeciality([])
            }
        } else {
            if (selectedSpeciality.includes(spe)) {
                setSelectedSpeciality(
                    selectedSpeciality.filter((filter) => filter !== spe)
                );
            } else {
                setSelectedSpeciality([...selectedSpeciality, spe]);
            }
        }
    };
    const exportPDF = () => {
        if (dailyMileageReports?.length > 0) {
            const doc = new jsPDF();
            doc.text("Daily Mileage Report", 20, 10);
            doc.autoTable({
                styles: { halign: "center" },
                headStyles: { fillColor: [245, 247, 249] },
                html: "#dailyMileageReport",
                theme: "plain",
                didParseCell: function (data) {
                    if (data.row.index === 0 || data.row.index % 2 === 0) {
                        data.cell.styles.fillColor = [255, 255, 255]; // White color for even rows
                    } else {
                        data.cell.styles.fillColor = [245, 247, 249]; // Light blue color for odd rows
                    }
                },
            });
            doc.setFont("helvetica", "normal");
            doc.setFontSize(13);
            doc.setTextColor(0, 0, 0);
            doc.text(
                `Total Records Found: ${totals[0].value}\nTotal Mileage: ${totals[1].value}`,
                15,
                doc.lastAutoTable.finalY + 10
            );
            doc.save("mileage-report.pdf");
            doc.save("provider-daily-mileage-report.pdf");
        } else {
            alert("No Records Found");
        }
    };
    const parseNull = (value, replacement = "NA", type = "string") => {
        return value === null || (Array.isArray(value) && value.length === 0)
            ? replacement
            : Array.isArray(value)
                ? value.map((day) => DAY_MAP[day]).join(", ")
                : type === "date"
                    ? moment(value).format("DD/MM/YYYY")
                    : value;
    };

    const exportCSV = () => {
        if (dailyMileageReports?.length > 0) {
            let csvContent =
                "date,provider_name,provider_speciality,provider_state,visits_completed,mileage\r\n";
            dailyMileageReports.forEach((provider) => {
                const row = [
                    parseNull(provider.date, "NA", "date"),
                    // parseNull(provider.provider_id),
                    parseNull(provider.provider_name),
                    parseNull(provider.provider_speciality),
                    parseNull(provider.provider_state),
                    parseNull(provider.visits_completed),
                    parseNull(provider.mileage)
                ];
                csvContent += row.join(",") + "\r\n";
            });

            // Add extra data at the bottom of the CSV
            csvContent += `\r\nTotal_Records_Found: ${totals[0].value}`;
            csvContent += `\r\nTotal Mileage: ${totals[1].value}`;


            const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
            const link = document.createElement("a");
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", "provider-daily-mileage-report.csv");
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } else {
            alert("No Records Found");
        }
    };
    const handleClear = () => {
        setSelectedStateName([]);
        setSelectedSpeciality([]);
        setSelectedProviders([]);
        // setDateRange([today.startOf("month"), today]);
        setDateRange([null, null]);
        setDateRangeValue("");
        setProviders([]);
        setFilteredPv([]);
        setSpeciality([]);
        setFilteredSpe([]);
        setStateNames([]);
        //getProviderReports({ setHeaders: true, dateRangeValue: "THIS_MONTH" });
        setClear(true);
        setDailyMileageReports([])
    };

    React.useEffect(() => {
        if (!clear) {
            getDailyMileageReports({ setHeaders: false, dateRange: dateRange });
        }
        if (
            selectedProviders?.length > 0 ||
            selectedStateName?.length > 0 ||
            selectedSpeciality?.length > 0
        ) {
            setIsSelectedValue(true);
        } else {
            setIsSelectedValue(false);
        }
    }, []);
    React.useEffect(() => {
        getDailyMileageReports({ setHeaders: true, dateRangeValue: "THIS_MONTH" });
        if (
            selectedProviders?.length > 0 ||
            selectedStateName?.length > 0 ||
            selectedSpeciality?.length > 0
        ) {
            setIsSelectedValue(true);
        } else {
            setIsSelectedValue(false);
        }
    }, []);

    const handleApplyClick = () => {
        getDailyMileageReports({ setHeaders: false, dateRange: dateRange });
    }

    return (
        <div className="relative flex flex-col w-full overflow-auto no-scrollbar standard-reports" style={{ height: "89vh" }}>
            <Snackbar
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                autoHideDuration={6000}
                message={message ? message : "Something went wrong"}
                severity="error"
            >
                <Alert
                    onClose={handleClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {message ? message : "Something went wrong"}
                </Alert>
            </Snackbar>
            <ReportHeader
                title="Provider Daily Mileage Report"
                exportCSV={exportCSV}
                exportPDF={exportPDF}
                totals={totals}
                total={dailyMileageReports.length}
            />
            <div
                className="p-2 d-flex flex-column overflow-auto align-items-center"
                style={{ height: "77vh" }}
            >
                <DailyMileageReportFilter
                    getDailyMileageReports={getDailyMileageReports}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    dateRangeValue={dateRangeValue}
                    setDateRangeValue={setDateRangeValue}
                    handleClear={handleClear}
                    setClear={setClear}
                    isSelecetdValue={isSelecetdValue}
                />
                <DailyMileageReportTable
                    providers={providers}
                    dailyMileageReports={dailyMileageReports}
                    setDailyMileageReports={setDailyMileageReports}
                    filteredPv={filteredPv}
                    setFilteredPv={setFilteredPv}
                    selectedProviders={selectedProviders}
                    handleProviderFilter={handleProviderFilter}
                    stateNames={stateNames}
                    selectedStateName={selectedStateName}
                    handleStateNameFilter={handleStateNameFilter}
                    speciality={speciality}
                    selectedSpeciality={selectedSpeciality}
                    handleSpecialityFilter={handleSpecialityFilter}
                    filteredSpe={filteredSpe}
                    setFilteredSpe={setFilteredSpe}
                    isSelecetdValue={isSelecetdValue}
                    isSelectAllProviders={isSelectAllProviders}
                    setIsSelectAllProviders={setIsSelectAllProviders}
                    isSelectAllSpe={isSelectAllSpe}
                    setIsSelectAllSpe={setIsSelectAllSpe}
                    isSelectAllState={isSelectAllState}
                    setIsSelectAllState={setIsSelectAllState}
                    handleApplyClick={handleApplyClick}
                    setLoading={setLoading}
                    loading={loading}
                />

            </div>

        </div>
    )
}
export default index;