import { apiSlice } from "../../app/api/apiSlice";

export const logsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createLogs: builder.mutation({
      query: (credentials) => ({
        url: "/logs/",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    fetchLogs: builder.mutation({
      query: () => ({
        url: `/logs/`,
        method: "GET",
      }),
    }),
  }),
});
export const { useCreateLogsMutation, useFetchLogsMutation } = logsApiSlice;
