import { apiSlice } from "../../app/api/apiSlice";
import { queryString } from "../../utilities/resuseFunctions";

export const appointmentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchAppointments: builder.mutation({
      query: (credentials) => ({
        url: "/appointment/fetch",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    filterAppointmentOptions: builder.mutation({
      query: (credentials) => ({
        url: "/appointment/filter",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    createAppointment: builder.mutation({
      query: (credentials) => ({
        url: `/appointment/create/${credentials}`,
        method: "GET",
      }),
    }),
    createNewPatientAppointment: builder.mutation({
      query: (credentials) => ({
        url: `/appointment/create/new`,
        method: "POST",
        body: { ...credentials },
      }),
    }),
    getAppointmentCount: builder.mutation({
      query: (credentials) => ({
        url: "/appointment/count",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    createOneAppointment: builder.mutation({
      query: (credentials) => ({
        url: `/appointment/create`,
        method: "POST",
        body: { ...credentials },
      }),
    }),
    cancelAppointment: builder.mutation({
      query: (credentials) => ({
        url: `/appointment/cancel`,
        method: "POST",
        body: { ...credentials },
      }),
    }),
    fetchAppointmentTypes: builder.mutation({
      query: (credentials) => ({
        url: `/appointment/types/${credentials}`,
        method: "GET",
      }),
    }),
    updateAppointmentType: builder.mutation({
      query: (credentials) => ({
        url: `/appointment/types/${credentials.appointmentTypeId}`,
        method: "PATCH",
        body: { ...credentials },
      }),
    }),
    fetchCSV: builder.mutation({
      query: (credentials) => ({
        url: `/appointment/csv?fileName=${credentials.fileName}`,
        method: "GET",
      }),
    }),
    fetchConsolidatedAppointments: builder.mutation({
      query: (credentials) => ({
        url: `/appointment/consolidate`,
        method: "POST",
        body: { ...credentials },
      }),
    }),
    fetchCareplanTemplate: builder.mutation({
      query: () => ({
        url: `/appointment/careplan-template`,
        method: "GET",
      }),
    }),
    fetchMapProviders: builder.mutation({
      query: (data) => ({
        url: `/appointment/map/?${queryString(data)}`,
        method: "GET",
      }),
    }),
    fetchProviderFilters: builder.mutation({
      query: (data) => ({
        url: `/appointment/provider/filters?${queryString(data)}`,
        method: "GET",
      }),
    }),
    rescheduleAppointment: builder.mutation({
      query: (credentials) => ({
        url: `/appointment/reschedule`,
        method: "POST",
        body: { ...credentials },
      }),
    }),
    rescheduleDispatchAppointment: builder.mutation({
      query: (credentials) => ({
        url: `/appointment/reschedule/dispatch`,
        method: "POST",
        body: { ...credentials },
      }),
    }),
    lockAppointment: builder.mutation({
      query: (credentials) => ({
        url: `/appointment/lock/${credentials.appointmentId}`,
        method: "GET",
      }),
    }),
    closestPatientAppointment: builder.mutation({
      query: (credentials) => ({
        url: `/appointment/closest?${queryString(credentials)}`,
        method: "GET",
      }),
    }),
    closestPatientAppointmentDistances: builder.mutation({
      query: (credentials) => ({
        url: `/appointment/distance?date=${credentials.date}&patientId=${credentials.patientId}&providerId=${credentials.providerId}&position=${credentials.position}`,
        method: "GET",
      }),
    }),
    getAppointmentNotes: builder.mutation({
      query: (credentials) => ({
        url: `/appointment/note/${credentials.appointmentId}`,
        method: "GET",
      }),
    }),
    createAppointmentNote: builder.mutation({
      query: (credentials) => ({
        url: `/appointment/note/${credentials.appointmentId}`,
        method: "POST",
        body: credentials,
      }),
    }),
    editAppointmentNote: builder.mutation({
      query: (credentials) => ({
        url: `/appointment/note/${credentials.noteId}`,
        method: "PATCH",
        body: credentials,
      }),
    }),
    deleteAppointmentNote: builder.mutation({
      query: ({ noteId, ...credentials }) => ({
        url: `/appointment/note/${noteId}?${queryString(credentials)}`,
        method: "DELETE",
      }),
    }),
    updateAppointmentStatus: builder.mutation({
      query: (credentials) => ({
        url: `/appointment/status/${credentials.appointmentId}?status=${credentials.status}`,
        method: "PATCH",
      }),
    }),
    updateAppointmentTimeslot: builder.mutation({
      query: (credentials) => ({
        url: `/appointment/timeslot/${credentials.appointmentId}`,
        method: "PATCH",
        body: credentials,
      }),
    }),
    fetchPatientVisitHistory: builder.mutation({
      query: ({ patientId, ...credentials }) => ({
        url: `/appointment/visit-history/${patientId}?${queryString(
          credentials
        )}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useFetchAppointmentsMutation,
  useFilterAppointmentOptionsMutation,
  useCreateAppointmentMutation,
  useGetAppointmentCountMutation,
  useCreateOneAppointmentMutation,
  useCreateNewPatientAppointmentMutation,
  useCancelAppointmentMutation,
  useFetchAppointmentTypesMutation,
  useUpdateAppointmentTypeMutation,
  useFetchCSVMutation,
  useFetchConsolidatedAppointmentsMutation,
  useFetchCareplanTemplateMutation,
  useFetchMapProvidersMutation,
  useFetchProviderFiltersMutation,
  useRescheduleAppointmentMutation,
  useLockAppointmentMutation,
  useClosestPatientAppointmentMutation,
  useClosestPatientAppointmentDistancesMutation,
  useGetAppointmentNotesMutation,
  useCreateAppointmentNoteMutation,
  useEditAppointmentNoteMutation,
  useDeleteAppointmentNoteMutation,
  useUpdateAppointmentStatusMutation,
  useUpdateAppointmentTimeslotMutation,
  useFetchPatientVisitHistoryMutation,
  useRescheduleDispatchAppointmentMutation,
} = appointmentApiSlice;
