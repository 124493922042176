import moment from "moment";
import React from "react";
import Select from "react-select";
import { Forward_Icon } from "../../assets/images";
import {
  useClosestPatientAppointmentMutation,
  useFetchAppointmentsMutation,
  useFetchProviderFiltersMutation,
} from "../../features/appointment/appointmentApiSlice";
import classNames from "classnames";
import DownArrow from "@mui/icons-material/KeyboardArrowDownRounded";
import PatientSchedulingModal from "./PatientSchedulingModal";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { Grid, Menu, MenuItem, Typography } from "@mui/material";
import { useGetProvidersMutation } from "../../features/organisation/organisationApiSlice";
import Loading from "../../components/Loading";
import InfoModal from "../../components/Modals/InfoModal";

const options = [
  { value: 1, label: "Least distance added" },
  { value: 2, label: "Least total distance" },
  { value: 3, label: "Least appointments" },
  { value: 4, label: "Smallest patient panel" },
];

const PatientSchedulingWizard = () => {
  const [getClosestPatientAppointment, { isLoading: isClosestLoading }] =
    useClosestPatientAppointmentMutation();

  const [providerAppointments, setProviderAppointments] = React.useState([]);

  const [filterOpen, setFilterOpen] = React.useState(false);

  const [dates, setDates] = React.useState([]);

  const [dateIndex, setDateIndex] = React.useState(0);

  const [startDate, setStartDate] = React.useState(null);

  const [endDate, setEndDate] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [appointmentType, setAppointmentType] = React.useState(null);
  const [createAppointment, setCreateAppointment] = React.useState(null);

  const user = useSelector(selectUser);

  const fetchProviders = async () => {
    try {
      const { data } = await getClosestPatientAppointment({
        date: moment(dates[dateIndex]).format("YYYY-MM-DD"),
        patientId: selectedPatient.value,
        providerId: selectedProvider?.value,
        appointmentType: appointmentType?.value,
        duration: appointmentType?.duration,
      });

      setProviderAppointments(data.data);
      if (selectedProvider?.value && data.data?.length > 0) {
        setProviderAppointment(providerAppointment);
        fetchAppointmentsFunction(data.data?.[0]?.provider?.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [providerAppointment, setProviderAppointment] = React.useState(null);

  const [appointments, setAppointments] = React.useState([]);

  const [fetchAppointments] = useFetchAppointmentsMutation();

  const [patients, setPatients] = React.useState([]);

  const [providers, setProviders] = React.useState([]);

  const [selectedPatient, setSelectedPatient] = React.useState(null);

  const [selectedProvider, setSelectedProvider] = React.useState(null);

  const [fetchProviderFiltersAPI, { isLoading: isProviderFiltersLoading }] =
    useFetchProviderFiltersMutation();
  const [getProviders, { isLoading: isProvidersLoading }] =
    useGetProvidersMutation();

  const isLoading =
    isProvidersLoading || isProviderFiltersLoading || isClosestLoading;

  const [appointmentTypes, setAppointmentTypes] = React.useState([]);

  const [patientType, setPatientType] = React.useState("All");
  const [patientAnchor, setPatientAnchor] = React.useState(null);
  const [filterOptions, setFilterOptions] = React.useState(null);

  const fetchProviderFilters = async () => {
    const { data } = await fetchProviderFiltersAPI({
      providerId: "0",
    }).unwrap();
    setAppointmentTypes(
      data?.appointmentTypes?.map((type) => {
        return {
          value: type.id,
          label: type.name,
          appointmentId: type.ehrId,
          duration: type.duration,
        };
      })
    );
    setFilterOptions(data);
    setPatients(data?.patientsList);
  };

  const fetchAllProviders = async () => {
    const { data } = await getProviders(user.organisation.id).unwrap();
    setProviders(data.map((x) => ({ value: x.id, label: x.name })));
  };

  const fetchAppointmentsFunction = async (providerId) => {
    try {
      const { data } = await fetchAppointments({
        providerId: providerId,
        appointmentType: "all",
        locationType: "all",
        communityId: null,
        patientId: null,
        startDate: moment(dates[dateIndex]).format("YYYY-MM-DD"),
        endDate: moment(dates[dateIndex]).format("YYYY-MM-DD"),
      });

      const appointments = Object.values(data.data)[0];

      setAppointments(appointments ?? []);
    } catch (err) {
      console.error(err);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setCreateAppointment(null);
  };
  React.useEffect(() => {
    fetchProviderFilters();
    fetchAllProviders();
  }, []);

  // React.useEffect(() => {
  //   if (filterOpen) {
  //     document.body.style.overflow = "hidden";
  //     window.scrollTo(0, 0);
  //   }

  //   return () => {
  //     document.body.style.overflow = "scroll";
  //   };
  // }, [filterOpen]);

  React.useEffect(() => {
    if (
      dates.length > 0 &&
      selectedPatient !== null &&
      appointmentType !== null
    ) {
      fetchProviders();
    } else {
      setProviderAppointments([]);
    }
  }, [dates, dateIndex, selectedPatient, selectedProvider, appointmentType]);

  React.useEffect(() => {
    if (startDate !== null && endDate !== null) {
      const start = moment(startDate);
      const end = moment(endDate);

      const dates = [];

      while (start <= end) {
        dates.push(start.format("YYYY-MM-DD"));
        start.add(1, "days");
      }

      setDates(dates);
    }
  }, [startDate, endDate]);

  const [selectedOption, setSelectedOption] = React.useState(options[0].value);

  const patientData =
    (patientType === "Recently discharged"
      ? filterOptions?.dischargedPatients
      : filterOptions?.patientsList) || [];

  const patientsList = patientData
    .filter((i) => {
      if (["All", "Recently discharged"].includes(patientType)) {
        return true;
      }

      if (i.patientType === patientType) {
        return true;
      }

      return false;
    })
    .map((patient) => ({
      value: patient.patientId,
      label: `${patient.patientName} | DOB: ${
        patient.patientDob || "NA"
      } | Ehr Id: ${patient.patientEhrId}`,
      departmentId: patient.patientDepartmentId,
      coords: patient.patientCoords,
    }));

  const resetAllandFetch = async () => {
    setProviderAppointments([]);
    setProviderAppointment(null);
    setAppointments([]);
    // setDates([]);
    // setStartDate(null);
    // setEndDate(null);
    setPatientType("All");
    setPatientAnchor(null);
    setFilterOpen(false);
    setSelectedPatient(null);
    setSelectedProvider(null);
    setAppointmentType(null);
  };

  const CustomOption = ({ innerProps, label, data, isFocused, isSelected }) => (
    <div
      {...innerProps}
      className={`custom-option ${isFocused ? "focused" : ""} ${
        isSelected ? "selected" : ""
      }`}
      key={label}
    >
      {label.split("|").map((value, i) => {
        return (
          <Typography
            key={value}
            className={`${i === 0 ? "bold-600" : "font-14"}`}
          >
            {value.split(":").length < 2 ? (
              value
            ) : (
              <Grid className="d-flex flex-row gap-1">
                {value.split(":").map((data, i) => (
                  <Typography
                    key={data}
                    className={`${i === 0 ? "bold-medium" : ""}`}
                    style={{
                      fontSize: 14,
                      color: isSelected ? "#fff" : "rgba(0,0,0,0.6)",
                    }}
                  >
                    {data}
                    {i === 0 && ":"}
                  </Typography>
                ))}
              </Grid>
            )}
          </Typography>
        );
      })}
    </div>
  );

  return (
    <div className="flex flex-col h-[calc(100vh-100px)] gap-4 mt-8">
      <Loading loading={isLoading} />
      <h1 className="px-8 m-0 text-2xl font-bold">Dispatch</h1>
      <div className="flex items-end justify-start gap-4 px-8">
        <div className="flex flex-col w-1/6 gap-2">
          <div className="flex flex-row items-center justify-between">
            <label htmlFor="patientSelect" className="text-sm font-bold">
              Select Patient
            </label>
            {/* <div>
              <div
                style={{ cursor: "pointer" }}
                onClick={(event) => {
                  setPatientAnchor(event.currentTarget);
                }}
                className="flex-row gap-1 d-flex align-items-center justify-content-center"
              >
                <span className="text-xs font-bold color-1">{patientType}</span>
                <DownArrow className="color-1" />
              </div>
              <Menu
                open={Boolean(patientAnchor)}
                anchorEl={patientAnchor}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                onClose={() => {
                  setPatientAnchor(null);
                }}
              >
                {["All", "Overdue", "New", "Recently discharged"].map(
                  (data, ind) => (
                    <MenuItem
                      key={ind}
                      style={{
                        width: "200px",
                        fontSize: 14,
                      }}
                      onClick={() => {
                        setPatientType(data);
                        setPatientAnchor(null);
                      }}
                    >
                      {data}
                    </MenuItem>
                  )
                )}
              </Menu>
            </div> */}
          </div>
          <div className="w-full">
            <Select
              options={patientsList}
              isSearchable
              isClearable
              value={selectedPatient}
              onChange={(selectedOption) => {
                setSelectedPatient(selectedOption);
              }}
              components={{ Option: CustomOption }} // Use the custom option component
            />
          </div>
        </div>
        <div className="flex flex-col w-1/6 gap-2">
          <label htmlFor="patientSelect" className="text-sm font-bold">
            Select Provider (optional)
          </label>
          <div className="w-full">
            <Select
              options={providers}
              isSearchable
              isClearable
              value={selectedProvider}
              onChange={(selectedOption) => {
                setSelectedProvider(selectedOption);
              }}
            />
          </div>
        </div>
        <div className="relative flex flex-col w-1/6 gap-2">
          <div className="flex justify-between">
            <label className="text-sm font-bold">Start date</label>
            <button
              onClick={() => {
                setStartDate(moment().format("YYYY-MM-DD"));
              }}
            >
              <p className="m-0 text-sm font-medium text-primaryblue">
                Set current date
              </p>
            </button>
          </div>
          <input
            type="date"
            value={startDate}
            placeholder="dd/mm/yyyy"
            min={moment().format("YYYY-MM-DD")}
            onChange={(e) => setStartDate(e.target.value)}
            className="w-full h-[38px] p-2 border rounded-md border-primarygrayborder"
          />
        </div>
        <div className="relative flex flex-col w-1/6 gap-2">
          <label htmlFor="endDate" className="text-sm font-bold">
            End Date
          </label>
          <input
            type="date"
            value={endDate}
            placeholder="dd/mm/yyyy"
            min={moment().format("YYYY-MM-DD")}
            onChange={(e) => setEndDate(e.target.value)}
            className="w-full h-[38px] p-2 border rounded-md border-primarygrayborder"
          />
        </div>
        <div className="flex flex-col w-1/6 gap-2">
          <label htmlFor="appoitmentType" className="text-sm font-bold">
            Appointment Type
          </label>
          <div className="w-full">
            <Select
              options={appointmentTypes}
              isSearchable
              isClearable
              value={appointmentType}
              onChange={(selectedOption) => {
                setAppointmentType(selectedOption);
              }}
            />
          </div>
        </div>
      </div>
      <div className="w-full h-[1px] bg-gray-200" />
      {dates.length > 0 ? (
        selectedPatient !== null &&
        appointmentType !== null && (
          <div className="flex w-100">
            <div className="flex flex-col w-1/2 gap-8 px-8">
              <div className="flex flex-row items-end gap-5">
                <div className="flex items-center w-2/3 gap-8">
                  <button
                    disabled={dateIndex === 0}
                    onClick={() => {
                      setDateIndex(dateIndex - 1);
                    }}
                    className="relative flex items-center justify-center w-10 rounded-md group aspect-square bg-primarylightgrey disabled:opacity-40"
                  >
                    <img
                      src={Forward_Icon}
                      alt="Filter"
                      className="object-contain w-5 h-5 rotate-180"
                    />
                    <p className="absolute px-2 py-1 text-xs transition-all duration-150 rounded-md opacity-[0%] -z-40 whitespace-nowrap -bottom-14 group-hover:z-50 group-hover:opacity-100 bg-primarylightgrey border-[0.5px] border-primarygrayborder shadow-sm">
                      Previous Day
                    </p>
                  </button>
                  <p className="m-0 text-xl font-bold">
                    {moment(dates[dateIndex]).format("dddd MMMM Do, YYYY")}
                  </p>
                  <button
                    onClick={() => {
                      setDateIndex(dateIndex + 1);
                    }}
                    disabled={dateIndex === dates.length - 1}
                    className="relative flex items-center justify-center w-10 rounded-md group aspect-square bg-primarylightgrey disabled:opacity-40"
                  >
                    <img
                      src={Forward_Icon}
                      alt="Filter"
                      className="object-contain w-5 h-5"
                    />
                    <p className="absolute px-2 py-1 text-xs transition-all duration-150 rounded-md opacity-[0%] -z-40 whitespace-nowrap -bottom-14 group-hover:z-50 group-hover:opacity-100 bg-primarylightgrey border-[0.5px] border-primarygrayborder shadow-sm">
                      Next Day
                    </p>
                  </button>
                </div>
                <div className="flex flex-col w-1/3 gap-1">
                  <label htmlFor="patientSelect" className="text-sm font-bold">
                    Sort By:
                  </label>
                  <div className="w-full">
                    <Select
                      options={options}
                      onChange={(selectedOption) => {
                        if (selectedOption.value === 1) {
                          setProviderAppointments(
                            [...providerAppointments].sort(
                              (a, b) => a.difference - b.difference
                            )
                          );
                        } else if (selectedOption.value === 2) {
                          setProviderAppointments(
                            [...providerAppointments].sort(
                              (a, b) => a.distance - b.distance
                            )
                          );
                        } else if (selectedOption.value === 3) {
                          setProviderAppointments(
                            [...providerAppointments].sort(
                              (a, b) => a.appointments - b.appointments
                            )
                          );
                        } else {
                          setProviderAppointments(
                            [...providerAppointments].sort(
                              (a, b) =>
                                a.provider.panelSize - b.provider.panelSize
                            )
                          );
                        }

                        setSelectedOption(selectedOption);
                      }}
                      defaultValue={options[0]}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-4 py-4 pr-8  h-[calc(100vh-380px)] overflow-y-auto">
                {providerAppointments.map((providerAppt, index) => (
                  <div
                    className="flex flex-row items-start justify-start gap-4"
                    key={index}
                  >
                    <div
                      className={classNames(
                        "flex items-center justify-center h-12 rounded-full min-w-12",
                        index === 0
                          ? "bg-primarygreen text-white"
                          : "bg-gray-200 text-black"
                      )}
                    >
                      <p
                        className={classNames(
                          "m-0 text-lg font-bold",
                          index === 0 ? "text-white" : "text-black"
                        )}
                      >
                        {index + 1}
                      </p>
                    </div>
                    <div
                      className={classNames(
                        "flex flex-col justify-between w-full bg-white rounded-md",
                        providerAppt.provider.id ===
                          providerAppointment?.provider.id
                          ? "border-3 border-primaryblue"
                          : "border border-gray-200"
                      )}
                    >
                      <div className="flex items-center justify-between p-4">
                        <div className="flex items-center gap-4">
                          <div className="flex flex-col">
                            <p className="m-0 text-xl font-bold">
                              {providerAppt.provider.name}
                            </p>
                            <p className="m-0 text-gray-400">
                              {providerAppt.provider.speciality},{" "}
                              {providerAppt.provider.panelSize} Patients
                            </p>
                          </div>
                        </div>
                        <button
                          onClick={() => {
                            setFilterOpen(true);
                            setProviderAppointment(providerAppt);
                            fetchAppointmentsFunction(providerAppt.provider.id);
                          }}
                          className="relative flex items-center justify-center w-12 h-12 rounded-md group aspect-square bg-primarylightgrey disabled:opacity-40"
                        >
                          <img
                            src={Forward_Icon}
                            alt="Filter"
                            className="object-contain w-5 h-5"
                          />
                          <p className="absolute px-2 py-1 text-xs transition-all duration-150 rounded-md opacity-[0%] -z-40 whitespace-nowrap -bottom-14 group-hover:z-50 group-hover:opacity-100 bg-primarylightgrey border-[0.5px] border-primarygrayborder shadow-sm">
                            Show Schedule
                          </p>
                        </button>
                      </div>
                      <div className="w-full h-[1px] bg-gray-200" />
                      <div className="grid grid-cols-2 gap-4 p-4">
                        <p className="m-0">
                          <span className="font-bold">
                            No. of scheduled visits:
                          </span>{" "}
                          {providerAppt.appointments}
                        </p>
                        <p className="m-0">
                          <span className="font-bold">
                            Total route distance:
                          </span>{" "}
                          {providerAppt.distance} miles
                        </p>
                        <p className="m-0">
                          <span className="font-bold">Proposed sequence:</span>{" "}
                          {providerAppt.position} of{" "}
                          {providerAppt.appointments + 1}
                        </p>
                        <p className="m-0">
                          <span className="font-bold">Distance added:</span> +
                          {providerAppt.difference}
                          miles
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="h-full w-[1px] bg-gray-200" />
            <PatientSchedulingModal
              filterOpen={filterOpen}
              providers={providerAppointments}
              providerAppointment={providerAppointment}
              setFilterOpen={setFilterOpen}
              setProviderAppointment={setProviderAppointment}
              appointments={appointments}
              setAppointments={setAppointments}
              dates={dates}
              dateIndex={dateIndex}
              selectedPatient={selectedPatient}
              departmentId={
                patients?.find(
                  (patient) => patient.patientId === selectedPatient?.value
                )?.patientDepartmentId
              }
              appointmentType={appointmentType}
              setOpen={setOpen}
              resetAllandFetch={resetAllandFetch}
              createAppointment={createAppointment}
              setCreateAppointment={setCreateAppointment}
            />
          </div>
        )
      ) : (
        <div className="flex items-center justify-center h-[calc(100vh-320px)]">
          <p>
            Select a patient first to automatically optimize visit scheduling
          </p>
        </div>
      )}
      {open && (
        <InfoModal
          open={open}
          handleClose={handleClose}
          title={"Success"}
          subtext={
            <p>
              You've successfully added the following visit for{" "}
              <b>{createAppointment?.patient?.name}</b> to{" "}
              <b>{createAppointment?.provider?.name}</b>'s schedule on{" "}
              <b>{createAppointment?.visitDate}</b>
            </p>
          }
          type={"success"}
        />
      )}
    </div>
  );
};

export default PatientSchedulingWizard;
