import classNames from "classnames";
import React, { useEffect } from "react";
import { useFetchPatientVisitHistoryMutation } from "../../../../features/appointment/appointmentApiSlice";
import moment from "moment";
import Loading from "../../../../components/Loading";

const VisitHistory = ({ patientId }) => {
  const [startDate, setStartDate] = React.useState(
    moment().subtract(1, "months").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [history, setHistory] = React.useState([]);

  const handleStartDateChange = (e) => setStartDate(e.target.value);
  const handleEndDateChange = (e) => setEndDate(e.target.value);

  const [fetchPatientVisitHistory, { isLoading }] =
    useFetchPatientVisitHistoryMutation();

  const getPatientVisitHistory = async () => {
    try {
      const { data } = await fetchPatientVisitHistory({
        startDate: startDate,
        endDate: endDate,
        patientId: patientId,
      }).unwrap();
      setHistory(data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getPatientVisitHistory();
  }, [startDate, endDate]);

  return (
    <>
      <Loading loading={isLoading} />
      <div className="flex flex-row items-center justify-between w-full pl-8 pr-16">
        <div className="flex flex-row items-center w-full gap-12">
          <div className="flex flex-row items-center w-full gap-8 max-w-7xl">
            <div className="flex flex-col gap-2">
              <label className="font-semibold">Start Date</label>
              <input
                type="date"
                name="startDate"
                className="border p-2 rounded-md"
                value={startDate}
                onChange={handleStartDateChange}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label className="font-semibold">End Date</label>
              <input
                type="date"
                name="endDate"
                className="border p-2 rounded-md"
                value={endDate}
                onChange={handleEndDateChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pl-8 pr-16">
        <div
          className={classNames(
            "relative w-full mt-8 mb-2 overflow-auto  bg-white rounded-lg shadow  max-h-[calc(100vh-200px)]"
          )}
        >
          <table className="relative w-full min-h-28 whitespace-no-wrap bg-white border-collapse table-auto table-striped">
            <thead>
              <tr className="h-16 text-base font-bold text-left text-white bg-primaryblue whitespace-nowrap">
                <th className="px-4 py-3">
                  <p className="m-0">Visit Date</p>
                </th>
                <th className="px-4 py-3">
                  <p className="m-0">Timeslot</p>
                </th>
                <th className="px-4 py-3">
                  <p className="m-0">Provider Name</p>
                </th>
                <th className="px-4 py-3">
                  <p className="m-0">Visit Type</p>
                </th>
                <th className="px-4 py-3">
                  <p className="m-0">Status</p>
                </th>
                <th className="px-4 py-3">
                  <p className="m-0">Visit Status</p>
                </th>
              </tr>
            </thead>
            <tbody>
              {history?.map((data, index) => (
                <tr
                  className={classNames(
                    "text-left text-gray-900 h-24 whitespace-nowrap"
                  )}
                  key={index}
                >
                  <td className="px-4 py-3 text-left text-gray-700">
                    {data.visitDate}
                  </td>
                  <td className="px-4 py-3 text-left text-gray-700">
                    {data.timeslot}
                  </td>
                  <td className="px-4 py-3 text-left text-gray-700">
                    {data?.provider?.name}
                  </td>
                  <td className="px-4 py-3 text-left text-gray-700">
                    {data.appointmentType?.name}
                  </td>
                  <td className="px-4 py-3 text-left text-gray-700">
                    {data.status}
                  </td>
                  <td className="px-4 py-3 text-left text-gray-700">
                    {data.visitStatus}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default VisitHistory;
