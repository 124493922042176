import React, { useRef, useState } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CheckboxSelector from "../../../Schedules/components/CheckboxSelector";
import CircularProgress from '@mui/material/CircularProgress';
import { Icons } from "../../../../assets";
import SearchIcon from "@mui/icons-material/Search";
import Stack from '@mui/material/Stack';
import { Tooltip } from "@mui/material";
import classNames from "classnames";
import moment from "moment";

function DailyMileageReportTable({
    setDailyMileageReports,
    dailyMileageReports,
    providers,
    filteredPv,
    setFilteredPv,
    selectedProviders,
    handleProviderFilter,
    stateNames,
    selectedStateName,
    handleStateNameFilter,
    speciality,
    selectedSpeciality,
    handleSpecialityFilter,
    filteredSpe,
    setFilteredSpe,
    isSelecetdValue,
    isSelectAllProviders,
    setIsSelectAllProviders,
    isSelectAllSpe,
    setIsSelectAllSpe,
    isSelectAllState,
    setIsSelectAllState,
    handleApplyClick,
    loading,
    setLoading
}) {
    const dropdownRef = useRef(null);
    const dropdownRefState = useRef(null);
    const dropdownRefSpe = useRef(null);
    const dropdownStatusRef = React.useRef(null);

    const [providerListOpen, setProviderListOpen] = useState(false);
    const [stateListOpen, setStateListOpen] = useState(false);
    const [speListOpen, setSpeListOpen] = useState(false);
    const [isApptDtAse, setIsAppDtAse] = React.useState(true);
    const [isDisAse, setIsDisAse] = React.useState(true);
    const [providerNameSearch, setProviderNameSearch] = useState("");
    const [speNameSearch, setSpeNameSearch] = useState("");
    const handleProviderListOpen = () => {
        setProviderListOpen(!providerListOpen);
        setStateListOpen(false);
        setSpeListOpen(false);
        if (providerListOpen) {
            setProviderNameSearch("");
            setFilteredPv(providers);
        }
        setSpeNameSearch("");
        setFilteredSpe(speciality);
    };
    const handleStateListOpen = () => {
        setStateListOpen(!stateListOpen);
        setProviderListOpen(false);
        setSpeListOpen(false);
        setProviderNameSearch("");
        setFilteredPv(providers);
        setSpeNameSearch("");
        setFilteredSpe(speciality);
    };
    const handleSpeListOpen = () => {
        setSpeListOpen(!speListOpen);
        setProviderListOpen(false);
        setStateListOpen(false);
        setProviderNameSearch("");
        setFilteredPv(providers);
        if (speListOpen) {
            setSpeNameSearch("");
            setFilteredSpe(speciality);
        }
    };
    const handleSelectAllProviders = () => {
        handleProviderFilter(-1, {
            selectAll: true,
            isSelectAll: !isSelectAllProviders,
        });
        setIsSelectAllProviders(!isSelectAllProviders);

    };
    const handleSelectAllSpe = () => {
        handleSpecialityFilter(-1, {
            selectAll: true,
            isSelectAll: !isSelectAllSpe,
        });
        setIsSelectAllSpe(!isSelectAllSpe);
    };
    const handleSelectAllStates = () => {
        handleStateNameFilter(-1, {
            selectAll: true,
            isSelectAll: !isSelectAllState,
        });
        setIsSelectAllState(!isSelectAllState);
    };
    const TABLE_HEADERS = [
        "Date",
        // "Provider Id",
        "Provider Name",
        "Provider Speciality",
        "Provider State",
        "Visits completed",
        "Mileage"
    ];
    const DAY_MAP = {
        MONDAY: "M",
        TUESDAY: "T",
        WEDNESDAY: "W",
        THURSDAY: "Th",
        FRIDAY: "F",
        SATURDAY: "Sa",
        SUNDAY: "Su",
    };
    const parseNull = (value, replacement = "NA", type = "string") => {
        return value === null || (Array.isArray(value) && value.length === 0)
            ? replacement
            : Array.isArray(value)
                ? value.map((day) => DAY_MAP[day]).join(",")
                : type === "date"
                    ? moment(value).format("MM/DD/YYYY")
                    : value;
    };
    const handleClickOutside = (event) => {
        if (
            dropdownRefSpe.current &&
            !dropdownRefSpe.current.contains(event.target)
        ) {
            setSpeListOpen(false);
            setFilteredSpe(speciality);
            setSpeNameSearch("");
        }

        if (
            dropdownRefState.current &&
            !dropdownRefState.current.contains(event.target)
        ) {
            setStateListOpen(false);
        }
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setProviderListOpen(false);
            setProviderNameSearch("");
            setFilteredPv(providers);
        }
    };
    React.useEffect(() => {
        if (speListOpen || stateListOpen || providerListOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [speListOpen, stateListOpen, providerListOpen]);

    React.useEffect(() => {
        setIsAppDtAse(true);
        setIsDisAse(true);
        selectedProviders.length === providers.length
            ? setIsSelectAllProviders(true)
            : setIsSelectAllProviders(false);
        selectedSpeciality.length === speciality.length
            ? setIsSelectAllSpe(true)
            : setIsSelectAllSpe(false);
        selectedStateName.length === stateNames.length
            ? setIsSelectAllState(true)
            : setIsSelectAllState(false);

    }, [selectedProviders, selectedSpeciality, selectedStateName]);

    return (
        <>
            <div className="h-full overflow-auto mt-2 w-full">
                <table
                    className="w-full"
                    style={{ whiteSpace: "nowrap" }}
                    id="dailyMileageReport"
                >
                    <thead>
                        <tr className="bg-primarylightgrey">
                            {TABLE_HEADERS.map((header, index) => {
                                if (header === "Provider Name") {
                                    return (
                                        <th
                                            key={index}
                                            className={
                                                "px-2 py-3 text-base relative min-w-56 " +
                                                (selectedProviders.length > 0 || providerListOpen
                                                    ? "bg-highlight"
                                                    : "")
                                            }
                                            ref={dropdownRef}
                                        >
                                            <div className="flex flex-row  justify-start items-center">
                                                <p className="m-0">
                                                    {header}
                                                    <span className="dropdown-icon ml-3"></span>
                                                </p>
                                                <Tooltip title="Sort">
                                                    <button
                                                        className="pr-2"
                                                        onClick={handleProviderListOpen}
                                                    >
                                                        <img
                                                            src={Icons.Sort_Icon}
                                                            alt="search"
                                                            className="w-5 h-5 cursor-pointer search-icon"
                                                        />
                                                    </button>
                                                </Tooltip>{" "}
                                                {providerListOpen && (
                                                    <div className="absolute z-50 flex flex-col w-full overflow-auto no-scrollbar bg-white border  max-h-60 top-16">
                                                        <div className="d-flex align-center sticky top-0 bg-white">
                                                            <input
                                                                type="search"
                                                                value={providerNameSearch}
                                                                onChange={(e) => {
                                                                    setProviderNameSearch(e.target.value);
                                                                    setFilteredPv(
                                                                        providers.filter((provider) => {
                                                                            return provider.name
                                                                                .toLowerCase()
                                                                                .includes(e.target.value.toLowerCase());
                                                                        })
                                                                    );
                                                                }}
                                                                className="search-input"
                                                                autoFocus
                                                                placeholder="Search"
                                                            />
                                                            {providerNameSearch === "" ? (
                                                                <SearchIcon className="search-icon-input" />
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        {filteredPv.length > 0 ? (
                                                            <>
                                                                <th
                                                                    className={classNames(
                                                                        "px-4 py-3 cursor-pointer text-sm"
                                                                    )}
                                                                >
                                                                    <label className="flex flex-row items-center gap-2 cursor-pointer">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="w-4 h-4 checked:lightblue"
                                                                            checked={
                                                                                isSelectAllProviders ||
                                                                                selectedProviders.length ===
                                                                                providers.length
                                                                            }
                                                                            onChange={(e) => {
                                                                                handleSelectAllProviders();
                                                                            }}
                                                                        />
                                                                        <p
                                                                            className={classNames(
                                                                                "m-0 cursor-pointer text-sm font-bold"
                                                                            )}
                                                                        >
                                                                            Select All
                                                                        </p>
                                                                    </label>
                                                                </th>
                                                                {filteredPv.map((provider, index) => (
                                                                    <div
                                                                        className={classNames(
                                                                            "hover:bg-primarylightgrey",
                                                                            providers.length - 1 && "border-b"
                                                                        )}
                                                                    >
                                                                        <CheckboxSelector
                                                                            header={provider.name}
                                                                            isChecked={
                                                                                selectedProviders.includes(provider.id)
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            handleChange={(_e) =>
                                                                                handleProviderFilter(provider.id, {
                                                                                    selectAll: false,
                                                                                    isSelectAll: !isSelectAllProviders,
                                                                                })
                                                                            }
                                                                            isBold={false}
                                                                        />
                                                                    </div>
                                                                ))}
                                                                <div className="sticky bottom-0 bg-white flex justify-end p-2 ">
                                                                    <button
                                                                        className="self-end px-3 py-1 text-sm bg-primaryblue text-white rounded"
                                                                        onClick={() => { handleApplyClick() }}
                                                                    >
                                                                        Apply
                                                                    </button>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <div className="no-record-found">
                                                                No Providers Found
                                                            </div>
                                                        )}

                                                    </div>

                                                )}
                                            </div>
                                        </th>
                                    );
                                } else if (header === "Provider State") {
                                    return (
                                        <th
                                            key={index}
                                            className={
                                                "px-2 py-3 text-base min-w-40 relative " +
                                                (selectedStateName.length > 0 || stateListOpen
                                                    ? "bg-highlight"
                                                    : "")
                                            }
                                            ref={dropdownRefState}
                                        >
                                            <div className="flex flex-row items-center justify-center">
                                                <p className="m-0">
                                                    {header}
                                                    <span className="dropdown-icon ml-3"></span>
                                                </p>
                                                <Tooltip title="Sort">
                                                    <button
                                                        className="pr-2"
                                                        onClick={handleStateListOpen}
                                                    >
                                                        <img
                                                            src={Icons.Sort_Icon}
                                                            alt="search"
                                                            className="w-5 h-5 cursor-pointer search-icon"
                                                        />
                                                    </button>
                                                </Tooltip>
                                                {stateListOpen && (
                                                    <div className="absolute z-50 flex flex-col w-full no-scrollbar overflow-auto bg-white border  max-h-60 top-16">
                                                        {stateNames.length > 0 ? (
                                                            <>
                                                                <th
                                                                    className={classNames(
                                                                        "px-4 py-3 cursor-pointer text-sm"
                                                                    )}
                                                                >
                                                                    <label className="flex flex-row items-center gap-2 cursor-pointer">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="w-4 h-4 checked:lightblue"
                                                                            checked={
                                                                                isSelectAllState ||
                                                                                selectedStateName.length ===
                                                                                stateNames.length
                                                                            }
                                                                            onChange={(e) => {
                                                                                handleSelectAllStates();
                                                                            }}
                                                                        />
                                                                        <p
                                                                            className={classNames(
                                                                                "m-0 cursor-pointer text-sm font-bold"
                                                                            )}
                                                                        >
                                                                            Select All
                                                                        </p>
                                                                    </label>
                                                                </th>
                                                                {stateNames.map((stateName, index) => (
                                                                    <div
                                                                        className={classNames(
                                                                            "hover:bg-primarylightgrey",
                                                                            stateName.length - 1 && "border-b"
                                                                        )}
                                                                    >
                                                                        <CheckboxSelector
                                                                            header={stateName}
                                                                            isChecked={
                                                                                selectedStateName.includes(stateName)
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            handleChange={(_e) =>
                                                                                handleStateNameFilter(stateName, {
                                                                                    selectAll: false,
                                                                                    isSelectAll: !isSelectAllState,
                                                                                })
                                                                            }
                                                                            isBold={false}
                                                                        />
                                                                    </div>
                                                                ))}
                                                                <div className="sticky bottom-0 bg-white flex justify-end p-2 ">
                                                                    <button
                                                                        className="self-end px-3 py-1 text-sm bg-primaryblue text-white rounded"
                                                                        onClick={() => { handleApplyClick() }}
                                                                    >
                                                                        Apply
                                                                    </button>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <div className="no-record-found">
                                                                No States Found
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </th>
                                    );
                                } else if (header === "Provider Speciality") {
                                    return (
                                        <th
                                            key={index}
                                            className={
                                                "px-2 py-3 text-base relative min-w-56 " +
                                                (selectedSpeciality.length > 0 || speListOpen
                                                    ? "bg-highlight"
                                                    : "")
                                            }
                                            ref={dropdownRefSpe}
                                        >
                                            <div className="flex flex-row justify-center items-center">
                                                <p className="m-0">
                                                    {header}
                                                    <span className="dropdown-icon ml-3"></span>
                                                </p>
                                                <Tooltip title="Sort">
                                                    <button className="pr-2" onClick={handleSpeListOpen}>
                                                        <img
                                                            src={Icons.Sort_Icon}
                                                            alt="search"
                                                            className="w-5 h-5 cursor-pointer search-icon"
                                                        />
                                                    </button>
                                                </Tooltip>{" "}
                                                {speListOpen && (
                                                    <div className="absolute z-50 flex flex-col w-full overflow-auto no-scrollbar bg-white border  max-h-60 top-16">
                                                        <div className="d-flex align-center sticky top-0 bg-white">
                                                            <input
                                                                type="search"
                                                                value={speNameSearch}
                                                                onChange={(e) => {
                                                                    setSpeNameSearch(e.target.value);
                                                                    setFilteredSpe(
                                                                        speciality.filter((spe) => {
                                                                            return spe
                                                                                .toLowerCase()
                                                                                .includes(e.target.value.toLowerCase());
                                                                        })
                                                                    );
                                                                }}
                                                                className="search-input"
                                                                autoFocus
                                                                placeholder="Search"
                                                            />
                                                            {speNameSearch === "" ? (
                                                                <SearchIcon className="search-icon-input" />
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        {filteredSpe.length > 0 ? (
                                                            <>
                                                                <th
                                                                    className={classNames(
                                                                        "px-4 py-3 cursor-pointer text-sm"
                                                                    )}
                                                                >
                                                                    <label className="flex flex-row items-center gap-2 cursor-pointer">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="w-4 h-4 checked:lightblue"
                                                                            checked={
                                                                                isSelectAllSpe ||
                                                                                selectedSpeciality.length ===
                                                                                speciality.length
                                                                            }
                                                                            onChange={(e) => {
                                                                                handleSelectAllSpe();
                                                                            }}
                                                                        />
                                                                        <p
                                                                            className={classNames(
                                                                                "m-0 cursor-pointer text-sm font-bold"
                                                                            )}
                                                                        >
                                                                            Select All
                                                                        </p>
                                                                    </label>
                                                                </th>
                                                                {filteredSpe.map((spe, index) => (
                                                                    <div
                                                                        className={classNames(
                                                                            "hover:bg-primarylightgrey",
                                                                            speciality.length - 1 && "border-b"
                                                                        )}
                                                                    >
                                                                        <CheckboxSelector
                                                                            header={spe}
                                                                            isChecked={
                                                                                selectedSpeciality.includes(spe)
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            handleChange={(_e) =>
                                                                                handleSpecialityFilter(spe, {
                                                                                    selectAll: false,
                                                                                    isSelectAll: !isSelectAllSpe,
                                                                                })
                                                                            }
                                                                            isBold={false}
                                                                        />
                                                                    </div>
                                                                ))}
                                                                <div className="sticky bottom-0 bg-white flex justify-end p-2 ">
                                                                    <button
                                                                        className="self-end px-3 py-1 text-sm bg-primaryblue text-white rounded"
                                                                        onClick={() => { handleApplyClick() }}
                                                                    >
                                                                        Apply
                                                                    </button>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <div className="no-record-found">
                                                                No Specialities Found
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </th>
                                    );
                                } else if (header === "Date") {
                                    return (
                                        <th
                                            key={index}
                                            className="px-2 py-3 justify-center text-base min-w-40 d-flex gap-30"
                                        >
                                            <div className="d-flex flex-row items-center justify-center relative">
                                                <div className="d-flex align-items-center w-full justify-center">
                                                    <p className="m-0">{header}</p>
                                                    {isApptDtAse ? (
                                                        <Tooltip title="Sort Descending">
                                                            <ArrowDropUpIcon
                                                                style={{
                                                                    fontSize: 30,
                                                                    color: "#212529",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() => {
                                                                    setIsAppDtAse(false);
                                                                    const result = [...dailyMileageReports].sort(
                                                                        (a, b) =>
                                                                            new Date(a.date) -
                                                                            new Date(b.date)
                                                                    );
                                                                    setDailyMileageReports(result);
                                                                }}
                                                                value="asc"
                                                                className="arrow-up"
                                                            />
                                                        </Tooltip>
                                                    ) : (
                                                        <Tooltip title="Sort Ascending">
                                                            <ArrowDropDownIcon
                                                                style={{
                                                                    fontSize: 30,
                                                                    color: "#212529",
                                                                    cursor: "pointer",
                                                                }}
                                                                value="des"
                                                                className="arrow-down"
                                                                onClick={() => {
                                                                    setIsAppDtAse(true);
                                                                    const result = [...dailyMileageReports].sort(
                                                                        (a, b) =>
                                                                            new Date(b.date) -
                                                                            new Date(a.date)
                                                                    );
                                                                    setDailyMileageReports(result);
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            </div>
                                        </th>
                                    );
                                } else {
                                    return (
                                        <th key={index} className="px-2 py-3 text-base">
                                            <div className="flex flex-row justify-center items-center">
                                                <p className="m-0">{header}</p>
                                            </div>
                                        </th>
                                    );
                                }
                            })}
                        </tr>
                    </thead>
                    {loading ? (
                        <div className="flex justify-center items-center absolute top-1/2 left-1/2">
                            <Stack >
                                <CircularProgress size={40} disableShrink />
                            </Stack>
                        </div>
                    ) :
                        dailyMileageReports.length > 0 ? (
                            <tbody>
                                {dailyMileageReports.map((provider, index) => {
                                    const isEven = (index + 1) % 2 === 0;
                                    return (
                                        <tr
                                            className={`text-base border-bottom text-left text-gray-900 border-bottom ${isEven ? "bg-primarylightgrey" : ""
                                                }`}
                                        >
                                            <td className="px-2 py-3">
                                                {parseNull(provider.date, "NA", "date")}
                                            </td>
                                            {/* <td className="px-2 py-3">
                                            {parseNull(provider.provider_id)}
                                        </td> */}
                                            <td className="px-2 py-3 reportnamecolumn">
                                                {parseNull(provider.provider_name)}
                                            </td>
                                            <td className="px-2 py-3">
                                                {parseNull(provider.provider_speciality)}
                                            </td>
                                            <td className="px-2 py-3">
                                                {parseNull(provider.provider_state)}
                                            </td>
                                            <td className="px-2 py-3 align-items-center">
                                                {parseNull(provider.visits_completed)}
                                            </td>
                                            {/* <td className="px-2 py-3">
                                            {parseNull(provider.mileage)}
                                        </td> */}
                                            <td className="px-2 py-3">
                                                {parseNull(provider.mileage) && !isNaN(parseNull(provider.mileage)) ? parseFloat(parseNull(provider.mileage)).toFixed(2) : parseNull(provider.mileage)}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        ) : (
                            <tbody>
                                <div
                                    className="d-flex align-items-center justify-content-center absolute w-full my-5 py-3"
                                    style={{ zIndex: 0 }}
                                >
                                    <p className="text-xl font-bold text-primarygrayborder">
                                        {" "}
                                        No Records Found
                                    </p>
                                </div>
                            </tbody>
                        )
                    }
                </table>
            </div>
        </>
    )
}

export default DailyMileageReportTable;