import "jspdf-autotable";

import { Alert } from "@mui/material";
import ProviderActivityReportFilter from "./components/providerActivityReportFilter";
import ProviderActivityReportTable from "./components/providerActivityReportTable";
import React from "react";
import ReportHeader from "../reportHeader";
import Snackbar from "@mui/material/Snackbar";
import axios from "axios";
import dayjs from "dayjs";
import { jsPDF } from "jspdf";
import moment from "moment";
import { useGetProviderActivityReportMutation } from "../../../features/report/reportApiSlice";

function index() {
  const today = dayjs();
  //const today = dayjs('2024-07-30');
  const [providers, setProviders] = React.useState([]);
  const [providerReports, setProviderReports] = React.useState([]);
  const [filteredPv, setFilteredPv] = React.useState([]);
  const [selectedProviders, setSelectedProviders] = React.useState([]);
  const [stateNames, setStateNames] = React.useState([]);
  const [selectedStateName, setSelectedStateName] = React.useState([]);
  const [status, setStatus] = React.useState([]);
  const [dateRange, setDateRange] = React.useState([
    today.startOf("month"),
    today,
  ]);
  const [dateRangeValue, setDateRangeValue] = React.useState("THIS_MONTH");
  const [isSelecetdValue, setIsSelectedValue] = React.useState(false);
  const [speciality, setSpeciality] = React.useState([]);
  const [selectedSpeciality, setSelectedSpeciality] = React.useState([]);
  const [filteredSpe, setFilteredSpe] = React.useState([]);
  const [totals, setTotals] = React.useState([
    { name: "Total Records Found", value: 0 },
    { name: "Total Distance Travelled", value: "0 miles" },
  ]);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("Something went wrong");
  const [isSelectAllProviders, setIsSelectAllProviders] = React.useState(true);
  const [isSelectAllSpe, setIsSelectAllSpe] = React.useState(true);
  const [isSelectAllState, setIsSelectAllState] = React.useState(true);
  const [selectedStatus, setSelectedStatus] = React.useState([]);
  const [isSelectAllStatus, setIsSelectAllStatus] = React.useState(true);

  const DAY_MAP = {
    MONDAY: "M",
    TUESDAY: "T",
    WEDNESDAY: "W",
    THURSDAY: "Th",
    FRIDAY: "F",
    SATURDAY: "Sa",
    SUNDAY: "Su",
  };
  const [getProviderActivityReport] = useGetProviderActivityReportMutation();
  const [clear, setClear] = React.useState(true);

  const getProviderReports = async (filters) => {
    console.log(filters);
    try {
      const from_date = filters.dateRange
        ? dayjs(filters.dateRange[0]).isValid()
          ? dayjs(filters.dateRange[0])?.format("DD-MM-YYYY")
          : ""
        : "";
      const to_date = filters.dateRange
        ? dayjs(filters.dateRange[1]).isValid()
          ? dayjs(filters.dateRange[1])?.format("DD-MM-YYYY")
          : ""
        : "";
      const providerActivityReport = await getProviderActivityReport({
        date_range: filters.dateRangeValue
          ? filters.dateRangeValue
          : dateRangeValue,
        provider_type: filters.setHeaders ? "" : selectedSpeciality,
        from_date: dateRangeValue ? "" : from_date,
        to_date: dateRangeValue ? "" : to_date,
        state: filters.setHeaders ? "" : selectedStateName,
        provider_id: filters.setHeaders ? "" : selectedProviders,
        status: filters.setHeaders ? "" : selectedStatus,
      }).unwrap();
      setProviderReports(
        [...providerActivityReport.data.report_data].sort(
          (a, b) => new Date(b.visit_date) - new Date(a.visit_date)
        )
      );
      setTotals([
        {
          name: "Total Records Found",
          value: providerActivityReport?.data?.report_data.length,
        },
        {
          name: "Total Distance Travelled",
          value: `${providerActivityReport.data.total_distance_travelled} miles`,
        },
      ]);
      if (filters.setHeaders === true) {
        const uniqueProviders = providerActivityReport.data.report_data.reduce(
          (acc, item) => {
            const existingProvider = acc.find(
              (provider) => provider.id === item.provider_id
            );
            if (!existingProvider) {
              acc.push({ name: item.provider_name, id: item.provider_id });
            }
            return acc;
          },
          []
        );
        setProviders(uniqueProviders);
        setFilteredPv(uniqueProviders);
        const spe = providerActivityReport.data.report_data.map(
          (item) => item.provider_type
        );
        setSpeciality([...new Set(spe)]);
        setFilteredSpe([...new Set(spe)]);
        const states = providerActivityReport.data.report_data.map(
          (item) => item.provider_state
        );
        const uniqueStatus = providerActivityReport.data.report_data.reduce((acc, item) => {
          const existingItem = acc.find((data) => data?.id === item.status);
          if (!existingItem) {
            acc.push({ name: item.status == 'SCHEDULED' ? 'Pending' : item?.status == 'NOSHOW' ? 'No Show' : item.status.charAt(0).toUpperCase() + item.status.slice(1).toLowerCase(), id: item.status });
          }
          return acc;
        }, []);
        setStatus(uniqueStatus);
        setStateNames([...new Set(states)]);
        setSelectedProviders(uniqueProviders.map(i => i.id));
        setSelectedSpeciality([...new Set(spe)]);
        setSelectedStateName([...new Set(states)]);
        setSelectedStatus(uniqueStatus.map(i => i.id))
      }
    } catch (err) {
      setOpen(true);
      setMessage(err?.response?.data?.message);
    }
  };
  const handleProviderFilter = (provider, obj) => {
    setClear(false);
    if (obj.selectAll === true) {
      if (obj.isSelectAll) {
        setSelectedProviders(providers.map(i => i.id))
      } else {
        setSelectedProviders([])
      }
    } else {
      if (selectedProviders.includes(provider)) {
        setSelectedProviders(
          selectedProviders.filter((filter) => filter !== provider)
        );
      } else {
        setSelectedProviders([...selectedProviders, provider]);
      }
    }
  };
  const handleStateNameFilter = (state, obj) => {
    setClear(false);
    if (obj.selectAll === true) {
      if (obj.isSelectAll) {
        setSelectedStateName(stateNames)
      } else {
        setSelectedStateName([])
      }
    } else {
      if (selectedStateName.includes(state)) {
        setSelectedStateName(
          selectedStateName.filter((filter) => filter !== state)
        );
      } else {
        setSelectedStateName([...selectedStateName, state]);
      }
    }
  };
  const handleSpecialityFilter = (spe, obj) => {
    setClear(false);
    if (obj.selectAll === true) {
      if (obj.isSelectAll) {
        setSelectedSpeciality(speciality)
      } else {
        setSelectedSpeciality([])
      }
    } else {
      if (selectedSpeciality.includes(spe)) {
        setSelectedSpeciality(
          selectedSpeciality.filter((filter) => filter !== spe)
        );
      } else {
        setSelectedSpeciality([...selectedSpeciality, spe]);
      }
    }
  };
  const handleStatusFilter = (statusit, obj) => {
    setClear(false);
    if (obj.selectAll === true) {
      if (obj.isSelectAll) {
        setSelectedStatus(status.map(i => i.id))
      } else {
        setSelectedStatus([])
      }
    } else {
      if (selectedStatus.includes(statusit)) {
        setSelectedStatus(
          selectedStatus.filter((filter) => filter !== statusit)
        );
      } else {
        setSelectedStatus([...selectedStatus, statusit]);
      }
    }
  };

  const exportPDF = () => {
    if (providerReports?.length > 0) {
      const doc = new jsPDF();
      doc.text("Provider Activity Report", 20, 10);
      doc.autoTable({
        styles: { halign: "center" },
        headStyles: { fillColor: [245, 247, 249] },
        html: "#providerActivityReport",
        theme: "plain",
        didParseCell: function (data) {
          if (data.row.index === 0 || data.row.index % 2 === 0) {
            data.cell.styles.fillColor = [255, 255, 255]; // White color for even rows
          } else {
            data.cell.styles.fillColor = [245, 247, 249]; // Light blue color for odd rows
          }
        },
      });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(13);
      doc.setTextColor(0, 0, 0);
      doc.text(
        `Total Records Found: ${totals[0].value}\nTotal Distance Travelled: ${totals[1].value}`,
        15,
        doc.lastAutoTable.finalY + 10
      );
      doc.save("patient-report.pdf");
      doc.save("provider-activity-report.pdf");
    } else {
      alert("No Records Found");
    }
  };
  const parseNull = (value, replacement = "NA", type = "string") => {
    return value === null || (Array.isArray(value) && value.length === 0)
      ? replacement
      : Array.isArray(value)
        ? value.map((day) => DAY_MAP[day]).join(", ")
        : type === "date"
          ? moment(value).format("DD/MM/YYYY")
          : value;
  };

  const exportCSV = () => {
    if (providerReports?.length > 0) {
      let csvContent =
        "Appt_Date,Provider_Name,Speciality,Appointment_Completed,Distance_Travelled,State,Status\r\n";
      providerReports.forEach((provider) => {
        const row = [
          parseNull(provider.visit_date, "NA", "date"),
          parseNull(provider.provider_name),
          parseNull(provider.provider_type),
          parseNull(provider.appointment_completed),
          parseNull(provider.distance_from_provider),
          parseNull(provider.provider_state),
          parseNull(provider.status)
        ];
        csvContent += row.join(",") + "\r\n";
      });

      // Add extra data at the bottom of the CSV
      csvContent += `\r\nTotal_Records_Found: ${totals[0].value}`;
      csvContent += `\r\nTotal Distance Travelled: ${totals[1].value}`;

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "provider-activity-report.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      alert("No Records Found");
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClear = () => {
    setSelectedStateName([]);
    setSelectedSpeciality([]);
    setSelectedProviders([]);
    // setDateRange([today.startOf("month"), today]);
    setDateRange([null, null]);
    setDateRangeValue("");
    setProviders([]);
    setFilteredPv([]);
    setSpeciality([]);
    setFilteredSpe([]);
    setStateNames([]);
    //getProviderReports({ setHeaders: true, dateRangeValue: "THIS_MONTH" });
    setClear(true);
    setProviderReports([])
    setStatus([]);
    setSelectedStatus([]);
  };
  React.useEffect(() => {
    // getProviders()
    //  getLocations()
    // getSpeciality()
  }, []);

  React.useEffect(() => {
    if (!clear) {
      getProviderReports({ setHeaders: false, dateRange: dateRange });
    }
    if (
      selectedProviders?.length > 0 ||
      selectedStateName?.length > 0 ||
      selectedSpeciality?.length > 0 ||
      selectedStatus?.length > 0
    ) {
      setIsSelectedValue(true);
    } else {
      setIsSelectedValue(false);
    }
  }, [selectedProviders, selectedStateName, selectedSpeciality, selectedStatus]);
  React.useEffect(() => {
    getProviderReports({ setHeaders: true, dateRangeValue: "THIS_MONTH" });
    if (
      selectedProviders?.length > 0 ||
      selectedStateName?.length > 0 ||
      selectedSpeciality?.length > 0 ||
      selectedStatus?.length > 0
    ) {
      setIsSelectedValue(true);
    } else {
      setIsSelectedValue(false);
    }
  }, []);
  return (
    <div className="relative flex flex-col w-full overflow-auto no-scrollbar standard-reports" style={{ height: "89vh" }}>
      <Snackbar
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={6000}
        message={message ? message : "Something went wrong"}
        severity="error"
      >
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message ? message : "Something went wrong"}
        </Alert>
      </Snackbar>
      <ReportHeader
        title="Provider Activity Report"
        exportCSV={exportCSV}
        exportPDF={exportPDF}
        totals={totals}
        total={providerReports.length}
      />
      <div
        className="p-2 d-flex flex-column overflow-auto align-items-center"
        style={{ height: "77vh" }}
      >
        <ProviderActivityReportFilter
          getProviderReports={getProviderReports}
          dateRange={dateRange}
          setDateRange={setDateRange}
          dateRangeValue={dateRangeValue}
          setDateRangeValue={setDateRangeValue}
          handleClear={handleClear}
          setClear={setClear}
          isSelecetdValue={isSelecetdValue}
        />
        <ProviderActivityReportTable
          providers={providers}
          providersReports={providerReports}
          setProviderReports={setProviderReports}
          filteredPv={filteredPv}
          setFilteredPv={setFilteredPv}
          selectedProviders={selectedProviders}
          handleProviderFilter={handleProviderFilter}
          stateNames={stateNames}
          selectedStateName={selectedStateName}
          handleStateNameFilter={handleStateNameFilter}
          speciality={speciality}
          selectedSpeciality={selectedSpeciality}
          handleSpecialityFilter={handleSpecialityFilter}
          filteredSpe={filteredSpe}
          setFilteredSpe={setFilteredSpe}
          isSelecetdValue={isSelecetdValue}
          isSelectAllProviders={isSelectAllProviders}
          setIsSelectAllProviders={setIsSelectAllProviders}
          isSelectAllSpe={isSelectAllSpe}
          setIsSelectAllSpe={setIsSelectAllSpe}
          isSelectAllState={isSelectAllState}
          setIsSelectAllState={setIsSelectAllState}
          status={status}
          selectedStatus={selectedStatus}
          isSelectAllStatus={isSelectAllStatus}
          setIsSelectAllStatus={setIsSelectAllStatus}
          handleStatusFilter={handleStatusFilter}
        />
      </div>
    </div>
  );
}

export default index;
