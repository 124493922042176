import React, { useRef, useState } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CheckboxSelector from "../../../Schedules/components/CheckboxSelector";
import CircularProgress from '@mui/material/CircularProgress';
import { Icons } from "../../../../assets";
import SearchIcon from "@mui/icons-material/Search";
import Stack from '@mui/material/Stack';
import { Tooltip } from "@mui/material";
import classNames from "classnames";
import moment from "moment";

function NoShowReportTable({
    noShowReports,
    setNoShowReports,
    providers,
    filteredPv,
    setFilteredPv,
    selectedProviders,
    handleProviderFilter,
    isSelecetdValue,
    isSelectAllProviders,
    setIsSelectAllProviders,
    isSelectAllVisitType,
    setIsSelectAllVisitType,
    isSelectAllPatients,
    setIsSelectAllPatients,
    filteredPt,
    setFilteredPt,
    selectedVisitType,
    selectedPatientsName,
    handleVisitTypeFilter,
    handlePatientNameFilter,
    visitType,
    patients,
}) {
    const dropdownRef = useRef(null);
    const [providerListOpen, setProviderListOpen] = useState(false);
    const [isApptDtAse, setIsAppDtAse] = React.useState(true);
    const [providerNameSearch, setProviderNameSearch] = useState("");
    const [patientNameSearch, setPatientNameSearch] = React.useState("");
    const [visitTypeListOpen, setVisitTypeListOpen] = React.useState(false);
    const [patientNameListOpen, setPatientNameListOpen] = React.useState(false);
    const dropdownRefVisit = React.useRef(null);
    const dropdownRefProvider = React.useRef(null);
    const [isPatientIdAse, setIsPatientIdAse] = React.useState(true);
    const handleProviderListOpen = () => {
        setProviderListOpen(!providerListOpen);

        setVisitTypeListOpen(false);
        setPatientNameListOpen(false);
        setPatientNameSearch("");
        setFilteredPt(patients);

        if (providerListOpen) {
            setProviderNameSearch("");
            setFilteredPv(providers);
        }

    };
    const handlePatientListOpen = () => {
        setPatientNameListOpen(!patientNameListOpen);
        setVisitTypeListOpen(false);
        setProviderListOpen(false);
        if (patientNameListOpen) {
            setPatientNameSearch("");
            setFilteredPt(patients);
        }
        setProviderNameSearch("");
        setFilteredPv(providers);
    };
    const handleVisitTypeListOpen = () => {

        setVisitTypeListOpen(!visitTypeListOpen);
        setProviderListOpen(false);
        setPatientNameListOpen(false);
        setPatientNameSearch("");
        setFilteredPt(patients);
        setProviderNameSearch("");
        setFilteredPv(providers);
    };
    const handleSelectAllProviders = () => {
        handleProviderFilter(-1, {
            selectAll: true,
            isSelectAll: !isSelectAllProviders,
        });
        setIsSelectAllProviders(!isSelectAllProviders);

    };
    const handleSelectAllVisitType = () => {
        handleVisitTypeFilter(-1, {
            selectAll: true,
            isSelectAll: !isSelectAllVisitType,
        });
        setIsSelectAllVisitType(!isSelectAllVisitType);
    };
    const handleSelectAllPatients = () => {
        handlePatientNameFilter(-1, {
            selectAll: true,
            isSelectAll: !isSelectAllPatients,
        });
        setIsSelectAllPatients(!isSelectAllPatients);
    };
    const TABLE_HEADERS = [
        "'No Show' Visit Date",
        "'No Show' Visit type",
        "Provider Name",
        "Patient Name",
        "Patient EHR ID",
        "Patient location address",
    ];
    const DAY_MAP = {
        MONDAY: "M",
        TUESDAY: "T",
        WEDNESDAY: "W",
        THURSDAY: "Th",
        FRIDAY: "F",
        SATURDAY: "Sa",
        SUNDAY: "Su",
    };
    const parseNull = (value, replacement = "NA", type = "string") => {
        return value === null || (Array.isArray(value) && value.length === 0)
            ? replacement
            : Array.isArray(value)
                ? value.map((day) => DAY_MAP[day]).join(",")
                : type === "date"
                    ? moment(value).format("MM/DD/YYYY")
                    : value;
    };
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setPatientNameListOpen(false);
            setPatientNameSearch("");
            setFilteredPt(patients);
        }
        if (
            dropdownRefVisit.current &&
            !dropdownRefVisit.current.contains(event.target)
        ) {
            setVisitTypeListOpen(false);
        }
        if (
            dropdownRefProvider.current &&
            !dropdownRefProvider.current.contains(event.target)
        ) {
            setProviderListOpen(false);
            setProviderNameSearch("");
            setFilteredPv(providers);
        }
    };
    React.useEffect(() => {
        if (
            patientNameListOpen ||

            visitTypeListOpen ||
            providerListOpen

        ) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [
        patientNameListOpen,

        visitTypeListOpen,
        providerListOpen,

    ]);
    React.useEffect(() => {
        setIsAppDtAse(true);
        setIsPatientIdAse(true);

        selectedPatientsName.length === patients.length
            ? setIsSelectAllPatients(true)
            : setIsSelectAllPatients(false);
        selectedVisitType.length === visitType.length
            ? setIsSelectAllVisitType(true)
            : setIsSelectAllVisitType(false);
        selectedProviders.length === providers.length
            ? setIsSelectAllProviders(true)
            : setIsSelectAllProviders(false);

    }, [
        selectedPatientsName,

        selectedProviders,

        selectedVisitType,

    ]);
    return (
        <>
            <div className="h-full overflow-auto mt-2 w-full">
                <table
                    className="w-full"
                    style={{ whiteSpace: "nowrap" }}
                    id="noShowReport"
                >
                    <thead>
                        <tr className="bg-primarylightgrey">
                            {TABLE_HEADERS.map((header, index) => {
                                if (header === "Provider Name") {
                                    return (
                                        <th
                                            key={index}
                                            className={
                                                "px-2 py-3 text-base relative min-w-56 " +
                                                (selectedProviders.length > 0 || providerListOpen
                                                    ? "bg-highlight"
                                                    : "")
                                            }
                                            ref={dropdownRefProvider}
                                        >
                                            <div className="flex flex-row items-center">
                                                <p className="m-0">
                                                    {header}{" "}
                                                    <span className="dropdown-icon ml-3"></span>
                                                </p>
                                                <Tooltip title="Sort">
                                                    <button
                                                        className="pr-2"
                                                        onClick={handleProviderListOpen}
                                                    >
                                                        <img
                                                            src={Icons.Sort_Icon}
                                                            alt="search"
                                                            className="w-5 h-5 cursor-pointer search-icon"
                                                        />
                                                    </button>
                                                </Tooltip>
                                                {providerListOpen && (
                                                    <div className="absolute z-50 flex flex-col w-full overflow-auto no-scrollbar left-0 bg-white border  max-h-52 top-16">
                                                        <div className="d-flex align-center sticky top-0 bg-white">
                                                            <input
                                                                type="search"
                                                                value={providerNameSearch}
                                                                onChange={(e) => {
                                                                    setProviderNameSearch(e.target.value);
                                                                    setFilteredPv(
                                                                        providers.filter((provider) => {
                                                                            return provider.name
                                                                                .toLowerCase()
                                                                                .includes(
                                                                                    e.target.value.toLowerCase()
                                                                                );
                                                                        })
                                                                    );
                                                                }}
                                                                className="search-input"
                                                                autoFocus
                                                                placeholder="Search"
                                                            />
                                                            {providerNameSearch === "" ? (
                                                                <SearchIcon className="search-icon-input" />
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        {filteredPv.length > 0 ? (
                                                            <>
                                                                <th
                                                                    className={classNames(
                                                                        "px-4 py-3 cursor-pointer text-sm"
                                                                    )}
                                                                >
                                                                    <label className="flex flex-row items-center gap-2 cursor-pointer">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="w-4 h-4 checked:lightblue"
                                                                            checked={
                                                                                isSelectAllProviders ||
                                                                                selectedProviders.length ===
                                                                                providers.length
                                                                            }
                                                                            onChange={(e) => {
                                                                                handleSelectAllProviders();
                                                                            }}
                                                                        />
                                                                        <p
                                                                            className={classNames(
                                                                                "m-0 cursor-pointer text-sm font-bold"
                                                                            )}
                                                                        >
                                                                            Select All
                                                                        </p>
                                                                    </label>
                                                                </th>
                                                                {filteredPv.map((provider, index) => (
                                                                    <div
                                                                        className={classNames(
                                                                            "hover:bg-primarylightgrey",
                                                                            providers.length - 1 && "border-b"
                                                                        )}
                                                                    >
                                                                        <CheckboxSelector
                                                                            header={provider.name}
                                                                            isChecked={
                                                                                selectedProviders.includes(
                                                                                    provider.id
                                                                                )
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            handleChange={(_e) =>
                                                                                handleProviderFilter(provider.id, {
                                                                                    selectAll: false,
                                                                                    isSelectAll: !isSelectAllProviders,
                                                                                })
                                                                            }
                                                                            isBold={false}
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </>
                                                        ) : (
                                                            <div className="no-record-found">
                                                                No Providers Found
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </th>
                                    );
                                }
                                else if (header === "Patient Name") {
                                    return (
                                        <th
                                            key={index}
                                            className={
                                                "px-2 py-3 text-base relative min-w-56 " +
                                                (patientNameListOpen ||
                                                    selectedPatientsName.length > 0
                                                    ? "bg-highlight"
                                                    : "")
                                            }
                                            ref={dropdownRef}
                                        >
                                            <div className="flex flex-row items-center">
                                                <p className="m-0">
                                                    {header}
                                                    <span className="dropdown-icon ml-3"></span>
                                                </p>
                                                <Tooltip title="Sort">
                                                    <button
                                                        className="pr-2"
                                                        onClick={handlePatientListOpen}
                                                    >
                                                        <img
                                                            src={Icons.Sort_Icon}
                                                            alt="search"
                                                            className="w-5 h-5 cursor-pointer search-icon"
                                                        />
                                                    </button>
                                                </Tooltip>{" "}
                                                {patientNameListOpen && (
                                                    <div className="absolute z-50 flex flex-col w-full overflow-auto no-scrollbar bg-white border  max-h-52 top-16 left-0">
                                                        <div className="d-flex align-center sticky top-0 bg-white">
                                                            <input
                                                                type="search"
                                                                value={patientNameSearch}
                                                                onChange={(e) => {
                                                                    setPatientNameSearch(e.target.value);
                                                                    setFilteredPt(
                                                                        patients.filter((patient) => {
                                                                            return patient.name
                                                                                .toLowerCase()
                                                                                .includes(
                                                                                    e.target.value.toLowerCase()
                                                                                );
                                                                        })
                                                                    );
                                                                }}
                                                                className="search-input"
                                                                autoFocus
                                                                placeholder="Search"
                                                            />
                                                            {patientNameSearch === "" ? (
                                                                <SearchIcon className="search-icon-input " />
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        {filteredPt.length > 0 ? (
                                                            <>
                                                                <th
                                                                    className={classNames(
                                                                        "px-4 py-3 cursor-pointer text-sm"
                                                                    )}
                                                                >
                                                                    <label className="flex flex-row items-center gap-2 cursor-pointer">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="w-4 h-4 checked:lightblue"
                                                                            checked={
                                                                                isSelectAllPatients ||
                                                                                selectedPatientsName.length ===
                                                                                patients.length
                                                                            }
                                                                            onChange={(e) => {
                                                                                handleSelectAllPatients();
                                                                            }}
                                                                        />
                                                                        <p
                                                                            className={classNames(
                                                                                "m-0 cursor-pointer text-sm font-bold"
                                                                            )}
                                                                        >
                                                                            Select All
                                                                        </p>
                                                                    </label>
                                                                </th>
                                                                {filteredPt.map((patient, index) => (
                                                                    <div
                                                                        className={classNames(
                                                                            "hover:bg-primarylightgrey",
                                                                            patients.length - 1 && "border-b"
                                                                        )}
                                                                    >
                                                                        <CheckboxSelector
                                                                            header={patient.name}
                                                                            isChecked={
                                                                                selectedPatientsName.includes(
                                                                                    patient.id
                                                                                )
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            handleChange={(_e) =>
                                                                                handlePatientNameFilter(patient.id, {
                                                                                    selectAll: false,
                                                                                    isSelectAllPatients,
                                                                                })
                                                                            }
                                                                            isBold={false}
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </>
                                                        ) : (
                                                            <div className="no-record-found">
                                                                No Patients Found
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </th>
                                    );
                                } else if (header === "'No Show' Visit Date") {
                                    return (
                                        <th
                                            key={index}
                                            className="px-2 py-3 justify-center text-base min-w-40 d-flex gap-30"
                                        >
                                            <div className="d-flex flex-row items-center justify-center relative">
                                                <div className="d-flex align-items-center w-full justify-center">
                                                    <p className="m-0">{header}</p>
                                                    {isApptDtAse ? (
                                                        <Tooltip title="Sort Descending">
                                                            <ArrowDropUpIcon
                                                                style={{
                                                                    fontSize: 30,
                                                                    color: "#212529",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() => {
                                                                    setIsAppDtAse(false);
                                                                    const result = [...noShowReports].sort(
                                                                        (a, b) =>
                                                                            new Date(a.visit_date) -
                                                                            new Date(b.visit_date)
                                                                    );
                                                                    setNoShowReports(result);
                                                                }}
                                                                value="asc"
                                                                className="arrow-up"
                                                            />
                                                        </Tooltip>
                                                    ) : (
                                                        <Tooltip title="Sort Ascending">
                                                            <ArrowDropDownIcon
                                                                style={{
                                                                    fontSize: 30,
                                                                    color: "#212529",
                                                                    cursor: "pointer",
                                                                }}
                                                                value="des"
                                                                className="arrow-down"
                                                                onClick={() => {
                                                                    setIsAppDtAse(true);
                                                                    const result = [...noShowReports].sort(
                                                                        (a, b) =>
                                                                            new Date(b.visit_date) -
                                                                            new Date(a.visit_date)
                                                                    );
                                                                    setNoShowReports(result);
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            </div>
                                        </th>
                                    );
                                } else if (header === "'No Show' Visit type") {
                                    return (
                                        <th
                                            key={index}
                                            className={
                                                "px-2 py-3 text-base relative min-w-56 " +
                                                (selectedVisitType.length > 0 || visitTypeListOpen
                                                    ? "bg-highlight"
                                                    : "")
                                            }
                                            ref={dropdownRefVisit}
                                        >
                                            <div className="flex flex-row items-center justify-center">
                                                <p className="m-0">
                                                    {header}{" "}
                                                    <span className="dropdown-icon ml-3"></span>
                                                </p>
                                                <Tooltip title="Sort">
                                                    <button
                                                        className="pr-2"
                                                        onClick={handleVisitTypeListOpen}
                                                    >
                                                        <img
                                                            src={Icons.Sort_Icon}
                                                            alt="search"
                                                            className="w-5 h-5 cursor-pointer search-icon"
                                                        />
                                                    </button>
                                                </Tooltip>
                                                {visitTypeListOpen && (
                                                    <div className="absolute z-50 flex flex-col w-full overflow-auto no-scrollbar bg-white border  max-h-52 top-16 ">
                                                        {visitType.length > 0 ? (
                                                            <>
                                                                <th
                                                                    className={classNames(
                                                                        "px-4 py-3 cursor-pointer text-sm"
                                                                    )}
                                                                >
                                                                    <label className="flex flex-row items-center gap-2 cursor-pointer">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="w-4 h-4 checked:lightblue"
                                                                            checked={
                                                                                isSelectAllVisitType ||
                                                                                selectedVisitType.length ===
                                                                                visitType.length
                                                                            }
                                                                            onChange={(e) => {
                                                                                handleSelectAllVisitType();
                                                                            }}
                                                                        />
                                                                        <p
                                                                            className={classNames(
                                                                                "m-0 cursor-pointer text-sm font-bold"
                                                                            )}
                                                                        >
                                                                            {isSelectAllVisitType ||
                                                                                selectedVisitType.length ===
                                                                                visitType.length
                                                                                ? "Select All"
                                                                                : "Deselect All"}
                                                                        </p>
                                                                    </label>
                                                                </th>
                                                                {visitType.map((type, index) => (
                                                                    <div
                                                                        className={classNames(
                                                                            "hover:bg-primarylightgrey",
                                                                            visitType.length - 1 && "border-b"
                                                                        )}
                                                                    >
                                                                        <CheckboxSelector
                                                                            header={type.name}
                                                                            isChecked={
                                                                                selectedVisitType.includes(type.id)
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            handleChange={(_e) =>
                                                                                handleVisitTypeFilter(type.id, {
                                                                                    selectAll: false,
                                                                                    isSelectAll: !isSelectAllVisitType,
                                                                                })
                                                                            }
                                                                            isBold={false}
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </>
                                                        ) : (
                                                            <div className="no-record-found ">
                                                                No Visit Type Found
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </th>
                                    );
                                } else {
                                    return (
                                        <th key={index} className="px-2 py-3 text-base">
                                            <div className="flex flex-row justify-center items-center">
                                                <p className="m-0">{header}</p>
                                            </div>
                                        </th>
                                    );
                                }
                            })}
                        </tr>
                    </thead>
                    {noShowReports.length > 0 ? (
                        <tbody>
                            {noShowReports.map((provider, index) => {
                                const isEven = (index + 1) % 2 === 0;
                                return (
                                    <tr
                                        className={`text-base border-bottom text-left text-gray-900 border-bottom ${isEven ? "bg-primarylightgrey" : ""
                                            }`}
                                    >
                                        <td className="px-2 py-3">
                                            {parseNull(provider.visit_date, "NA", "date")}
                                        </td>
                                        <td className="px-2 py-3">
                                            {parseNull(provider.visit_type)}
                                        </td>
                                        <td className="px-2 py-3 reportnamecolumn">
                                            {parseNull(provider.provider_name)}
                                        </td>
                                        <td className="px-2 py-3 reportnamecolumn">
                                            {parseNull(provider.patient_name)}
                                        </td>
                                        <td className="px-2 py-3">
                                            {parseNull(provider.patient_ehr_id)}
                                        </td>
                                        <td className="px-2 py-3 align-items-center">
                                            {parseNull(`${provider.patient_street},${provider.patient_zip},${provider.patient_city},${provider.patient_state}`)}
                                        </td>

                                    </tr>
                                );
                            })}
                        </tbody>
                    ) : (
                        <tbody>
                            <div
                                className="d-flex align-items-center justify-content-center absolute w-full my-5 py-3"
                                style={{ zIndex: 0 }}
                            >
                                <p className="text-xl font-bold text-primarygrayborder">
                                    {" "}
                                    No Records Found
                                </p>
                            </div>
                        </tbody>
                    )}
                </table>
            </div>
        </>
    )
}

export default NoShowReportTable;