import React, { useEffect } from "react";
import ReusableModal from "../../../components/Modals/ReusableModal";
import classNames from "classnames";
import { Icon } from "@virtuslab/tetrisly-icons";
import Select from "react-select";

const AppointmentStatusModal = ({
  open,
  handleCancel,
  handleSubmit,
  status,
}) => {
  const statuses = [
    {
      value: "SCHEDULED",
      label: "Pending",
    },
    {
      value: "CONFIRMED",
      label: "Confirmed",
    },
    {
      value: "COMPLETED",
      label: "Completed",
    },
    {
      value: "NOSHOW",
      label: "No Show",
    }
  ];

  const [selectedStatus, setSelectedStatus] = React.useState(
    statuses.find((s) => s.value === status)
  );

  useEffect(() => {
    setSelectedStatus(statuses.find((s) => s.value === status));
  }, [status]);

  useEffect(() => {}, [selectedStatus]);

  return (
    <ReusableModal open={open} hideHeader>
      <div className="flex flex-col gap-3 p-3">
        <div className="flex flex-row gap-2">
          <div
            className={classNames(
              "flex items-center justify-center w-12 h-12 rounded-full bg-primaryblue"
            )}
          >
            <Icon name="20-calendar-check" color={"#fff"} />
          </div>
          <div className="flex flex-col">
            <p className="m-0 font-semibold">Change Appointment Status</p>
            <p className="m-0 font-light">
              Do you wish to change the status of this appointment?
            </p>
          </div>
        </div>
        <div className="flex flex-col w-1/2 gap-2">
          <div className="flex-1 font-semibold">Statuses</div>
          <div className="flex-1">
            <Select
              options={statuses}
              value={selectedStatus}
              onChange={(selected) => setSelectedStatus(selected)}
              className="w-full"
            />
          </div>
        </div>
        <div className="h-[1px] w-full bg-primarygrayborder" />
        <div className="flex flex-row items-center justify-end gap-3">
          <button
            onClick={handleCancel}
            className="px-4 py-2 font-medium border rounded-md bg-primarylightgrey border-primarygray"
          >
            Cancel
          </button>
          <button
            className={classNames(
              "px-4 py-2 font-medium text-white rounded-md bg-primaryblue border-[1px] border-primaryblue"
            )}
            onClick={async () => {
              await handleSubmit(selectedStatus.value);
              handleCancel();
            }}
          >
            Proceed
          </button>
        </div>
      </div>
    </ReusableModal>
  );
};

export default AppointmentStatusModal;
