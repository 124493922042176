import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import "./VisitCancelModal.css";
import CustomButton from "../Buttons/CustomButton";

const InfoModal = ({ open: isOpen, handleClose, title, subtext, type }) => {
  const [open, setOpen] = useState(isOpen);

  const isSuccess = type === "success";
  const isError = type === "error";

  const onClose = () => {
    setOpen(false);
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"sm"}
      fullWidth
    >
      <Grid
        className={`${
          isSuccess
            ? "success-header"
            : isError
            ? "error-header"
            : "info-header"
        }`}
      >
        <Grid className="d-flex justify-content-between align-items-center mx-3 my-2">
          <Grid className="d-flex align-items-center">
            <Typography className="cancel-title px-2 text-white">
              {isSuccess ? "Success" : isError ? "Error" : title}
            </Typography>
          </Grid>
          <IconButton onClick={handleClose}>
            <CloseIcon style={{ color: "#fff" }} />
          </IconButton>
        </Grid>
      </Grid>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          className="info-subtext"
        >
          {subtext}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <DialogActions>
          <CustomButton
            className={`button-cancel ${
              isSuccess
                ? "success-header"
                : isError
                ? "error-header"
                : "info-header"
            }`}
            onClick={onClose}
          >
            Okay
          </CustomButton>
        </DialogActions>
      </DialogActions>
    </Dialog>
  );
};

export default InfoModal;
