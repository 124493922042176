import {
  ArrowDownwardTwoTone,
  ArrowUpwardTwoTone,
  FilterList,
} from "@mui/icons-material";
import { Close_Icon, Search_Icon } from "../../../assets/images";
import { Collapse, Tooltip } from "@mui/material";
import { selectToken, selectUser } from "../../../features/auth/authSlice";
import {
  useCreateProviderMutation,
  useDeleteProviderMutation,
  useUpdateProviderMissingFieldsMutation,
} from "../../../features/provider/providerApiSlice";
import {
  useCreateProviderUserMutation,
  useResendInviteMutation,
  useUpdateUserEmailAndPhoneMutation,
} from "../../../features/user/userApiSlice";

import CheckboxSelector from "../../Schedules/components/CheckboxSelector";
import CloseSvg from "../../../assets/svgs/CloseSvg";
import { FileUploader } from "react-drag-drop-files";
import FilterSelect from "../../../components/Filter/FilterSelect";
import IconComponent from "../../../components/DataTable/Icon";
import { Icons } from "../../../assets";
import Loading from "../../../components/Loading";
import ProviderUpdateModal from "./components/ProviderUpdateModal";
import React from "react";
import ReusableModal from "../../../components/Modals/ReusableModal";
import Search from "../../../components/Search/SearchNew";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import axios from "axios";
import classNames from "classnames";
import { useGetProvidersMutation } from "../../../features/organisation/organisationApiSlice";
import { useLSStore } from "../../../context/ls-store";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  ACTIVITY_STATUS,
  ACTIVITY_TYPE,
} from "../../../constants/config.constants";
import { useCreateLogsMutation } from "../../../features/logs/logsApiSlice";

const fileTypes = ["csv", "xls", "xlsx"];

const ProviderList = () => {
  const [providers, setProviders] = React.useState([]);
  const [statuses, setStatuses] = React.useState([]);
  const [specialities, setSpecialities] = React.useState([]);

  const [specialityFilter, setSpecialityFilter] = React.useState([]);
  const [statusFilter, setStatusFilter] = React.useState([]);
  const [searchFilter, setSearchFilter] = React.useState("");

  const [inviteModal, setInviteModal] = React.useState(false);
  const [selectedProvider, setSelectedProvider] = React.useState(null);
  const [sortStatusOrder, setSortStatusOrder] = React.useState("none");
  const [sortNameOrder, setSortNameOrder] = React.useState("none");
  const [sortSpecialityOrder, setSortSpecialityOrder] = React.useState("none");

  const [addOpen, setAddOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(null);
  const [del, setDel] = React.useState(null);

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [speciality, setSpeciality] = React.useState("");
  const [street, setStreet] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [zip, setZip] = React.useState("");
  const [npiId, setNpiId] = React.useState("");
  const [providerEhrId, setProviderEhrId] = React.useState("");
  const [ehrId, setEhrId] = React.useState("");
  const [errorMsg, setErrorMsg] = React.useState("");

  const navigate = useNavigate();

  const handleSortStatus = (a, b) => {
    if (sortStatusOrder === "none") {
      return 0;
    } else if (sortStatusOrder === "asc") {
      return a.status.localeCompare(b.status);
    } else {
      return b.status.localeCompare(a.status);
    }
  };

  const handleSortName = (a, b) => {
    if (sortNameOrder === "none") {
      return 0;
    } else if (sortNameOrder === "asc") {
      return a.name.localeCompare(b.name);
    } else {
      return b.name.localeCompare(a.name);
    }
  };

  const handleSortSpeciality = (a, b) => {
    if (sortSpecialityOrder === "none") {
      return 0;
    } else if (sortSpecialityOrder === "asc") {
      return a.speciality.localeCompare(b.speciality);
    } else {
      return b.speciality.localeCompare(a.speciality);
    }
  };

  const handleSpecialityFilter = (speciality) => {
    if (specialityFilter.indexOf(speciality) === -1) {
      setSpecialityFilter([...specialityFilter, speciality]);
    } else {
      setSpecialityFilter(
        specialityFilter.filter((filter) => filter !== speciality)
      );
    }
  };

  const handleStatusFilter = (status) => {
    if (statusFilter.indexOf(status) === -1) {
      setStatusFilter([...statusFilter, status]);
    } else {
      setStatusFilter(statusFilter.filter((filter) => filter !== status));
    }
  };

  const handleProviderClick = (provider) => {
    navigate(`/data-management/providers/${provider.id}`);
  };

  const user = useSelector(selectUser);
  const [getProviders, { isLoading: providerLoading }] =
    useGetProvidersMutation();

  const [createProviderUser, { isLoading: userLoading }] =
    useCreateProviderUserMutation();
  const [updateProviderMissingFields, { isLoading: missingLoading }] =
    useUpdateProviderMissingFieldsMutation();

  const [createProvider, { isLoading: createLoading }] =
    useCreateProviderMutation();
  const [deleteProvider, { isLoading: deleteLoading }] =
    useDeleteProviderMutation();

  const [updateUserApi, { isLoading: updateLoading }] =
    useUpdateUserEmailAndPhoneMutation();

  const [createLog] = useCreateLogsMutation();
  const [resendInvite, { isLoading: resendLoading }] =
    useResendInviteMutation();

  const isLoading =
    providerLoading ||
    userLoading ||
    missingLoading ||
    createLoading ||
    deleteLoading ||
    updateLoading ||
    resendLoading;

  const updateMissingFields = async (data) => {
    try {
      const payload = {
        activity: `User ${user?.details?.name} has updated the data for provider ${selectedProvider.name}.`,
        type: ACTIVITY_TYPE.PROVIDER_UPDATE,
      };
      try {
        await updateProviderMissingFields({
          id: selectedProvider.id,
          data: {
            npi_id: data.providerNpi,
            speciality: data.providerSpeciality,
            street: data.providerStreet,
            city: data.providerCity,
            state: data.providerState,
            zip: data.providerZip,
          },
        }).unwrap();

        const updatedProviders = providers.map((provider) => {
          if (provider.id === selectedProvider.id) {
            return {
              ...provider,
              npi_id: +data.providerNpi,
              speciality: data.providerSpeciality,
              address: [
                data.providerStreet,
                data.providerCity,
                data.providerState,
                data.providerZip,
              ],
            };
          }
          return provider;
        });

        setProviders(updatedProviders);

        setAlert({
          message: "Provider Updated Successfully",
          severity: "success",
        });
        await createLog({
          ...payload,
          status: ACTIVITY_STATUS.SUCCESS,
        }).unwrap();
      } catch (error) {
        await createLog({
          ...payload,
          status: ACTIVITY_STATUS.FAILED,
        }).unwrap();
        throw new Error(error.data?.message || error.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const inviteProvider = async (data) => {
    try {
      const payload = {
        activity: `User ${user?.details?.name} has invited provider ${selectedProvider.name} to join CareSMS`,
        type: ACTIVITY_TYPE.PROVIDER_UPDATE,
      };
      try {
        await createProviderUser({
          providerId: selectedProvider.id,
          userData: {
            type: 3,
            email: data.providerEmail,
            phone: data.providerPhone,
          },
        }).unwrap();

        const updatedProviders = providers.map((provider) => {
          if (provider.id === selectedProvider.id) {
            return {
              ...provider,
              email: data.providerEmail,
              phone: data.providerPhone,
            };
          }
          return provider;
        });

        setProviders(updatedProviders);

        setAlert({
          message: "Provider Invited Successfully",
          severity: "success",
        });
        await createLog({
          ...payload,
          status: ACTIVITY_STATUS.SUCCESS,
        }).unwrap();
      } catch (error) {
        await createLog({
          ...payload,
          status: ACTIVITY_STATUS.FAILED,
        }).unwrap();
        throw new Error(error.data.message || error.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const handleResendInvite = async (data) => {
    try {
      const payload = {
        activity: `User ${user?.details?.name} has resent the invite for provider ${data.name}`,
        type: ACTIVITY_TYPE.PROVIDER_UPDATE,
      };
      try {
        await resendInvite({
          id: data.userId,
          email: data.email,
          firstname: data.name.split(" ")[0],
        }).unwrap();

        setAlert({
          message: "Provider Invite sent Successfully",
          severity: "success",
        });
        await createLog({
          ...payload,
          status: ACTIVITY_STATUS.SUCCESS,
        }).unwrap();
      } catch (error) {
        await createLog({
          ...payload,
          status: ACTIVITY_STATUS.FAILED,
        }).unwrap();
        throw new Error(error.data.message || error.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const updateContactDetails = async (data) => {
    try {
      const payload = {
        activity: `User ${user?.details?.name} has updated the data for provider ${selectedProvider.name}.`,
        type: ACTIVITY_TYPE.PROVIDER_UPDATE,
      };
      try {
        await updateUserApi({
          id: selectedProvider.id,
          type: 3,
          phone: data.providerPhone,
          email: data.providerEmail,
        }).unwrap();

        const updatedProviders = providers.map((provider) => {
          if (provider.id === selectedProvider.id) {
            return {
              ...provider,
              email: data.providerEmail,
              phone: data.providerPhone,
            };
          }
          return provider;
        });

        setProviders(updatedProviders);

        setAlert({
          message: "Contact Details Updated Successfully",
          severity: "success",
        });
        await createLog({
          ...payload,
          status: ACTIVITY_STATUS.SUCCESS,
        }).unwrap();
      } catch (error) {
        await createLog({
          ...payload,
          status: ACTIVITY_STATUS.FAILED,
        }).unwrap();
        throw new Error(error.data?.message || error.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const fetchProviders = async () => {
    try {
      const { data } = await getProviders(user.organisation.id).unwrap();
      setProviders(data);
      setStatuses(Array.from(new Set(data.map((provider) => provider.status))));
      setSpecialities(
        Array.from(new Set(data.map((provider) => provider.speciality)))
      );
    } catch (error) {
      console.error(error.data.message);
    }
  };

  React.useEffect(() => {
    fetchProviders();
  }, []);

  const Avatar = ({ name, image }) => {
    return (
      <>
        {image !== null ? (
          <div className="flex items-center gap-2">
            <img
              src={image}
              alt={name}
              className="object-cover w-10 h-10 rounded-full"
            />
            <p className="m-0 font-semibold">{name}</p>
          </div>
        ) : (
          <p className="flex items-center justify-center w-10 h-10 m-0 text-white rounded-full bg-primaryblue">
            {name
              .split(" ")
              .map((n) => n[0])
              .join("")}
          </p>
        )}
      </>
    );
  };

  const setAlert = useLSStore((state) => state.setAlert);
  const token = useSelector(selectToken);

  async function uploadFile(file) {
    const formData = new FormData();
    formData.append("file", file);

    const payload = {
      activity: `User ${user?.details?.name} has uploaded provider data.`,
      type: ACTIVITY_TYPE.PROVIDER_UPLOAD,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/provider/verify-provider-doc`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": process.env.REACT_APP_SERVER_URL,
          },
          withCredentials: true,
        }
      );

      await fetchProviders();

      setOpenDropdown(false);

      setAlert({
        message: "Data Uploaded Successfully",
        severity: "success",
      });
      await createLog({
        ...payload,
        status: ACTIVITY_STATUS.SUCCESS,
      }).unwrap();
    } catch (error) {
      console.log(error);
      await createLog({
        ...payload,
        status: ACTIVITY_STATUS.FAILED,
      }).unwrap();
    }
  }

  const handleChange = (file) => {
    try {
      uploadFile(file);
    } catch (error) {
      console.log("err");
    }
  };

  const [openDropdown, setOpenDropdown] = React.useState(false);

  const saveProvider = async () => {
    const payload = {
      activity: `New provider ${firstName} ${lastName} has been created by User - ${user?.details?.name}`,
      type: ACTIVITY_TYPE.PROVIDER_ADD,
    };
    try {
      await createProvider({
        providerEhrId: providerEhrId,
        providerNpiId: npiId,
        providerFirstname: firstName,
        providerLastname: lastName,
        providerSpeciality: speciality,
        providerStreetAddress: street,
        providerCity: city,
        providerState: state,
        providerZip: zip,
      }).unwrap();
      await fetchProviders();
      await createLog({
        ...payload,
        status: ACTIVITY_STATUS.SUCCESS,
      }).unwrap();
    } catch (error) {
      await createLog({
        ...payload,
        status: ACTIVITY_STATUS.SUCCESS,
      }).unwrap();
      throw new Error(error?.data?.message);
    }
  };

  const onDeleteProvider = async () => {
    const payload = {
      activity: `Provider ${firstName} has been deleted by the User - ${user?.details?.name}`,
      type: ACTIVITY_TYPE.PROVIDER_DELETE,
    };
    try {
      await deleteProvider({
        id: del,
      }).unwrap();
      setProviders((prev) => {
        const update = [...prev];
        const index = update.findIndex((x) => x.id === del);
        update[index] = { ...update[index], status: "INACTIVE" };
        return [...update];
      });
      await createLog({
        ...payload,
        status: ACTIVITY_STATUS.SUCCESS,
      }).unwrap();
    } catch (error) {
      console.error(error?.data?.message);
      await createLog({
        ...payload,
        status: ACTIVITY_STATUS.FAILED,
      }).unwrap();
    }
  };

  const onEdit = (provider) => {
    setInviteModal(true);
    setEdit(provider.id);
    setSelectedProvider(provider);
  };

  const onDelete = (provider) => {
    setFirstName(provider.name);
    setDel(provider.id);
  };

  const clearInput = () => {};

  const downloadCSV = async () => {
    const payload = {
      activity: `User ${user?.details?.name} has downloaded provider upload template`,
      type: ACTIVITY_TYPE.PROVIDER_UPLOAD,
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/provider/get/template`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "provider-template.csv");
      document.body.appendChild(link);
      link.click();
      await createLog({
        ...payload,
        status: ACTIVITY_STATUS.SUCCESS,
      }).unwrap();
    } catch (error) {
      console.error(error);
      await createLog({
        ...payload,
        status: ACTIVITY_STATUS.FAILED,
      }).unwrap();
    }
  };

  return (
    <div className="flex flex-col items-start py-4 overflow-hidden">
      <Loading loading={isLoading} />
      {!user?.organisation?.data?.isEnterprise && (
        <div
          className={classNames(
            "w-full px-16 transition-all",
            !openDropdown && "pb-8"
          )}
        >
          <button
            onClick={() => {
              setOpenDropdown(!openDropdown);
            }}
            className="flex flex-row items-center justify-between w-full px-4 py-2 bg-gray-100 rounded-md"
          >
            <div className="flex flex-row items-center">
              Upload Provider Data
              <img
                src={Icons.Upload_Icon}
                alt="upload icon"
                className="mx-2 icon"
              />
            </div>
            {openDropdown ? (
              <Tooltip title="Collapse">
                <ArrowDownwardTwoTone />
              </Tooltip>
            ) : (
              <Tooltip title="Expand">
                <ArrowUpwardTwoTone />
              </Tooltip>
            )}
          </button>
        </div>
      )}
      <Collapse in={openDropdown} className="w-full">
        <div className="flex flex-col w-full gap-1 px-16 py-8">
          <div className="flex flex-row items-end justify-end w-full mb-2 md:mb-0">
            <p
              className="m-0 text-sm underline text-primaryblue"
              onClick={downloadCSV}
              role="button"
            >
              Provider Data Template
            </p>
          </div>
          <FileUploader
            handleChange={handleChange}
            name="file"
            types={fileTypes}
            classes="w-100"
          >
            <div className="flex hover:bg-subtleblue transition-all cursor-pointer flex-col w-full justify-center items-center gap-2 border-[1px] border-dashed border-secondarygrayborder rounded-lg p-4">
              <img
                src={Icons.Upload_Icon}
                alt="upload icon"
                className="mx-2 icon"
              />
              <p className="m-0 mt-2 text-sm">
                Drag & Drop or{" "}
                <span className="text-primaryblue">Choose file</span> to upload
              </p>
            </div>
          </FileUploader>
        </div>
      </Collapse>
      <div className="flex flex-row items-center justify-between w-full px-16">
        <div className="flex flex-row items-center w-full gap-12">
          <h1 className="m-0 text-2xl font-bold w-fit whitespace-nowrap">
            Providers{" "}
            <span className="ml-2 text-base font-bold text-primaryblue">
              {
                providers
                  .filter((provider) =>
                    provider.name
                      .toLowerCase()
                      .includes(searchFilter.toLowerCase())
                  )
                  .filter(
                    (provider) =>
                      specialityFilter.length === 0 ||
                      specialityFilter.indexOf(provider.speciality) !== -1
                  )
                  .filter(
                    (provider) =>
                      statusFilter.length === 0 ||
                      statusFilter.indexOf(provider.status) !== -1
                  )?.length
              }
              {/* {providers.length} */}
            </span>
          </h1>
          <div className="flex flex-row items-center w-full gap-8 max-w-7xl">
            <Search value={searchFilter} setValue={setSearchFilter} />
            <FilterSelect
              value={specialityFilter}
              handleChange={handleSpecialityFilter}
              data={specialities}
              placeholder="Filter by Speciality..."
            />
            <FilterSelect
              value={statusFilter}
              handleChange={handleStatusFilter}
              data={statuses}
              placeholder="Filter by Status ..."
            />
            <button
              onClick={() => {
                setAddOpen(true);
              }}
              className="px-4 py-2 shadow-sm rounded-md border-[2px] border-primarygrayborder whitespace-nowrap text-primaryblue"
            >
              Add New
            </button>
          </div>
        </div>
      </div>
      <div className="w-full px-16">
        <div className="relative w-full mt-8 mb-2 overflow-x-auto overflow-y-auto bg-white rounded-lg shadow max-h-[calc(100vh-270px)]">
          <table className="relative w-full whitespace-no-wrap bg-white border-collapse table-auto table-striped">
            <thead>
              <tr className="h-16 text-base font-bold text-left text-white bg-primaryblue whitespace-nowrap">
                <th className="px-4 py-3">
                  <div className="flex flex-row items-center gap-1">
                    <p className="m-0">Name</p>
                    <button
                      className="flex flex-col items-center gap-1"
                      onClick={() => {
                        if (sortNameOrder === "none") {
                          setSortNameOrder("asc");
                        }
                        if (sortNameOrder === "asc") {
                          setSortNameOrder("desc");
                        }
                        if (sortNameOrder === "desc") {
                          setSortNameOrder("none");
                        }
                      }}
                    >
                      {sortNameOrder === "asc" ? (
                        <Tooltip title="Sort Descending">
                          <ArrowDownwardTwoTone />
                        </Tooltip>
                      ) : sortNameOrder === "desc" ? (
                        <Tooltip title="Sort Ascending">
                          <ArrowUpwardTwoTone />
                        </Tooltip>
                      ) : (
                        // <div className="flex items-center h-4">
                        //   <div className="w-6 h-[2px] bg-white" />
                        // </div>
                        <div className="flex items-center providerlist-icon">
                          <SwapVertIcon />
                        </div>
                      )}
                    </button>
                  </div>
                </th>
                <th className="px-4 py-3">
                  <div className="flex flex-row items-center gap-2">
                    <p className="m-0">Type</p>
                    <button
                      className="flex flex-col items-center gap-1"
                      onClick={() => {
                        if (sortSpecialityOrder === "none") {
                          setSortSpecialityOrder("asc");
                        }
                        if (sortSpecialityOrder === "asc") {
                          setSortSpecialityOrder("desc");
                        }
                        if (sortSpecialityOrder === "desc") {
                          setSortSpecialityOrder("none");
                        }
                      }}
                    >
                      {sortSpecialityOrder === "asc" ? (
                        <Tooltip title="Sort Descending">
                          <ArrowDownwardTwoTone />
                        </Tooltip>
                      ) : sortSpecialityOrder === "desc" ? (
                        <Tooltip title="Sort Ascending">
                          <ArrowUpwardTwoTone />
                        </Tooltip>
                      ) : (
                        // <div className="flex items-center h-4">
                        //   <div className="w-6 h-[2px] bg-white" />
                        // </div>
                        <div className="flex items-center providerlist-icon">
                          <SwapVertIcon />
                        </div>
                      )}
                    </button>
                  </div>
                </th>
                <th className="px-4 py-3">NPI ID</th>
                <th className="px-4 py-3">Phone</th>
                <th className="px-4 py-3">Email</th>
                <th className="px-4 py-3">
                  <div className="flex flex-row items-center gap-2">
                    <p className="m-0">Status</p>
                    <button
                      className="flex flex-col items-center gap-1"
                      onClick={() => {
                        if (sortStatusOrder === "none") {
                          setSortStatusOrder("asc");
                        }
                        if (sortStatusOrder === "asc") {
                          setSortStatusOrder("desc");
                        }
                        if (sortStatusOrder === "desc") {
                          setSortStatusOrder("none");
                        }
                      }}
                    >
                      {sortStatusOrder === "asc" ? (
                        <Tooltip title="Sort Descending">
                          <ArrowDownwardTwoTone />
                        </Tooltip>
                      ) : sortStatusOrder === "desc" ? (
                        <Tooltip title="Sort Ascending">
                          <ArrowUpwardTwoTone />
                        </Tooltip>
                      ) : (
                        <div className="flex items-center providerlist-icon">
                          <SwapVertIcon />
                        </div>
                      )}
                    </button>
                  </div>
                </th>
                {/* <th className="px-4 py-3">Edit</th> */}
                <th className="px-4 py-3">
                  <p className="m-0">Edit</p>
                </th>
                <th className="px-4 py-3">
                  <p className="m-0">Delete</p>
                </th>
                <th className="px-4 py-3">
                  <p className="m-0">Invite</p>
                </th>
              </tr>
            </thead>
            <tbody>
              {providers
                .filter((provider) =>
                  provider.name
                    .toLowerCase()
                    .includes(searchFilter.toLowerCase())
                )
                .filter(
                  (provider) =>
                    specialityFilter.length === 0 ||
                    specialityFilter.indexOf(provider.speciality) !== -1
                )
                .filter(
                  (provider) =>
                    statusFilter.length === 0 ||
                    statusFilter.indexOf(provider.status) !== -1
                )
                .sort((a, b) => handleSortName(a, b))
                .sort((a, b) => handleSortSpeciality(a, b))
                .sort((a, b) => handleSortStatus(a, b))
                .map((provider, index) => (
                  <tr
                    className={classNames(
                      "text-left text-gray-900 h-24 whitespace-nowrap",
                      index !== providers.length - 1
                        ? "border-b"
                        : "border-none"
                    )}
                    key={index}
                  >
                    <td className="px-4 py-3 text-left">
                      <div className="flex flex-row items-center gap-4">
                        <button
                          onClick={() => handleProviderClick(provider)}
                          className="flex flex-row items-center gap-4"
                        >
                          <Avatar
                            name={provider.name}
                            image={provider.profileImage}
                          />
                          <p className="m-0 font-semibold underline">
                            {provider.name}
                          </p>
                        </button>
                        {provider.locationId === null ? (
                          <button
                            onClick={() => {
                              setInviteModal(true);
                              setSelectedProvider(provider);
                            }}
                            className="bg-yellow-50 gap-1 text-yellow-600 border-[1px] flex items-center justify-center border-primaryyellow rounded-md px-2"
                          >
                            <WarningRoundedIcon
                              style={{
                                fontSize: "16px",
                              }}
                            />
                            <p className="m-0 text-sm">Missing Data Points</p>
                          </button>
                        ) : provider.email === "NA" ||
                          provider.phone === "NA" ? (
                          <button
                            onClick={() => {
                              setEdit(null);
                              setInviteModal(true);
                              setSelectedProvider(provider);
                            }}
                            className="bg-subtleblue border-[1px] border-primaryblue rounded-md px-2"
                          >
                            <p className="m-0 text-sm text-primaryblue">
                              Invite to{" "}
                              <span className="font-semibold">CareSMS</span>
                            </p>
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              setEdit(null);
                              setInviteModal(true);
                              setSelectedProvider(provider);
                            }}
                            className="bg-subtleblue border-[1px] border-primaryblue rounded-md px-2"
                          >
                            <p className="m-0 text-sm font-semibold text-primaryblue">
                              Update Contact Details
                            </p>
                          </button>
                        )}
                      </div>
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      {provider.speciality}
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      {provider.npi_id}
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      {provider.phone}
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      {provider.email}
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      <div
                        className={classNames(
                          provider.status.toUpperCase() === "ACTIVE"
                            ? "bg-subtlegreen border-[1px] border-primarygreen text-primarygreen"
                            : "bg-primarylightgrey border-[1px] border-primarygrayborder text-primarytextgrey",
                          "rounded-md text-center flex flex-row gap-1 items-center px-2 w-fit"
                        )}
                      >
                        <div
                          className={classNames(
                            "w-2 h-2 rounded-full",
                            provider.status.toUpperCase() === "ACTIVE"
                              ? "bg-primarygreen"
                              : "bg-primarytextgrey"
                          )}
                        />
                        {provider.status}
                      </div>
                    </td>
                    <td className="px-4 py-3 text-center text-gray-700">
                      <IconComponent
                        tooltip="Edit provider"
                        icon={"Edit_Icon"}
                        i={provider}
                        onClick={() => onEdit(provider)}
                      />
                    </td>
                    <td className="px-4 py-3 text-center text-gray-700">
                      <IconComponent
                        disabled={provider.status === "Inactive"}
                        tooltip="Delete provider"
                        icon={"Delete_Icon"}
                        i={provider}
                        onClick={() => onDelete(provider)}
                      />
                    </td>
                    <td className="px-4 py-3 text-center text-gray-700">
                      {provider.email === "NA" || provider.phone === "NA" ? (
                        <button
                          onClick={() => {
                            setEdit(null);
                            setInviteModal(true);
                            setSelectedProvider(provider);
                          }}
                          className="bg-subtleblue border-[1px] border-primaryblue rounded-md px-2"
                        >
                          <p className="m-0 text-sm text-primaryblue">
                            Invite to{" "}
                            <span className="font-semibold">CareSMS</span>
                          </p>
                        </button>
                      ) : (
                        <button
                          onClick={() => handleResendInvite(provider)}
                          className="bg-subtleblue border-[1px] border-primaryblue rounded-md px-2"
                        >
                          <p className="m-0 text-sm text-primaryblue">
                            Resend Invite
                          </p>
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      {selectedProvider && inviteModal && (
        <ProviderUpdateModal
          open={inviteModal}
          setOpen={setInviteModal}
          handleSubmit={
            selectedProvider.locationId === null || !!edit
              ? updateMissingFields
              : selectedProvider.email === "NA"
              ? inviteProvider
              : updateContactDetails
          }
          provider={selectedProvider}
          setProvider={setSelectedProvider}
          type={
            selectedProvider.locationId === null || !!edit
              ? "update"
              : selectedProvider.email === "NA"
              ? "invite"
              : "update details"
          }
          isEdit={!!edit}
        />
      )}
      <ReusableModal
        open={addOpen}
        title={"Add new provider"}
        onClose={() => {
          setAddOpen(false);
          clearInput();
        }}
      >
        <div className="flex flex-col items-center min-w-[480px]">
          <div className="h-[1px] w-full bg-primarygrayborder" />
          <div className="flex flex-col gap-4 p-6 overflow-auto max-h-[calc(100vh-200px)]">
            <div className="flex flex-row w-full gap-4">
              <div className="flex flex-col w-1/2 gap-2">
                <label htmlFor="email" className="m-0 text-base font-semibold">
                  First Name
                </label>
                <input
                  type="text"
                  id="firstname"
                  name="firstname"
                  placeholder="John"
                  className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="flex flex-col w-1/2 gap-2">
                <label htmlFor="phone" className="m-0 text-base font-semibold">
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastname"
                  name="lastname"
                  placeholder="Doe"
                  className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-row w-full gap-4">
              <div className="flex flex-col w-1/2 gap-2">
                <label htmlFor="street" className="m-0 text-base font-semibold">
                  Street
                </label>
                <input
                  type="text"
                  id="street"
                  name="street"
                  placeholder="Enter Street"
                  className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                />
              </div>
              <div className="flex flex-col w-1/2 gap-2">
                <label htmlFor="city" className="m-0 text-base font-semibold">
                  City
                </label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                  placeholder="Enter City"
                  value={city}
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row w-full gap-4">
              <div className="flex flex-col w-1/2 gap-2">
                <label htmlFor="state" className="m-0 text-base font-semibold">
                  State
                </label>
                <input
                  type="text"
                  id="state"
                  name="state"
                  className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                  placeholder="Enter State"
                  value={state}
                  onChange={(e) => {
                    setState(e.target.value);
                  }}
                />
              </div>
              <div className="flex flex-col w-1/2 gap-2">
                <label htmlFor="zip" className="m-0 text-base font-semibold">
                  Zip
                </label>
                <input
                  type="text"
                  id="zip"
                  name="zip"
                  className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                  placeholder="Zip code"
                  value={zip}
                  onChange={(e) => {
                    setZip(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row w-full gap-4">
              <div className="flex flex-col w-1/2 gap-2">
                <label
                  htmlFor="speciality"
                  className="m-0 text-base font-semibold"
                >
                  Speciality
                </label>
                <select
                  name="speciality"
                  id="speciality"
                  className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                  onChange={(e) => setSpeciality(e.target.value)}
                  value={speciality}
                >
                  <option value="NA">NA</option>
                  <option value="Family Medicine">Family Medicine</option>
                  <option value="Psychiatrists">Psychiatrists</option>
                  <option value="Podiatrists">Podiatrists</option>
                  <option value="Cardiologists">Cardiologists</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="flex flex-col w-1/2 gap-2">
                <label htmlFor="npiId" className="m-0 text-base font-semibold">
                  Npi Id
                </label>
                <input
                  type="text"
                  id="npiId"
                  name="npiId"
                  className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                  placeholder="Enter Npi Id"
                  value={npiId}
                  onChange={(e) => {
                    setNpiId(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row w-full gap-4">
              <div className="flex flex-col w-1/2 gap-2">
                <label htmlFor="ehrId" className="m-0 text-base font-semibold">
                  Ehr
                </label>
                <select
                  id="ehrId"
                  name="ehrId"
                  value={ehrId}
                  onChange={(e) => setEhrId(e.target.value)}
                  className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                >
                  <option value={""}>Select Ehr</option>
                  <option value={1}>Athena</option>
                </select>
              </div>
              <div className="flex flex-col w-1/2 gap-2">
                <label
                  htmlFor="providerEhrId"
                  className="m-0 text-base font-semibold"
                >
                  Provider Ehr Id
                </label>
                <input
                  type="text"
                  id="providerEhrId"
                  name="providerEhrId"
                  className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                  placeholder="Enter Provider Ehr Id"
                  value={providerEhrId}
                  onChange={(e) => {
                    setProviderEhrId(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          {errorMsg && <div className="text-red-500">{errorMsg}</div>}
          <div className="justify-center w-full mb-2 d-flex">
            <button
              disabled={
                firstName === "" ||
                lastName === "" ||
                street === "" ||
                city === "" ||
                state === "" ||
                zip === "" ||
                speciality === "" ||
                npiId === "" ||
                providerEhrId === "" ||
                ehrId === ""
              }
              className="w-75 disabled:opacity-40 disabled:cursor-not-allowed font-semibold p-2 border-[2px] rounded-md bg-subtleblue border-primaryblue text-primaryblue whitespace-nowrap"
              onClick={async () => {
                try {
                  await saveProvider();
                  setAddOpen(false);
                  clearInput();
                } catch (error) {
                  console.log(error);
                  setErrorMsg(error.message);
                }
              }}
            >
              Add
            </button>
          </div>
        </div>
      </ReusableModal>
      <ReusableModal
        open={!!del}
        title="Delete provider"
        onClose={() => {
          setDel(null);
          clearInput();
        }}
      >
        <p className="p-3 text-base">
          Are you sure you want to delete provider: {firstName}?
        </p>
        <div className="flex justify-end">
          <div className="flex gap-2 m-3">
            <button
              className="w-28 disabled:opacity-40 disabled:cursor-not-allowed font-semibold p-2 border-[2px] rounded-md bg-subtleblue border-primaryblue text-primaryblue whitespace-nowrap"
              onClick={() => {
                setDel(null);
                clearInput();
              }}
            >
              Cancel
            </button>
            <button
              className="p-2 font-semibold text-white rounded-md w-28 disabled:opacity-40 disabled:cursor-not-allowed bg-primaryblue whitespace-nowrap"
              onClick={async () => {
                await onDeleteProvider();
                clearInput();
                setDel(null);
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </ReusableModal>
    </div>
  );
};

export default ProviderList;
