export const BACK_OFFICE_ROLE = {
  1: "Admin",
  2: "Scheduler",
  3: "Executive",
  4: "Creator",
};

export const COMMUNITY_ROLE = {
  1: "Nurse",
  2: "Admin",
  3: "Supervisor",
};

export const USER_ROLE = {
  1: "Back Office",
  2: "Community",
  3: "Provider",
  4: "Patient",
  5: "Patient POC",
};

export const ACTIVITY_TYPE = {
  SCHEDULE_PUBLISH: "SCHEDULE_PUBLISH",
  APPOINTMENT_CREATED: "APPOINTMENT_CREATED",
  APPOINTMENT_CANCELED: "APPOINTMENT_CANCELED",
  SEQUENCE_CHANGE: "SEQUENCE_CHANGE",
  VISIT_STATUS_CHANGE: "VISIT_STATUS_CHANGE",
  VISIT_LOCKED: "VISIT_LOCKED",
  VISIT_UNLOCKED: "VISIT_UNLOCKED",
  DOWNLOAD_SCHEDULES: "DOWNLOAD_SCHEDULES",
  PROVIDER_ADD: "PROVIDER_ADD",
  PROVIDER_UPLOAD: "PROVIDER_UPLOAD",
  PROVIDER_UPDATE: "PROVIDER_UPDATE",
  PROVIDER_DELETE: "PROVIDER_DELETE",
  POC_ADD: "POC_ADD",
  POC_UPDATE: "POC_UPDATE",
  POC_DELETE: "POC_DELETE",
  PATIENT_NOTE_UPDATE: "PATIENT_NOTE_UPDATE",
  COMMUNITY_ADD: "COMMUNITY_ADD",
  COMMUNITY_UPDATE: "COMMUNITY_UPDATE",
  COMMUNITY_DELETE: "COMMUNITY_DELETE",
  COMMUNITY_STAFF_ADD: "COMMUNITY_STAFF_ADD",
  COMMUNITY_STAFF_UPDATE: "COMMUNITY_STAFF_UPDATE",
  COMMUNITY_STAFF_DELETE: "COMMUNITY_STAFF_DELETE",
  ZONE_ADD: "ZONE_ADD",
  ZONE_UPDATE: "ZONE_UPDATE",
  ZONE_INACTIVE: "ZONE_INACTIVE",
  CARETAG_ADD: "CARETAG_ADD",
  CARETAG_UPDATE: "CARETAG_UPDATE",
  CARETAG_INACTIVE: "CARETAG_INACTIVE",
  PROVIDER_SETTINGS_UPDATE: "PROVIDER_SETTINGS_UPDATE",
  VISIT_RULES_COMMUNITY_UPDATE: "VISIT_RULES_COMMUNITY_UPDATE",
  VISIT_RULES_HOME_UPDATE: "VISIT_RULES_HOME_UPDATE",
  BACK_OFFICE_USER_ADD: "BACK_OFFICE_USER_ADD",
  BACK_OFFICE_USER_UPDATE: "BACK_OFFICE_USER_UPDATE",
  BACK_OFFICE_USER_DELETE: "BACK_OFFICE_USER_DELETE",
};

export const ACTIVITY_STATUS = {
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
};
