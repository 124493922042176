import classNames from "classnames";
import moment from "moment";
import React from "react";
import CloseSvg from "../../assets/svgs/CloseSvg";
import {
  useCreateNewPatientAppointmentMutation,
  useRescheduleDispatchAppointmentMutation,
} from "../../features/appointment/appointmentApiSlice";
import MapV3 from "../../components/Maps/MapV3";
import ReusableModal from "../../components/Modals/ReusableModal";
import Loading from "../../components/Loading";
import { Tooltip } from "@mui/material";
import CheckSvg from "../../assets/svgs/CheckSvg";
import { Edit_Icon } from "../../assets/images";
import { DropArea } from "../LongitudinalSchedules/components/DropArea";
import { numberToPhone } from "../../utilities/resuseFunctions";
import Lock from "../../assets/svgs/Lock";
import Unlock from "../../assets/svgs/Unlock";
import { Icon } from "@virtuslab/tetrisly-icons";
import AppointmentLockModal from "../LongitudinalSchedules/components/AppointmentLockModal";
import AppointmentStatusModal from "../LongitudinalSchedules/components/AppointmentStatusModal";

const PatientSchedulingModal = ({
  providerAppointment,
  setProviderAppointment,
  appointments,
  dates,
  dateIndex,
  selectedPatient,
  appointmentType,
  setOpen,
  providers,
  resetAllandFetch,
  createAppointment,
  setCreateAppointment,
}) => {
  const [rescheduleDispatchAppointment] =
    useRescheduleDispatchAppointmentMutation();

  const [updatedAppointments, setUpdatedAppointments] = React.useState([]);

  const [uniqueLocations, setUniqueLocations] = React.useState([]);

  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const [openStatusModal, setOpenStatusModal] = React.useState(false);
  const [appointmentToChangeStatus, setAppointmentToChangeStatus] =
    React.useState(null);
  const [openLockModal, setOpenLockModal] = React.useState(false);
  const [appointmentToLock, setAppointmentToLock] = React.useState(null);

  const [updates, setUpdates] = React.useState([]);
  const [note, setNote] = React.useState("");

  const [editable, setEditable] = React.useState(false);
  const [draggingAppointment, setDraggingAppointment] = React.useState(null);

  const getClosestDistance = async (appointments, position, timeslot) => {
    const positionAppointment = appointments[position - 1];

    const appointment = {
      patient_id: selectedPatient?.value,
      provider_id: providerAppointment.provider.id,
      sequenceNumber: position,
      appointment_type: {
        id: appointmentType.value,
        name: appointmentType.label,
        duration: appointmentType.duration,
      },
      proposedPatient: true,
      timeslot: timeslot
        ? moment(timeslot, "HH:mm").format("h:mm A")
        : positionAppointment.timeslot,
      visitDate: moment(dates[dateIndex]).format("YYYY-MM-DD"),
    };

    const { data } = await rescheduleDispatchAppointment({
      appointment,
      appointmentIds: appointments.map((appt) => appt.appointmentId),
      indexToBeMoved: position - 1,
    });

    const dispatchIndex = data?.data?.findIndex((appt) => {
      return (
        appt.patient?.id === appointment.patient_id &&
        appt.provider?.id === appointment.provider_id
      );
    });

    const nextAppointments = data?.data
      ?.slice(dispatchIndex + 1)
      .map((appt, index) => {
        return {
          ...appt,
          sequence: appt.sequenceNo + 1,
          distance: appt.distance,
        };
      });

    const updatedAppointments = data?.data?.map((appt, index) => {
      return {
        ...appt,
        sequence: appt.sequenceNo,
        distance: appt.distance,
        proposedPatient: selectedPatient.value === appt.patient.id,
      };
    });

    const currentAppointment = updatedAppointments.find(
      (app) => app.proposedPatient
    );

    setCreateAppointment(currentAppointment);

    const updates = nextAppointments.map((appointment) => {
      return {
        id: appointment.appointmentId,
        sequenceNumber: appointment.sequence,
        distanceFromPreviousAppointment: +appointment.distance,
        timeslot: moment(appointment.timeslot, "h:mm A").format("HH:mm"),
      };
    });

    setUpdates(updates);

    setUpdatedAppointments(updatedAppointments);

    setProviderAppointment((prev) => ({
      ...prev,
      position,
    }));

    const locationMap = updatedAppointments.reduce(
      (acc, appointment, index) => {
        if (!acc[appointment.location.id]) {
          acc[appointment.location.id] = {
            location: appointment.location,
            appointments: [],
          };
        }
        acc[appointment.location.id].appointments.push({
          sequence: index + 1,
          patient: appointment.patient.name,
          proposedPatient: index + 1 === position,
        });
        return acc;
      },
      {}
    );

    const uniqueLocations = Object.values(locationMap);

    setUniqueLocations(uniqueLocations);
  };

  React.useEffect(() => {
    if (providerAppointment)
      getClosestDistance(
        appointments,
        providerAppointment.position,
        providerAppointment.timeslot
      );
  }, [appointments, providerAppointment?.position]);

  React.useEffect(() => {}, [updatedAppointments]);

  const onDrop = async (index) => {
    if (draggingAppointment === null) return;
    getClosestDistance(appointments, index + 1);
  };

  const [createNewAppointment, { isLoading }] =
    useCreateNewPatientAppointmentMutation();

  const handleSubmit = async () => {
    try {
      const result = await createNewAppointment({
        appointment: {
          visitDate: moment(dates[dateIndex]).format("YYYY-MM-DD"),
          sequenceNumber: createAppointment.sequenceNo,
          appointmentType: appointmentType.value,
          patientId: createAppointment.patient.id,
          providerId: createAppointment.provider.id,
          distanceFromPreviousAppointment: createAppointment.distance,
          distanceFromProvider: createAppointment.distanceFromHome ?? 0,
          timeslot: moment(createAppointment.timeslot, "h:mm A").format(
            "HH:mm"
          ),
          visitType: "ADHOC",
          note,
          visitStatus: createAppointment?.visitStatus,
          status: createAppointment?.status,
        },
        updates,
      }).unwrap();
      setCreateAppointment({
        ...createAppointment,
        visitDate: moment(dates[dateIndex]).format("MM/DD/YYYY"),
      });
      // setAlert({
      //   message: "Appointment has been created successfully",
      //   severity: "success",
      // });
      setOpen(true);
      handleReset();
    } catch (error) {
      console.log(error);
      // setAlert({
      //   message:
      //     "(Error Code 001): We were unable to publish the appointment to CareSMS due to system timeout. Please try again in a few minutes. If the problem persists, please contact your system administrator.",
      //   severity: "error",
      // });
    }
  };

  const handleReset = () => {
    resetAllandFetch();
    setUpdatedAppointments([]);
    setUniqueLocations([]);
    setOpenConfirmModal(false);
    setUpdates([]);
    setNote("");
    setEditable(false);
  };

  const handleLockModal = () => {
    if (openLockModal) setAppointmentToLock(null);
    setOpenLockModal(!openLockModal);
  };

  const handleStatusModal = () => {
    if (openStatusModal) setAppointmentToChangeStatus(null);
    setOpenStatusModal(!openStatusModal);
  };

  const handleLock = async () => {
    try {
      const status =
        appointmentToLock.status === "LOCKED" ? "UNLOCKED" : "LOCKED";
      setCreateAppointment((prev) => ({ ...prev, status }));
      setUpdatedAppointments((prev) =>
        prev.map((p) => (p.proposedPatient ? { ...p, status } : p))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeStatus = async (status) => {
    try {
      setCreateAppointment((prev) => ({ ...prev, visitStatus: status }));
      setUpdatedAppointments((prev) =>
        prev.map((p) => (p.proposedPatient ? { ...p, visitStatus: status } : p))
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="w-1/2">
        <Loading loading={isLoading} />
        <div className="w-full h-[1px] bg-gray-200" />
        <div className="w-1/2 p-4">
          <MapV3
            locations={uniqueLocations}
            provider={providerAppointment?.provider}
            providers={providers}
            selectedPatient={selectedPatient}
          />
        </div>
        <div className="w-full h-[1px] bg-gray-200" />
        {updatedAppointments.length > 0 && (
          <div className="flex justify-end px-4 py-2">
            <button
              disabled={true}
              onClick={() => {
                setEditable(!editable);
              }}
              className="flex items-center justify-center w-10 h-10 transition-all duration-200 ease-in-out rounded-md bg-primarylightgrey disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {!editable ? (
                <Tooltip title="Edit appointment sequence">
                  <img
                    src={Edit_Icon}
                    alt="edit"
                    className="object-contain w-5 h-5"
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Save">
                  <CheckSvg color={"#0d6efd"} />
                </Tooltip>
              )}
            </button>
          </div>
        )}
        <div className="flex flex-col w-full gap-2 px-4 mb-24 h-[calc(100vh-690px)] overflow-y-auto">
          <DropArea onDrop={() => onDrop(0)} />
          {updatedAppointments.map((appointment, index) => (
            <>
              <button
                disabled={!appointment.proposedPatient}
                className={classNames(
                  "flex flex-col  gap-4 p-3 rounded-md shadow-custom border ",
                  editable && "cursor-pointer",
                  appointment.proposedPatient
                    ? "bg-subtleorange border-primaryblack"
                    : "bg-white border-primarygrayborder"
                )}
              >
                <div className="flex w-100 relative">
                  <div className="flex flex-col gap-4 w-100">
                    <div className="flex flex-row justify-between w-100">
                      <div className="flex flex-row items-center gap-4">
                        <div
                          className={classNames(
                            "flex items-center justify-center rounded-full text-white text-sm font-semibold w-10 h-10",
                            appointment.status === "UNLOCKED"
                              ? "bg-[#F2C94C]"
                              : appointment.status === "LOCKED"
                              ? "bg-[#3062D4]"
                              : "bg-[#6FCF97]"
                          )}
                        >
                          {index + 1}
                        </div>
                        <div className="flex flex-col items-start gap-0">
                          <span className="flex flex-row items-center gap-2 text-2xl font-bold whitespace-nowrap text-primaryblack">
                            <span className="m-0">
                              {appointment.patient.name}
                            </span>
                            <span>|</span>
                            <span className="m-0">
                              {appointment.location?.name !== null
                                ? appointment.location?.name
                                : "Private Residence"}
                            </span>
                          </span>
                          <span className="m-0 text-base font-normal text-primarytextgrey">
                            {index === 0
                              ? `${appointment.distanceFromHome} miles from home`
                              : `${appointment.distance} miles from previous appointment`}
                            {index === updatedAppointments.length - 1
                              ? ` and ${appointment.distanceFromHome} miles from home`
                              : ``}
                            {appointment?.appointmentType?.duration &&
                              ` | ${appointment?.appointmentType?.duration}-min visit`}
                          </span>
                        </div>
                      </div>
                      {appointment.status === "PUBLISHED" && (
                        <Tooltip title="Published to Athena">
                          <div className="flex items-center justify-center w-10 h-10 rounded-lg bg-gray-50">
                            <CheckSvg color={"#6FCF97"} />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                    <div className="flex w-full justify-evenly">
                      <button
                        disabled={true}
                        className={classNames(
                          "text-sm m-0 py-1 px-2 border-[1px] bg-primarylightgrey font-semibold rounded-lg",
                          appointment.proposedPatient
                            ? "text-primaryblack border-primaryblack"
                            : "text-primarytextgrey border-primarylightgrey"
                        )}
                      >
                        {appointment.appointmentType.name}
                      </button>
                      <button
                        // disabled={true}
                        className={classNames(
                          "text-sm m-0 py-1 px-2 bg-white font-semibold rounded-lg flex items-center gap-1",
                          appointment.proposedPatient
                            ? "text-primaryblack border-[1px] border-primaryblack"
                            : appointment.visitStatus === "COMPLETED"
                            ? "text-primarygreen border-[1px] border-primarygreen"
                            : appointment.visitStatus === "SCHEDULED" ||
                              appointment.visitStatus == null
                            ? "text-primaryyellow border-[1px] border-primaryyellow"
                            : appointment.visitStatus === "CANCELLED"
                            ? "text-primaryred border-[1px] border-primaryred"
                            : "text-primaryblue border-[1px] border-primaryblue"
                        )}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevents event bubbling
                          setAppointmentToChangeStatus(appointment);
                          handleStatusModal(appointment.visitStatus);
                        }}
                      >
                        {appointment.visitStatus === "SCHEDULED" ||
                        appointment.visitStatus == null ? (
                          <Icon
                            name="20-hourglass"
                            color={
                              appointment.proposedPatient ? "#000" : "#F2C94C"
                            }
                          />
                        ) : appointment.visitStatus === "CONFIRMED" ? (
                          <Icon name="20-calendar-check" color={"#3062D4"} />
                        ) : appointment.visitStatus === "CANCELLED" ? (
                          <Icon name="20-calendar-close" color={"#eb5757"} />
                        ) : (
                          <CheckSvg color={"#27AE60"} />
                        )}
                        {appointment.visitStatus === "SCHEDULED" ||
                        appointment.visitStatus == null
                          ? "Pending"
                          : appointment.visitStatus === "CONFIRMED"
                          ? "Confirmed"
                          : appointment.visitStatus === "CANCELLED"
                          ? "Cancelled"
                          : "Completed"}
                      </button>
                      <button
                        // disabled={true}
                        className={classNames(
                          "text-sm m-0 py-1 px-2 bg-white font-semibold rounded-lg flex items-center gap-1",
                          appointment.proposedPatient
                            ? "text-primaryblack border-[1px] border-primaryblack"
                            : appointment.status === "PUBLISHED"
                            ? "text-primarygreen border-[1px] border-primarygreen"
                            : appointment.status === "UNLOCKED"
                            ? "text-primaryyellow border-[1px] border-primaryyellow"
                            : "text-primaryblue border-[1px] border-primaryblue"
                        )}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevents event bubbling
                          setAppointmentToLock(appointment);
                          handleLockModal();
                        }}
                      >
                        {appointment.status === "UNLOCKED" ? (
                          <Unlock
                            color={
                              appointment.proposedPatient ? "#000" : "#F2C94C"
                            }
                          />
                        ) : appointment.status === "LOCKED" ? (
                          <Lock color={"#3062D4"} />
                        ) : (
                          <CheckSvg color={"#27AE60"} />
                        )}
                        {appointment.status === "PUBLISHED"
                          ? "Published"
                          : appointment.status === "UNLOCKED"
                          ? "Unlocked"
                          : "Locked"}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <div
                    className={classNames(
                      "w-full h-[1px] ",
                      appointment.proposedPatient
                        ? "bg-gray-600"
                        : "bg-gray-200"
                    )}
                  />
                  <p className="m-0 mt-2 text-start"></p>
                  <p className="m-0 text-start">
                    <b>Appointment note:</b>{" "}
                    {appointment?.notes?.length > 0
                      ? appointment.notes
                          ?.map(({ description }) => description)
                          ?.join(", ")
                      : "NA"}
                  </p>
                  <p className="m-0 text-start">
                    <b>Patient note:</b> {appointment?.patient?.notes ?? "NA"}
                  </p>
                  <div className="flex justify-between">
                    <p className="m-0">
                      <b>Phone: </b>{" "}
                      {`${
                        appointment.patient?.poc?.phone
                          ? numberToPhone(appointment.patient?.poc?.phone)
                          : "NA"
                      }`}
                    </p>
                    <p className="m-0">{appointment?.timeslot}</p>
                  </div>
                </div>
              </button>
              <DropArea onDrop={() => onDrop(index + 1)} />
            </>
          ))}
        </div>
        <div className="w-full h-[1px] bg-gray-200" />
        {updatedAppointments.length > 0 && (
          <div className="fixed bottom-0 flex items-center justify-center w-1/2 p-2 bg-white border-t">
            <button
              onClick={() => setOpenConfirmModal(true)}
              className="py-2 px-4 font-bold border-[1px] shadow-sm rounded-md text-primarytextgreen border-primarytextgreen"
            >
              Add to Schedule
            </button>
          </div>
        )}
      </div>
      <ReusableModal open={openConfirmModal} hideHeader>
        <div className="flex flex-col items-center min-w-[480px]">
          <div className="flex flex-row items-center justify-between w-full p-6">
            <p className="m-0 text-2xl font-bold text-primaryblack">
              Add Appointment
            </p>
            <button
              onClick={() => setOpenConfirmModal(false)}
              className="flex border-[2px] border-primarygrayborder items-center justify-center w-8 rounded-full aspect-square bg-primarylightgrey"
            >
              <CloseSvg color={"#828282"} />
            </button>
          </div>
          <div className="h-[1px] w-full bg-primarygrayborder" />
          <div className="flex flex-col w-full gap-2 p-4">
            <label htmlFor="patientSelect" className="text-sm font-bold">
              Appointment Note (Optional)
            </label>
            <div className="w-full">
              <textarea
                className="w-full border"
                rows={5}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </div>
          </div>
          <div className="h-[1px] w-full bg-primarygrayborder" />
          <div className="flex flex-row items-center justify-between w-full gap-6 p-4">
            <button
              onClick={() => setOpenConfirmModal(false)}
              className="w-1/2 p-2 font-semibold rounded-md bg-primarylightgrey border-[2px] text-primarytextgrey border-primarygrayborder"
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              className="w-1/2 disabled:opacity-40 disabled:cursor-not-allowed font-semibold p-2 border-[2px] rounded-md bg-subtleblue border-primaryblue text-primaryblue whitespace-nowrap"
            >
              Add Appointment
            </button>
          </div>
        </div>
      </ReusableModal>
      <AppointmentLockModal
        open={openLockModal}
        handleLockModal={handleLockModal}
        handleLock={handleLock}
        status={appointmentToLock?.status}
      />
      {appointmentToChangeStatus && (
        <AppointmentStatusModal
          open={openStatusModal}
          handleCancel={handleStatusModal}
          handleSubmit={handleChangeStatus}
          status={appointmentToChangeStatus?.visitStatus}
        />
      )}
    </>
  );
};

export default PatientSchedulingModal;
