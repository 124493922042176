import "jspdf-autotable";

import { Alert } from "@mui/material";
import React from "react";
import ReportHeader from "../reportHeader";
import RevenueReportFilter from "./components/revenueReportFilter";
import RevenueReportTable from "./components/revenueReportTable";
import Snackbar from "@mui/material/Snackbar";
import dayjs from "dayjs";
import { jsPDF } from "jspdf";
import moment from "moment";
import { useGetRevenueReportMutation } from "../../../features/report/reportApiSlice";

function index() {
  const today = dayjs();
  const [providers, setProviders] = React.useState([]);
  const [patients, setPatients] = React.useState([]);
  const [speciality, setSpeciality] = React.useState([]);
  const [visitType, setVisitType] = React.useState([]);
  const [selectedVisitType, setSelecetdVisitType] = React.useState([]);
  const [status, setStatus] = React.useState([]);

  const [revenueReports, setRevenueReports] = React.useState([]);

  const [filteredPv, setFilteredPv] = React.useState([]);
  const [filteredSpe, setFilteredSpe] = React.useState([]);
  const [filteredPt, setFilteredPt] = React.useState([]);

  const [selectedProviders, setSelectedProviders] = React.useState([]);
  const [selectedSpeciality, setSelectedSpeciality] = React.useState([]);
  const [selectedPatientsName, setSelectedPatientsName] = React.useState([]);
  const [selectedStatus, setSelectedStatus] = React.useState([]);
  const [isSelectAllStatus, setIsSelectAllStatus] = React.useState(true);


  const [dateRange, setDateRange] = React.useState([
    today.startOf("month"),
    today,
  ]);
  const [dateRangeValue, setDateRangeValue] = React.useState("THIS_MONTH");
  const [isSelecetdValue, setIsSelectedValue] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("Something went wrong");

  const [isSelectAllProviders, setIsSelectAllProviders] = React.useState(true);
  const [isSelectAllSpe, setIsSelectAllSpe] = React.useState(true);
  const [isSelectAllPatients, setIsSelectAllPatients] = React.useState(true);
  const [isSelectAllVisitType, setIsSelectAllVisitType] = React.useState(true);

  const [isTableView, setIsTableView] = React.useState(true);

  const [totals, setTotals] = React.useState([
    { name: "Total Records Found", value: 0 },
    { name: "Total Revenue", value: "$0" },
  ]);
  const [clear, setClear] = React.useState(true);
  const DAY_MAP = {
    MONDAY: "M",
    TUESDAY: "T",
    WEDNESDAY: "W",
    THURSDAY: "Th",
    FRIDAY: "F",
    SATURDAY: "Sa",
    SUNDAY: "Su",
  };
  const [getRevenueReport] = useGetRevenueReportMutation();
  const getRevenueReports = async (filters) => {
    console.log(filters);
    try {
      const from_date = filters.dateRange
        ? dayjs(filters.dateRange[0]).isValid()
          ? dayjs(filters.dateRange[0])?.format("DD-MM-YYYY")
          : ""
        : "";
      const to_date = filters.dateRange
        ? dayjs(filters.dateRange[1]).isValid()
          ? dayjs(filters.dateRange[1])?.format("DD-MM-YYYY")
          : ""
        : "";
      const revenueReport = await getRevenueReport({
        date_range: filters.dateRangeValue
          ? filters.dateRangeValue
          : dateRangeValue,
        provider_type: filters.setHeaders ? "" : selectedSpeciality,
        from_date: dateRangeValue ? "" : from_date,
        to_date: dateRangeValue ? "" : to_date,
        visit_type: filters.setHeaders ? "" : selectedVisitType,
        provider_id: filters.setHeaders ? "" : selectedProviders,
        patient_id: filters.setHeaders ? "" : selectedPatientsName,
        status: filters.setHeaders ? "" : selectedStatus,
      }).unwrap();
      // const Reports = await axios.get(`http://localhost:4000/v1/report/revenue?
      // provider_id=${filters.setHeaders ? "" : selectedProviders}
      // &patient_id=${filters.setHeaders ? "" : selectedPatientsName}
      // &visit_type=${filters.setHeaders ? "" : selectedVisitType}
      // &provider_type=${filters.setHeaders ? "" : selectedSpeciality}
      // &date_range=${filters.dateRangeValue ? filters.dateRangeValue : dateRangeValue}
      // &from_date=${dateRangeValue ? "" : from_date}
      // &to_date=${dateRangeValue ? "" : to_date}`)
      setRevenueReports(
        [...revenueReport.data.report_data].sort(
          (a, b) => new Date(b.visit_date) - new Date(a.visit_date)
        )
      );
      setTotals([
        {
          name: "Total Records Found",
          value: revenueReport.data.report_data.length,
        },
        {
          name: "Total Revenue",
          value: `$${revenueReport.data.total_revenue?.toLocaleString(
            "en-US"
          )}`,
        },
      ]);
      if (filters.setHeaders === true) {
        const uniquePatients = revenueReport.data.report_data.reduce(
          (acc, item) => {
            const existingPatient = acc.find(
              (patient) => patient.id === item.patient_id
            );
            if (!existingPatient) {
              acc.push({ name: item.patient_name, id: item.patient_id });
            }
            return acc;
          },
          []
        );
        setPatients(uniquePatients);
        setFilteredPt(uniquePatients);
        const uniqueProviders = revenueReport.data.report_data.reduce(
          (acc, item) => {
            const existingProvider = acc.find(
              (provider) => provider.id === item.provider_id
            );
            if (!existingProvider) {
              acc.push({ name: item.provider_name, id: item.provider_id });
            }
            return acc;
          },
          []
        );
        setProviders(uniqueProviders);
        const uniqueStatus = revenueReport.data.report_data.reduce((acc, item) => {
          const existingItem = acc.find((data) => data?.id === item.status);
          if (!existingItem) {
            acc.push({ name: item.status == 'SCHEDULED' ? 'Pending' : item?.status == 'NOSHOW' ? 'No Show' : item.status.charAt(0).toUpperCase() + item.status.slice(1).toLowerCase(), id: item.status });
          }
          return acc;
        }, []);
        setStatus(uniqueStatus);
        setFilteredPv(uniqueProviders);
        const uniqueVisitT = revenueReport.data.report_data.reduce(
          (acc, item) => {
            const existingItem = acc.find((v) => v.id === item.visit_type_id);
            if (!existingItem) {
              acc.push({ name: item.visit_type, id: item.visit_type_id });
            }
            return acc;
          },
          []
        );
        setVisitType(uniqueVisitT);
        const spe = revenueReport.data.report_data.map(
          (item) => item.provider_type
        );
        setSpeciality([...new Set(spe)]);
        setFilteredSpe([...new Set(spe)]);
        setSelectedProviders(uniqueProviders.map(i => i.id));
        setSelectedSpeciality([...new Set(spe)]);
        setSelectedPatientsName(uniquePatients.map(i => i.id));
        setSelecetdVisitType(uniqueVisitT.map(i => i.id));
        setSelectedStatus(uniqueStatus.map(i => i.id))
      }
    } catch (err) {
      setOpen(true);
      setMessage(err.response?.data?.message);
    }
  };
  const handleProviderFilter = (provider, obj) => {
    setClear(false);
    if (obj.selectAll === true) {
      if (obj.isSelectAll) {
        setSelectedProviders(providers.map(i => i.id))
      } else {
        setSelectedProviders([])
      }
    } else {
      if (selectedProviders.includes(provider)) {
        setSelectedProviders(
          selectedProviders.filter((filter) => filter !== provider)
        );
      } else {
        setSelectedProviders([...selectedProviders, provider]);
      }
    }
  };
  const handleSpecialityFilter = (spe, obj) => {
    setClear(false);
    if (obj.selectAll === true) {
      if (obj.isSelectAll) {
        setSelectedSpeciality(speciality)
      } else {
        setSelectedSpeciality([])
      }
    } else {
      if (selectedSpeciality.includes(spe)) {
        setSelectedSpeciality(
          selectedSpeciality.filter((filter) => filter !== spe)
        );
      } else {
        setSelectedSpeciality([...selectedSpeciality, spe]);
      }
    }
  };
  const handlePatientNameFilter = (patient, obj) => {
    setClear(false);
    if (obj.selectAll === true) {
      if (obj.isSelectAll) {
        setSelectedPatientsName(patients.map(i => i.id))
      } else {
        setSelectedPatientsName([])
      }
    } else {
      if (selectedPatientsName.includes(patient)) {
        setSelectedPatientsName(
          selectedPatientsName.filter((filter) => filter !== patient)
        );
      } else {
        setSelectedPatientsName([...selectedPatientsName, patient]);
      }
    }
  };
  const handleVisitTypeFilter = (type, obj) => {
    setClear(false);
    if (obj.selectAll === true) {
      if (obj.isSelectAll) {
        setSelecetdVisitType(visitType.map(i => i.id))
      } else {
        setSelecetdVisitType([])
      }
    } else {
      if (selectedVisitType.includes(type)) {
        setSelecetdVisitType(
          selectedVisitType.filter((filter) => filter !== type)
        );
      } else {
        setSelecetdVisitType([...selectedVisitType, type]);
      }
    }
  };

  const handleStatusFilter = (statusit, obj) => {
    setClear(false);
    if (obj.selectAll === true) {
      if (obj.isSelectAll) {
        setSelectedStatus(status.map(i => i.id))
      } else {
        setSelectedStatus([])
      }
    } else {
      if (selectedStatus.includes(statusit)) {
        setSelectedStatus(
          selectedStatus.filter((filter) => filter !== statusit)
        );
      } else {
        setSelectedStatus([...selectedStatus, statusit]);
      }
    }
  };
  const exportPDF = () => {
    if (revenueReports?.length > 0) {
      const doc = new jsPDF();
      doc.text("Revenue Report", 20, 10);
      doc.autoTable({
        styles: { halign: "center" },
        headStyles: { fillColor: [245, 247, 249] },
        html: "#revenueReport",
        theme: "plain",
        didParseCell: function (data) {
          if (data.row.index === 0 || data.row.index % 2 === 0) {
            data.cell.styles.fillColor = [255, 255, 255]; // White color for even rows
          } else {
            data.cell.styles.fillColor = [245, 247, 249]; // Light blue color for odd rows
          }
        },
      });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(13);
      doc.setTextColor(0, 0, 0);
      doc.text(
        `Total Records Found: ${totals[0].value}\nTotal Revenue: ${totals[1].value}`,
        15,
        doc.lastAutoTable.finalY + 10
      );
      doc.save("revenue-report.pdf");
    } else {
      alert("No Records Found");
    }
  };
  const parseNull = (value, replacement = "NA", type = "string") => {
    return value === null || (Array.isArray(value) && value.length === 0)
      ? replacement
      : Array.isArray(value)
        ? value.map((day) => DAY_MAP[day]).join(", ")
        : type === "date"
          ? moment(value).format("DD/MM/YYYY")
          : value;
  };


  const exportCSV = () => {
    if (revenueReports?.length > 0) {
      let csvContent =
        "Appt_Date,Provider_Name,Speciality,Patient_Name,Visit_Type,Expected_Revenue,Status\r\n";
      revenueReports.forEach((revenue) => {
        const row = [
          parseNull(revenue.visit_date, "NA", "date"),
          parseNull(revenue.provider_name),
          parseNull(revenue.provider_type),
          parseNull(revenue.patient_name),
          parseNull(revenue.visit_type),
          parseNull(revenue.rate?.toLocaleString("en-US")),
          parseNull(revenue.status)
        ];
        csvContent += row.join(",") + "\r\n";
      });

      // Add extra data at the bottom of the CSV
      csvContent += `\r\nTotal_Records_Found: ${totals[0].value}`;
      csvContent += `\r\nTotal_Revenue: ${totals[1].value}`;
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "revenue-report.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      alert("No Records Found");
    }
  };
  const handleClear = () => {
    setSelectedPatientsName([]);
    setSelectedSpeciality([]);
    setSelectedProviders([]);
    setSelecetdVisitType([]);
    // setDateRange([today.startOf("month"), today]);
    setDateRange([null, null]);
    setDateRangeValue("");
    setPatients([]);
    setFilteredPt([]);
    setProviders([]);
    setFilteredPv([]);
    setVisitType([]);
    setSpeciality([]);
    // getRevenueReports({ setHeaders: true, dateRangeValue: "THIS_MONTH" });
    setClear(true);
    setRevenueReports([])
    setIsTableView(true)
    setStatus([]);
    setSelectedStatus([]);
  };
  const handleClose = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    if (!clear) {
      getRevenueReports({ dateRange: dateRange });
    }
    if (
      selectedProviders?.length > 0 ||
      selectedPatientsName?.length > 0 ||
      selectedSpeciality?.length > 0 ||
      selectedVisitType?.length > 0 ||
      selectedStatus?.length > 0
    ) {
      setIsSelectedValue(true);
    } else {
      setIsSelectedValue(false);
    }
  }, [
    selectedProviders,
    selectedPatientsName,
    selectedSpeciality,
    selectedVisitType,
    selectedStatus
  ]);
  React.useEffect(() => {
    getRevenueReports({ setHeaders: true, dateRangeValue: "THIS_MONTH" });
    if (
      selectedProviders?.length > 0 ||
      selectedPatientsName?.length > 0 ||
      selectedSpeciality?.length > 0 ||
      selectedVisitType?.length > 0 ||
      selectedStatus?.length > 0
    ) {
      setIsSelectedValue(true);
    } else {
      setIsSelectedValue(false);
    }
  }, []);
  return (
    <div className="relative flex flex-col w-full overflow-auto no-scrollbar standard-reports" style={{ height: "89vh" }}>
      <Snackbar
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={6000}
        message={message ? message : "Something went wrong"}
        severity="error"
      >
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message ? message : "Something went wrong"}
        </Alert>
      </Snackbar>
      <ReportHeader
        title="Revenue Report"
        exportCSV={exportCSV}
        exportPDF={exportPDF}
        totals={totals}
        total={revenueReports.length}
      />
      <div
        className="p-2 d-flex flex-column overflow-auto align-items-center"
        style={{ height: "77vh" }}
      >
        <RevenueReportFilter
          getRevenueReports={getRevenueReports}
          dateRange={dateRange}
          setDateRange={setDateRange}
          dateRangeValue={dateRangeValue}
          setDateRangeValue={setDateRangeValue}
          handleClear={handleClear}
          setClear={setClear}
          isSelecetdValue={isSelecetdValue}
          isTableView={isTableView}
          setIsTableView={setIsTableView}
        />
        <RevenueReportTable
          revenueReports={revenueReports}
          setRevenueReports={setRevenueReports}
          filteredPv={filteredPv}
          providers={providers}
          setFilteredPv={setFilteredPv}
          selectedProviders={selectedProviders}
          handleProviderFilter={handleProviderFilter}
          speciality={speciality}
          selectedSpeciality={selectedSpeciality}
          handleSpecialityFilter={handleSpecialityFilter}
          filteredSpe={filteredSpe}
          setFilteredSpe={setFilteredSpe}
          isSelecetdValue={isSelecetdValue}
          patients={patients}
          selectedPatientsName={selectedPatientsName}
          handlePatientNameFilter={handlePatientNameFilter}
          filteredPt={filteredPt}
          setFilteredPt={setFilteredPt}
          visitType={visitType}
          selectedVisitType={selectedVisitType}
          handleVisitTypeFilter={handleVisitTypeFilter}
          isSelectAllProviders={isSelectAllProviders}
          setIsSelectAllProviders={setIsSelectAllProviders}
          isSelectAllSpe={isSelectAllSpe}
          setIsSelectAllSpe={setIsSelectAllSpe}
          isSelectAllPatients={isSelectAllPatients}
          setIsSelectAllPatients={setIsSelectAllPatients}
          isSelectAllVisitType={isSelectAllVisitType}
          setIsSelectAllVisitType={setIsSelectAllVisitType}
          isTableView={isTableView}

          status={status}
          selectedStatus={selectedStatus}
          isSelectAllStatus={isSelectAllStatus}
          setIsSelectAllStatus={setIsSelectAllStatus}
          handleStatusFilter={handleStatusFilter}

        />
      </div>
    </div>
  );
}

export default index;
