import React, { useMemo } from "react";
import classNames from "classnames";
import moment from "moment";

const StatusBadge = ({ status }) => {
  const isActive = status.toUpperCase() === "ACTIVE";
  return (
    <div
      className={classNames(
        "rounded-md text-center flex flex-row gap-1 items-center px-2 w-fit",
        isActive
          ? "bg-subtlegreen border-[1px] border-primarygreen text-primarygreen"
          : "bg-primarylightgrey border-[1px] border-primarygrayborder text-primarytextgrey"
      )}
    >
      <div
        className={classNames(
          "w-2 h-2 rounded-full",
          isActive ? "bg-primarygreen" : "bg-primarytextgrey"
        )}
      />
      {status}
    </div>
  );
};

const PatientsTable = ({ patient, index, patients, handlePatientClick }) => {
  const { name, dob, sex, patientEhrId, careplans = [], status } = patient;

  const [provider, setProvider] = React.useState(careplans?.[0]?.providerId);

  const lastVisitDate = useMemo(() => {
    const lvd = careplans.find(
      (careplan) => careplan.providerId === Number(provider)
    )?.lastVisitDate;
    return lvd ? moment(lvd).format("MM/DD/YYYY") : "NA";
  }, [careplans, provider]);

  const dobFormatted = moment(dob).format("MM/DD/YYYY");
  const gender = sex === "F" ? "Female" : "Male";

  return (
    <tr
      className={classNames(
        "text-left text-gray-900 h-24 whitespace-nowrap",
        index !== patients.length - 1 ? "border-b" : "border-none"
      )}
      key={index}
    >
      <td className="px-4 py-3 text-left">
        <button
          onClick={() => handlePatientClick(patient)}
          className="flex flex-row items-center gap-4"
        >
          <p className="m-0 font-semibold underline">{name}</p>
        </button>
      </td>
      <td className="px-4 py-3 text-left text-gray-700">
        <select
          value={provider}
          onChange={(e) => setProvider(e.target.value)}
          className="border rounded p-1"
        >
          {careplans.map((careplan) => (
            <option key={careplan.providerId} value={careplan.providerId}>
              {careplan.providerName}
            </option>
          ))}
        </select>
      </td>
      <td className="px-4 py-3 text-left text-gray-700">{lastVisitDate}</td>
      <td className="px-4 py-3 text-left text-gray-700">{dobFormatted}</td>
      <td className="px-4 py-3 text-left text-gray-700">{gender}</td>
      <td className="px-4 py-3 text-left text-gray-700">{patientEhrId}</td>
      <td className="px-4 py-3 text-left text-gray-700">
        <StatusBadge status={status} />
      </td>
    </tr>
  );
};

export default PatientsTable;
