import { Alert } from "@mui/material";
import NoShowReportFilter from "./components/NoShowReportFilter";
import NoShowReportTable from "./components/NoShowReportTable";
import React from "react";
import ReportHeader from "../reportHeader";
import Snackbar from "@mui/material/Snackbar";
import dayjs from "dayjs";
import { jsPDF } from "jspdf";
import moment from "moment";
import { useGetNoShowReportMutation } from "../../../features/report/reportApiSlice";

function index() {
    const today = dayjs();
    const [message, setMessage] = React.useState("Something went wrong");
    const [open, setOpen] = React.useState(false);
    const [totals, setTotals] = React.useState([
        { name: "Total Records Found", value: 0 },
    ]);
    const [noShowReports, setNoShowReports] = React.useState([]);
    const [providers, setProviders] = React.useState([]);
    const [filteredPv, setFilteredPv] = React.useState([]);
    const [patients, setPatients] = React.useState([]);
    const [visitType, setVisitType] = React.useState([]);
    const [filteredPt, setFilteredPt] = React.useState([]);
    const [selectedProviders, setSelectedProviders] = React.useState([]);
    const [dateRangeValue, setDateRangeValue] = React.useState("THIS_MONTH");
    const [dateRange, setDateRange] = React.useState([
        today.startOf("month"),
        today,
    ]);
    const [isSelecetdValue, setIsSelectedValue] = React.useState(false);
    const [isSelectAllProviders, setIsSelectAllProviders] = React.useState(true);
    const [selectedPatientsName, setSelectedPatientsName] = React.useState([]);
    const [selectedVisitType, setSelecetdVisitType] = React.useState([]);
    const [isSelectAllPatients, setIsSelectAllPatients] = React.useState(true);
    const [isSelectAllVisitType, setIsSelectAllVisitType] = React.useState(true);
    const [isClearCalled, setIsClearCalled] = React.useState(false);
    const DAY_MAP = {
        MONDAY: "M",
        TUESDAY: "T",
        WEDNESDAY: "W",
        THURSDAY: "Th",
        FRIDAY: "F",
        SATURDAY: "Sa",
        SUNDAY: "Su",
    };
    const [clear, setClear] = React.useState(true);
    const handleClose = () => {
        setOpen(false);
    };
    const [getNoShowReport] = useGetNoShowReportMutation();
    const getNoShowReports = async (filters) => {
        console.log("Filters received:", filters);
        try {
            const from_date = filters.dateRange
                ? dayjs(filters.dateRange[0]).isValid()
                    ? dayjs(filters.dateRange[0])?.format("DD-MM-YYYY")
                    : ""
                : "";
            const to_date = filters.dateRange
                ? dayjs(filters.dateRange[1]).isValid()
                    ? dayjs(filters.dateRange[1])?.format("DD-MM-YYYY")
                    : ""
                : "";
            const noShowReport = await getNoShowReport({
                date_range: filters.dateRangeValue
                    ? filters.dateRangeValue
                    : dateRangeValue,
                from_date: dateRangeValue ? "" : from_date,
                to_date: dateRangeValue ? "" : to_date,
                patient_id: filters.setHeaders ? "" : selectedPatientsName,
                visit_type: filters.setHeaders ? "" : selectedVisitType,
                provider_id: filters.setHeaders ? "" : selectedProviders,
            }).unwrap();

            setNoShowReports(
                [...noShowReport.data].sort(
                    (a, b) => new Date(b.visit_date) - new Date(a.visit_date)
                )
            );
            setTotals([
                {
                    name: "Total Records Found",
                    value: noShowReport?.data?.length,
                },
            ]);
            if (filters.setHeaders && filters.setHeaders === true) {
                const uniquePatients = noShowReport?.data?.reduce((acc, item) => {
                    const existingPatient = acc.find(
                        (patient) => patient.id === item.patient_id
                    );
                    if (!existingPatient) {
                        acc.push({ name: item.patient_name, id: item.patient_id });
                    }
                    return acc;
                }, []);
                setPatients(uniquePatients);
                setFilteredPt(uniquePatients);
                const uniqueProviders = noShowReport?.data?.reduce((acc, item) => {
                    const existingProvider = acc.find(
                        (provider) => provider.id === item.provider_id
                    );
                    if (!existingProvider) {
                        acc.push({ name: item.provider_name, id: item.provider_id });
                    }
                    return acc;
                }, []);
                setProviders(uniqueProviders);
                setFilteredPv(uniqueProviders);
                const uniqueVisitT = noShowReport?.data?.reduce((acc, item) => {
                    const existingItem = acc.find((v) => v.id === item.visit_type_id);
                    if (!existingItem) {
                        acc.push({ name: item.visit_type, id: item.visit_type_id });
                    }
                    return acc;
                }, []);
                setVisitType(uniqueVisitT);
                setSelectedPatientsName(uniquePatients.map(i => i.id));
                setSelecetdVisitType(uniqueVisitT.map(i => i.id));
                setSelectedProviders(uniqueProviders.map(i => i.id));
                setIsSelectAllPatients(true)
                setIsSelectAllProviders(true)
            }
        } catch (err) {
            setOpen(true);
            setMessage(err?.response?.data?.message);
            console.log(err, "error")
        }
    };
    const handleProviderFilter = (provider, obj) => {
        setClear(false);
        if (obj.selectAll === true) {
            if (obj.isSelectAll) {
                setSelectedProviders(providers.map(i => i.id))
            } else {
                setSelectedProviders([])
            }
        } else {
            if (selectedProviders.includes(provider)) {
                setSelectedProviders(
                    selectedProviders.filter((filter) => filter !== provider)
                );
            } else {
                setSelectedProviders([...selectedProviders, provider]);
            }
        }
    };
    const handlePatientNameFilter = (patient, obj) => {
        setClear(false);
        if (obj.selectAll === true) {
            if (obj.isSelectAll) {
                setSelectedPatientsName(patients.map(i => i.id))
            } else {
                setSelectedPatientsName([])
            }
        } else {
            if (selectedPatientsName.includes(patient)) {
                setSelectedPatientsName(
                    selectedPatientsName.filter((filter) => filter !== patient)
                );
            } else {
                setSelectedPatientsName([...selectedPatientsName, patient]);
            }
        }
    };
    const handleVisitTypeFilter = (type, obj) => {
        setClear(false);
        if (obj.selectAll === true) {
            if (obj.isSelectAll) {
                setSelecetdVisitType(visitType.map(i => i.id))
            } else {
                setSelecetdVisitType([])
            }
        } else {
            if (selectedVisitType.includes(type)) {
                setSelecetdVisitType(
                    selectedVisitType.filter((filter) => filter !== type)
                );
            } else {
                setSelecetdVisitType([...selectedVisitType, type]);
            }
        }
    };
    const exportPDF = () => {
        if (noShowReports?.length > 0) {
            const doc = new jsPDF();
            doc.text("No Show Report", 20, 10);
            doc.autoTable({
                styles: { halign: "center" },
                headStyles: { fillColor: [245, 247, 249] },
                html: "#noShowReport",
                theme: "plain",
                didParseCell: function (data) {
                    if (data.row.index === 0 || data.row.index % 2 === 0) {
                        data.cell.styles.fillColor = [255, 255, 255]; // White color for even rows
                    } else {
                        data.cell.styles.fillColor = [245, 247, 249]; // Light blue color for odd rows
                    }
                },
            });
            doc.setFont("helvetica", "normal");
            doc.setFontSize(13);
            doc.setTextColor(0, 0, 0);
            doc.text(
                `Total Records Found: ${totals[0].value}`,
                15,
                doc.lastAutoTable.finalY + 10
            );
            doc.save("no-show.pdf");
            doc.save("no-show-report.pdf");
        } else {
            alert("No Records Found");
        }
    };
    const parseNull = (value, replacement = "NA", type = "string") => {
        return value === null || (Array.isArray(value) && value.length === 0)
            ? replacement
            : Array.isArray(value)
                ? value.map((day) => DAY_MAP[day]).join(", ")
                : type === "date"
                    ? moment(value).format("DD/MM/YYYY")
                    : value;
    };
    const handleClear = () => {
        setSelectedPatientsName([]);
        setSelecetdVisitType([]);
        setSelectedProviders([]);
        setDateRange([null, null]);
        setDateRangeValue("");
        setVisitType([]);
        setPatients([]);
        setFilteredPt([]);
        setProviders([]);
        setFilteredPv([]);
        setIsClearCalled(true);
        setNoShowReports([])
        setClear(true)
    };
    const exportCSV = () => {
        if (noShowReports?.length > 0) {
            let csvContent =
                "visit_date,visit_type,provider_name,patient_name,patient_ehr_id,patient_location\r\n";
            noShowReports.forEach((provider) => {
                const row = [
                    parseNull(provider.visit_date, "NA", "date"),
                    // parseNull(provider.provider_id),
                    parseNull(provider.visit_type),
                    parseNull(provider.provider_name),
                    parseNull(provider.patient_name),
                    parseNull(provider.patient_ehr_id),
                    parseNull(`"${parseNull(provider.patient_street)}, ${parseNull(provider.patient_zip)}, ${parseNull(provider.patient_city)}, ${parseNull(provider.patient_state)}"`)
                ];
                csvContent += row.join(",") + "\r\n";
            });

            // Add extra data at the bottom of the CSV
            csvContent += `\r\nTotal_Records_Found: ${totals[0].value}`;


            const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
            const link = document.createElement("a");
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", "no-show-report.csv");
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } else {
            alert("No Records Found");
        }
    };
    React.useEffect(() => {
        if (!clear) {
            getNoShowReports({ dateRange: dateRange });
        }
        if (
            selectedPatientsName?.length > 0 ||
            selectedVisitType?.length > 0 ||
            selectedProviders?.length > 0
        ) {
            setIsSelectedValue(true);
        } else {
            setIsSelectedValue(false);
        }
    }, [
        selectedPatientsName,
        selectedVisitType,
        selectedProviders,

    ]);
    React.useEffect(() => {
        getNoShowReports({ setHeaders: true, dateRangeValue: "THIS_MONTH" });
        if (
            selectedPatientsName?.length > 0 ||
            selectedVisitType?.length > 0 ||
            selectedProviders?.length > 0

        ) {
            setIsSelectedValue(true);
        } else {
            setIsSelectedValue(false);
        }
    }, []);
    return (
        <div className="relative flex flex-col w-full overflow-auto no-scrollbar standard-reports" style={{ height: "89vh" }}>
            <Snackbar
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                autoHideDuration={6000}
                message={message ? message : "Something went wrong"}
                severity="error"
            >
                <Alert
                    onClose={handleClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {message ? message : "Something went wrong"}
                </Alert>
            </Snackbar>
            <ReportHeader
                title="No Show Report"
                exportCSV={exportCSV}
                exportPDF={exportPDF}
                totals={totals}
                total={noShowReports.length}
            />
            <div
                className="p-2 d-flex flex-column overflow-auto align-items-center"
                style={{ height: "77vh" }}
            >
                <NoShowReportFilter
                    getNoShowReports={getNoShowReports}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    dateRangeValue={dateRangeValue}
                    setDateRangeValue={setDateRangeValue}
                    handleClear={handleClear}
                    setClear={setClear}

                />
                <NoShowReportTable
                    providers={providers}
                    filteredPv={filteredPv}
                    setFilteredPv={setFilteredPv}
                    selectedProviders={selectedProviders}
                    handleProviderFilter={handleProviderFilter}
                    isSelecetdValue={isSelecetdValue}
                    isSelectAllProviders={isSelectAllProviders}
                    setIsSelectAllProviders={setIsSelectAllProviders}
                    setNoShowReports={setNoShowReports}
                    noShowReports={noShowReports}
                    isSelectAllVisitType={isSelectAllVisitType}
                    setIsSelectAllVisitType={setIsSelectAllVisitType}
                    isSelectAllPatients={isSelectAllPatients}
                    setIsSelectAllPatients={setIsSelectAllPatients}
                    filteredPt={filteredPt}
                    setFilteredPt={setFilteredPt}
                    selectedVisitType={selectedVisitType}
                    selectedPatientsName={selectedPatientsName}
                    handleVisitTypeFilter={handleVisitTypeFilter}
                    visitType={visitType}
                    patients={patients}
                    handlePatientNameFilter={handlePatientNameFilter}
                />

            </div>

        </div>
    )
}
export default index;